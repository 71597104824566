import React, { createContext, useEffect, useState } from "react";
import "./App.css";
import Routes from "./routes/routes";
import {
  IFilterContext,
  IMobileType,
  IVehicleType,
  getCoordinates,
  getMobileCoordinates,
  getMobilesData,
  getTenants,
  getUsers,
  getvehiclesData,
} from "./api/Api";
import { failed, useToast } from "./hooks/useToast";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { IDENTITY_SERVER_URL, Measurement_ID } from "./config";
import ReactGA from "react-ga4";

const defaultFilterContext: IFilterContext = {
  report: "vehicle",
  setReport: () => {},
  table: "trip",
  setTable: () => {},
  device: "all",
  setDevice: () => {},
  period: "today",
  setPeriod: () => {},
  fromDate: "",
  setFromdate: () => {},
  toDate: "",
  setTodate: () => {},
  columns: [],
  setColumns: () => {},
  mapperiod: "default",
  setMapPeriod: () => {},
};

export const VehicleDataContext = createContext<any>([]);
export const SidebarOpenContext = createContext<any>([]);
export const LoaderContext = createContext<any>(true);
export const VehicleCoordinatesContext = createContext<any>([]);
export const MobileCoordinatesContext = createContext<any>([]);
export const DisplayCoordinatesContext = createContext<any>([]);
export const FilterContext =
  createContext<IFilterContext>(defaultFilterContext);
export const ShowFilterContext = createContext<any>(false);
export const ShowMapCustomContext = createContext<any>(false);
export const CalledReplayContext = createContext<any>(false);
export const VehiclesDataContext = createContext<any>([]);
export const MobilesDataContext = createContext<any>([]);
export const RadioButtonValueContext = createContext<any>([]);
export const MapLayerContext = createContext<any>("");
export const initialApiCallContext = createContext<any>(false);
export const ReportExportContext = createContext<any>(false);
export const UserDetailsContext = createContext<any>("");
export const VehicleDataPopupContext = createContext<any>("");
export const MapColorContext = createContext<any>("");
export const InfoDataContext = createContext<any>([]);
export const AccordionStateContext = createContext<any>("");
export const ViewportDataContext = createContext<any>([]);
export const PathFilterContext = createContext<any>(false);

export interface VehicleType {
  // coordinates: number[],
  name: string;
  vId: string;
  vehicle_id: string;
  drId: string;
  driver_id: string;
  deId: string;
  device_id: string;
  mileage: string;
  capacity: string;
  fuel_capacity: string;
  last_fuel_date: string;
  color: string;
}

if (Measurement_ID) {
  ReactGA.initialize(Measurement_ID);
}

const App = () => {
  const [vehiclesInfo, setvehiclesInfo] = useState<IVehicleType[]>([]);
  const [mobilesInfo, setmobilesInfo] = useState<IMobileType[]>([]);
  const [loader, setLoader] = useState<boolean>(true);
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [organizationId, setOrganizationId] = useState<string>("");
  const [displayCoordinates, setDisplayCoordinates] = useState<string[]>([]);
  const [role, setRole] = useState<string>("");
  const [userName, setUserName] = useState<string>("");
  const [tenants, setTenants] = useState<string[]>([]);
  const [layer, setLayer] = useState<string>(
    "https://mt0.google.com/vt/lyrs=m&hl=sl&x={x}&y={y}&z={z}&s=Ga"
  );
  // const [showMapCustom, setShowMapCustom] = useState<boolean>(false)
  // const [sidebarMarginTop, setSidebarMarginTop] = useState<string>('')

  const currentDate = new Date();
  const sessionDateString = localStorage.getItem("sgs:session_time");
  if (sessionDateString && currentDate > new Date(sessionDateString)) {
    localStorage.removeItem("sgs:access-token");
    localStorage.removeItem("sgs:session_time");
  }

  const accessToken = localStorage.getItem("sgs:access-token");
  // const [accessToken, setAccessToken] = useState<string>(isAccessToken ? isAccessToken : '');
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
      title: "Custom Title",
    });
  }, []);

  useEffect(() => {
    if (accessToken === null || accessToken === undefined) {
      return;
    }
    getUsers(accessToken).then((data) => {
      setOrganizationId(data.organizations.length && data.organizations[0].id);
      setRole(data.roles[0].name);
      setUserName(data.name);
    });
  }, [accessToken]);

  useEffect(() => {
    if (accessToken === null || accessToken === undefined || role === "") {
      return;
    }
    getTenants(accessToken).then((data) => {
      const displayTenants = [25, 1, 26];
      const filteredTenants = data.tenants
        .filter((tenant: any) =>
          displayTenants.includes(tenant.organization.id)
        )
        .reverse();
      setTenants(filteredTenants);
      if (role === "sa") {
        setOrganizationId(filteredTenants[0].organization.id);
      }
    });
  }, [accessToken, role]);

  useEffect(() => {
    const checkedId = localStorage.getItem("sgs:checkedId");
    if (accessToken === null || accessToken === undefined || role === "") {
      return;
    }
    Promise.all([
      getvehiclesData(accessToken, organizationId, role),
      getMobilesData(accessToken, organizationId, role),
    ]).then(([vehiclesData, mobilesData]) => {
      if (vehiclesData.error || mobilesData.error) {
        failed();
      } else if ((vehiclesData.statusCode || mobilesData.statusCode) === 401) {
        localStorage.removeItem("sgs:access-token");
        localStorage.removeItem("sgs:checkedId");
        localStorage.removeItem("sgs:session_time");
        window.location.href = `${IDENTITY_SERVER_URL}&error=Session expired. Please login again.`;
      } else {
        // const filteredVehiclesData = vehiclesData.filter(
        //   (vehicle: IVehicleType) => vehicle.organizationId === organizationId
        // );
        setvehiclesInfo(vehiclesData);
        setmobilesInfo(mobilesData);
        if (role === "sa") {
          const vehicleFirstDeviceId =
            vehiclesData.length > 0 ? vehiclesData[0].device_id : null;
          const mobileFirstDeviceId =
            mobilesData.length > 0 ? mobilesData[0].device_id : null;
          if (checkedId === null || checkedId === undefined) {
            setDisplayCoordinates([vehicleFirstDeviceId, mobileFirstDeviceId]);
          } else {
            setDisplayCoordinates(JSON.parse(checkedId));
          }
        } else {
          // const allDeviceIds = [
          //   ...vehiclesData.map((vehicle: IVehicleType) => vehicle.device_id),
          //   ...mobilesData.map((mobile: IMobileType) => mobile.device_id)
          // ];
          const allDeviceIds = [
            ...vehiclesData.map((vehicle: IVehicleType) => vehicle.device_id),
          ];
          if (checkedId === null || checkedId === undefined) {
            setDisplayCoordinates(allDeviceIds);
          } else {
            setDisplayCoordinates(JSON.parse(checkedId));
          }
        }
        setLoader(false);
      }
    });
    // .finally(() => {
    // });
  }, [accessToken, organizationId, role]);

  const updateVehiclesInfo = (newData: IVehicleType[]) => {
    setvehiclesInfo(newData);
  };
  const [vehiclecoordinates, setVehicleCoordinates] = useState([]);
  const [mobilecoordinates, setMobileCoordinates] = useState([]);
  const [tripspointCoordinates, setTripspointCoordinates] = useState([]);
  const [calledreplay, setCalledreplay] = useState<boolean>(false);
  // const [oneCoordinate, setOneCoordinate] = useState<boolean>(false);
  const [initialApiCall, setInitialApiCall] = useState<boolean>(false);
  const [viewportData, setViewportData] = useState<any>();
  const [pathFilter, setPathFilter] = useState<boolean>(false);

  useEffect(() => {
    if (accessToken === null || accessToken === undefined || role === "") {
      return;
    }
    const callApi = async () => {
      if (!calledreplay && !pathFilter) {
        // used in filter.tsx
        Promise.all([
          getCoordinates(accessToken),
          getMobileCoordinates(accessToken),
        ]).then(([vehicleData, mobileData]) => {
          if (vehicleData.error || mobileData.error) {
            failed();
          } else if (
            (vehicleData.statusCode || mobileData.statusCode) === 401
          ) {
          } else {
            setVehicleCoordinates(vehicleData);
            setMobileCoordinates(mobileData);
            const filteredVehicleData = vehicleData.filter(
              (vehicle: IVehicleType) =>
                displayCoordinates.includes(vehicle.device_id)
            );
            const filteredMobileData = mobileData.filter(
              (mobile: IMobileType) =>
                displayCoordinates.includes(mobile.device_id)
            );
            setViewportData([...filteredVehicleData, ...filteredMobileData]);
            setLoader(false);
            // if (!oneCoordinate) {
            //   const vehicleFirstDeviceId = vehiclesInfo.length > 0 ? vehiclesInfo[0].device_id : '';
            //   const mobileFirstDeviceId = mobilesInfo.length > 0 ? mobilesInfo[0].device_id : '';

            //   setDisplayCoordinates([vehicleFirstDeviceId, mobileFirstDeviceId]);
            //   setOneCoordinate(true);
            // }
          }
        });
      }
    };
    if (!initialApiCall) {
      setLoader(true);
      callApi();
      setInitialApiCall(true);
    } else {
      const timeoutId = setTimeout(callApi, 500);
      const intervalId = setInterval(callApi, 15000);
      return () => {
        clearInterval(intervalId);
        clearTimeout(timeoutId);
      };
    }
  }, [
    vehiclesInfo,
    mobilesInfo,
    displayCoordinates,
    calledreplay,
    accessToken,
    initialApiCall,
    role,
    pathFilter,
  ]);

  // useEffect(() => {
  //   if (accessToken === null || accessToken === undefined){
  //     return
  //   }
  //   if (!calledreplay) { // used in filter.tsx
  //     Promise.all([getCoordinates(accessToken), getMobileCoordinates(accessToken)])
  //       .then(([vehicleData, mobileData]) => {
  //         if (vehicleData.error || mobileData.error) {
  //           failed();
  //         } else if ((vehicleData.statusCode || mobileData.statusCode)===401){ }
  //         else {
  //           setVehicleCoordinates(vehicleData);
  //           setMobileCoordinates(mobileData);

  //           // if (!oneCoordinate) {
  //           //   const vehicleFirstDeviceId = vehiclesInfo.length > 0 ? vehiclesInfo[0].device_id : '';
  //           //   const mobileFirstDeviceId = mobilesInfo.length > 0 ? mobilesInfo[0].device_id : '';

  //           //   setDisplayCoordinates([vehicleFirstDeviceId, mobileFirstDeviceId]);
  //           //   setOneCoordinate(true);
  //           // }
  //         }
  //       });
  //   }
  // }, [vehiclesInfo, mobilesInfo, displayCoordinates, calledreplay, accessToken]);

  const [vehicleData, setVehicleData] = useState();

  const [infoData, setInfoData] = useState();

  const [sidebarOpen, setSidebarOpen] = useState(false);

  const [report, setReport] = useState("vehicle");
  const [table, setTable] = useState("trip");
  const [device, setDevice] = useState("all");
  const [period, setPeriod] = useState("today");
  const [mapperiod, setMapPeriod] = useState("default");
  const [fromDate, setFromdate] = useState<string>("");
  const [toDate, setTodate] = useState<string>("");
  const [columns, setColumns] = useState<string[]>([]);
  const [reportExport, setReportExport] = useState(false);
  const [showExport, setShowExport] = useState(false);

  const [radioValue, setradioValue] = useState("device");
  const [vehicleDataPopup, setVehicleDataPopup] = useState("vehicle");
  const [mapColor, setMapColor] = useState("");
  const [expandedAccordion, setExpandedAccordion] = useState<string[]>([]);

  const ToastComponent = useToast();

  const toggleAccordion = (panel: string) => {
    if (expandedAccordion.includes(panel)) {
      setExpandedAccordion(
        expandedAccordion.filter((item: string) => item !== panel)
      );
    } else {
      setExpandedAccordion([...expandedAccordion, panel]);
    }
  };

  return (
    <>
      <PathFilterContext.Provider
        value={{
          pathFilter,
          setPathFilter,
        }}
      >
        <ViewportDataContext.Provider value={{ viewportData, setViewportData }}>
          <AccordionStateContext.Provider
            value={{ expandedAccordion, toggleAccordion }}
          >
            <InfoDataContext.Provider value={{ infoData, setInfoData }}>
              <MapColorContext.Provider value={{ mapColor, setMapColor }}>
                <VehicleDataPopupContext.Provider
                  value={{ vehicleDataPopup, setVehicleDataPopup }}
                >
                  <UserDetailsContext.Provider
                    value={{
                      organizationId,
                      role,
                      tenants,
                      userName,
                      setOrganizationId,
                      setTenants,
                    }}
                  >
                    <ReportExportContext.Provider
                      value={{
                        reportExport,
                        setReportExport,
                        showExport,
                        setShowExport,
                      }}
                    >
                      <initialApiCallContext.Provider
                        value={{ setInitialApiCall }}
                      >
                        <MapLayerContext.Provider value={{ layer, setLayer }}>
                          <RadioButtonValueContext.Provider
                            value={{ radioValue, setradioValue }}
                          >
                            {/* <SidebarMarginTop.Provider value={{sidebarMarginTop, setSidebarMarginTop}}> */}
                            <CalledReplayContext.Provider
                              value={{ calledreplay, setCalledreplay }}
                            >
                              {/* <ShowMapCustomContext.Provider value={{showMapCustom, setShowMapCustom}}> */}
                              <DisplayCoordinatesContext.Provider
                                value={{
                                  displayCoordinates,
                                  setDisplayCoordinates,
                                }}
                              >
                                <MobileCoordinatesContext.Provider
                                  value={{
                                    mobilecoordinates,
                                    setMobileCoordinates,
                                  }}
                                >
                                  <ShowFilterContext.Provider
                                    value={{ showFilter, setShowFilter }}
                                  >
                                    <FilterContext.Provider
                                      value={{
                                        report,
                                        setReport,
                                        table,
                                        setTable,
                                        device,
                                        setDevice,
                                        period,
                                        setPeriod,
                                        fromDate,
                                        setFromdate,
                                        toDate,
                                        setTodate,
                                        columns,
                                        setColumns,
                                        mapperiod,
                                        setMapPeriod,
                                      }}
                                    >
                                      <VehicleCoordinatesContext.Provider
                                        value={{
                                          vehiclecoordinates,
                                          setVehicleCoordinates,
                                          tripspointCoordinates,
                                          setTripspointCoordinates,
                                        }}
                                      >
                                        <LoaderContext.Provider
                                          value={{ setLoader }}
                                        >
                                          <MobilesDataContext.Provider
                                            value={{
                                              mobilesInfo,
                                              setmobilesInfo,
                                            }}
                                          >
                                            <VehiclesDataContext.Provider
                                              value={{
                                                vehiclesInfo,
                                                updateVehiclesInfo,
                                              }}
                                            >
                                              <VehicleDataContext.Provider
                                                value={{
                                                  vehicleData,
                                                  setVehicleData,
                                                }}
                                              >
                                                <SidebarOpenContext.Provider
                                                  value={{
                                                    sidebarOpen,
                                                    setSidebarOpen,
                                                  }}
                                                >
                                                  {ToastComponent}
                                                  <Routes />
                                                </SidebarOpenContext.Provider>
                                              </VehicleDataContext.Provider>
                                            </VehiclesDataContext.Provider>
                                          </MobilesDataContext.Provider>
                                        </LoaderContext.Provider>
                                      </VehicleCoordinatesContext.Provider>
                                    </FilterContext.Provider>
                                  </ShowFilterContext.Provider>
                                </MobileCoordinatesContext.Provider>
                              </DisplayCoordinatesContext.Provider>
                              {/* </ShowMapCustomContext.Provider> */}
                            </CalledReplayContext.Provider>
                            {/* </SidebarMarginTop.Provider> */}
                          </RadioButtonValueContext.Provider>
                        </MapLayerContext.Provider>
                      </initialApiCallContext.Provider>
                    </ReportExportContext.Provider>
                  </UserDetailsContext.Provider>
                </VehicleDataPopupContext.Provider>
              </MapColorContext.Provider>
            </InfoDataContext.Provider>
          </AccordionStateContext.Provider>
        </ViewportDataContext.Provider>
      </PathFilterContext.Provider>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1000 }}
        open={loader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default App;
