import React, { useContext, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import Icon from "@mui/material/Icon";
import MenuIcon from "@mui/icons-material/Menu";
import {
  iconColor,
  lineColor,
  primaryColor,
  scrollbarColor,
  textColor,
} from "../constants/color-constants";
import RegisterVehicle from "./RegisterVehicle";
import Device from "./Device";
import {
  AccordionStateContext,
  FilterContext,
  LoaderContext,
  MobilesDataContext,
  PathFilterContext,
  RadioButtonValueContext,
  ShowFilterContext,
  SidebarOpenContext,
  UserDetailsContext,
  VehiclesDataContext,
} from "../App";
import Drawer from "@mui/material/Drawer";
import { IMobileType, IVehicleType } from "../api/Api";
import MobileDevice from "./MobileDevice";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const Sidebar = () => {
  const { sidebarOpen, setSidebarOpen } = useContext(SidebarOpenContext);
  const { vehiclesInfo } = useContext(VehiclesDataContext);
  // const { showMapCustom, setShowMapCustom } = useContext(ShowMapCustomContext)
  const { mobilesInfo } = useContext(MobilesDataContext);
  const { showFilter, setShowFilter } = useContext(ShowFilterContext);
  const isMobile = useMediaQuery("(max-width:600px)");
  const [selected, setSelected] = useState<string | null>(null);
  const { mapperiod, period } = useContext(FilterContext);
  const { radioValue } = useContext(RadioButtonValueContext);
  const { tenants, role } = useContext(UserDetailsContext);
  const { pathFilter, setPathFilter } = useContext(PathFilterContext);
  const { setLoader } = useContext(LoaderContext);

  const { expandedAccordion, toggleAccordion } = useContext(
    AccordionStateContext
  );
  const isAccordionExpanded = (panel: string) => expandedAccordion.includes(panel);

  return (
    <>
      <Tooltip title="Menu">
        <Icon
          component={MenuIcon}
          sx={{
            color: iconColor,
            height: "25px",
            cursor: "pointer",
          }}
          onClick={() => {
            pathFilter && setLoader(true);
            sidebarOpen ? setSidebarOpen(false) : setSidebarOpen(true);
            setShowFilter(false);
            setPathFilter(false);
            // showMapCustom ? setShowMapCustom(false) : setShowMapCustom(true)
          }}
        />
      </Tooltip>

      <Drawer
        anchor="left"
        hideBackdrop
        open={sidebarOpen}
        disableEnforceFocus
        sx={{
          // height: '90vh',
          zIndex: "-2",
          width: isMobile ? "100vw" : "200px",
          // backgroundColor: primaryColor,
          ".css-4t3x6l-MuiPaper-root-MuiDrawer-paper": {
            height: "100vh",
          },
        }}
      >
        <Box
          className="container"
          // display="flex"
          // flexDirection="column"
          // justifyContent="space-between"
          bgcolor={primaryColor}
          width={isMobile ? "100vw" : "300px"}
          height="calc(100vh - 60px)"
          zIndex="1"
          // borderRight={`2px solid ${lineColor}`}
          // mt={isMobile ? sidebarMarginTop + 'px' : 'unset'}
          // mt={
          //   isMobile
          //     ? mapperiod === "custom" && period === "custom"
          //       ? showFilter
          //         ? "180px"
          //         : "60px"
          //       : mapperiod === "custom" || (period === "custom" && showFilter)
          //       ? showFilter
          //         ? "130px"
          //         : "60px"
          //       : showFilter
          //       ? "120px"
          //       : "0px"
          //     : "0px"
          // }
          mt={
            (isMobile &&
              showFilter &&
              mapperiod === "custom" &&
              period === "custom") ||
            (isMobile && showFilter && period === "custom")
              ? "180px"
              : (isMobile && showFilter && mapperiod === "custom") ||
                (isMobile && showFilter)
              ? "120px"
              : isMobile && mapperiod === "custom"
              ? "60px"
              : "unset"
          }
          pb="60px"
          overflow="auto"
          sx={{
            "&::-webkit-scrollbar": {
              width: "4px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: scrollbarColor,
              borderRadius: "4px",
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: lineColor,
            },
            scrollbarColor: `${scrollbarColor} ${lineColor}`,
            scrollbarWidth: "thin",
          }}
        >
          <Box
            className="register"
            p="80px 15px 15px 0px"
            // height="60px"
            // borderBottom={`1px solid ${lineColor}`}
            // display="flex"
            // justifyContent='space-between'
            alignItems="center"
          >
            <RegisterVehicle />
            {/* <RadioGroup
              name="use-radio-group"
              defaultValue="device"
              // onChange={handleRadioChange}
            >
              <Box display="flex" color={textColor}>
                <FormControlLabel
                  value="device"
                  label="Device"
                  control={<Radio style={{ color: `${iconColor}` }} />}
                />
                <FormControlLabel
                  value="mobile"
                  label="Mobile"
                  control={<Radio style={{ color: `${iconColor}` }} />}
                />
              </Box>
            </RadioGroup> */}
          </Box>

          {role === "sa" ? (
            tenants.map((tenant: any) => (
              <Accordion
                key={tenant.organization_id}
                disableGutters
                expanded={isAccordionExpanded(tenant.organization_id)}
                onChange={() => toggleAccordion(tenant.organization_id)}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon style={{ color: iconColor }} />}
                  aria-controls={`panel${tenant.organization_id}-content`}
                  id={`panel${tenant.organization_id}-header`}
                  sx={{
                    backgroundColor: primaryColor,
                    color: textColor,
                  }}
                >
                  {tenant.organization.name}
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    backgroundColor: primaryColor,
                    padding: "unset",
                  }}
                >
                  <Box
                    className="devices"
                    display="flex"
                    flexDirection="column"
                    flex="1"
                  >
                    {radioValue === "device"
                      ? vehiclesInfo.length > 0 &&
                        vehiclesInfo.map((vehicle: IVehicleType) => {
                          if (
                            vehicle?.organization_id?.toString() ===
                            tenant.organization_id
                          ) {
                            return (
                              <Device
                                key={vehicle.device_id}
                                data={vehicle}
                                selected={selected}
                                setSelected={setSelected}
                              />
                            );
                          }
                          return null;
                        })
                      : // vehiclesInfo.length > 0 &&
                        //   vehiclesInfo.map((vehicle: VehicleType) => (
                        //     <Device
                        //       data={vehicle}
                        //       key={vehicle.vehicle_id}
                        //       selected={selected}
                        //       setSelected={setSelected}
                        //     />
                        //   ))
                        mobilesInfo.length > 0 &&
                        mobilesInfo.map((mobile: IMobileType) => {
                          if (
                            mobile?.organization_id?.toString() ===
                            tenant.organization_id
                          ) {
                            return (
                              <MobileDevice
                                data={mobile}
                                key={mobile.device_id}
                                selected={selected}
                                setSelected={setSelected}
                              />
                            );
                          }
                          return null;
                        })}
                  </Box>
                </AccordionDetails>
              </Accordion>
            ))
          ) : (
            <Box
              className="devices"
              display="flex"
              flexDirection="column"
              flex="1"
            >
              {radioValue === "device"
                ? vehiclesInfo.length > 0 &&
                  vehiclesInfo.map((vehicle: IVehicleType) => (
                    <Device
                      data={vehicle}
                      key={vehicle.vehicle_id}
                      selected={selected}
                      setSelected={setSelected}
                    />
                  ))
                : mobilesInfo.length > 0 &&
                  mobilesInfo.map((mobile: IMobileType) => (
                    <MobileDevice
                      data={mobile}
                      key={mobile.device_id}
                      selected={selected}
                      setSelected={setSelected}
                    />
                  ))}
            </Box>
          )}

          {/* <Box
                        className='footer'
                        display='flex'
                        height='60px'
                        justifyContent='space-evenly'
                        alignItems='center'
                        borderTop={`1px solid ${lineColor}`}
                    >
                        {sidebarItems.map(item => {
                            return (
                                <Box
                                    display='flex'
                                    flexDirection='column'
                                    alignItems='center'
                                    width='25%'
                                    key={item.itemName}
                                >
                                    <Tooltip title={item.itemName}>
                                        <Icon
                                            component={item.itemIcon}
                                            onClick={() => item.navigateTo && navigate(item.navigateTo)}
                                            sx={{
                                                color: iconColor,
                                                height: '25px',
                                                cursor: 'pointer'
                                            }}
                                        />
                                    </Tooltip>
                                </Box>
                            )
                        })}
                    </Box> */}
        </Box>
      </Drawer>
    </>
  );
};

export default Sidebar;
