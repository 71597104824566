import React, { useState } from 'react';
import { Icon, Tooltip } from '@mui/material';
// import PersonIcon from '@mui/icons-material/Person';
import LogoutIcon from '@mui/icons-material/Logout';
import { iconColor } from '../constants/color-constants';
import { useNavigate } from 'react-router-dom';

const UserAccount = () => {

    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    
    const handleLogout = () => {
        localStorage.removeItem('sgs:access-token');
        localStorage.removeItem("sgs:checkedId");
        localStorage.removeItem("sgs:session_time");
        navigate('/');
      }

    return (
        <>
            <Tooltip title='Logout'>
                <Icon
                    component={LogoutIcon}
                    sx={{
                        color: iconColor,
                        height: '25px',
                        cursor: 'pointer'
                    }}
                    onClick={() => {
                        open ? setOpen(false) : setOpen(true) 
                        handleLogout();
                    }}
                />
            </Tooltip>
        </>
    )
}

export default UserAccount;
