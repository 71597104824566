import { useEffect, useMemo } from 'react'
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { getCurrentTenants } from '../api/Api';
import { addMinutes, format } from 'date-fns';

const CallBackHandler = () => {
    const { search } = useLocation();
    const params = useMemo(() => new URLSearchParams(search), [search]);
    const navigate = useNavigate();

    useEffect(() => {
        getCurrentTenants().then((data) => {
            const currentDate = new Date();
            const futureDate = addMinutes(currentDate, data.tenant.organization.session_time);
            localStorage.setItem("sgs:session_time", futureDate.toString());
            const accessToken = params?.get("access_token") || "";
            localStorage.setItem("sgs:access-token", accessToken);
            navigate('/');
        });
    }, [params,navigate])
    
 return null
}

export default CallBackHandler