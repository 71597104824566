import React from 'react';
import DescriptionIcon from '@mui/icons-material/Description';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import PersonIcon from '@mui/icons-material/Person';
import Sidebar from './Sidebar';
import { Box, useMediaQuery } from '@mui/material';
import { lineColor, primaryColor } from '../constants/color-constants';
import Report from './Report';
import Search from './Search';
import Notifications from './Notifications';
import UserAccount from './UserAccount';
import LogoutIcon from '@mui/icons-material/Logout';

const Navbar = () => {

    const navbarItems = [
        {
            itemName: 'Menu',
            itemIcon: MenuIcon,
            component: <Sidebar />
        },
        {
            itemName: 'Report',
            itemIcon: DescriptionIcon,
            component: <Report />
        },
        {
            itemName: 'Search',
            itemIcon: SearchIcon,
            component: <Search />
        },
        // {
        //     itemName: 'Notifications',
        //     itemIcon: NotificationsIcon,
        //     component: <Notifications />
        // },
        {
            itemName: 'Logout',
            // itemIcon: PersonIcon,
            itemIcon: LogoutIcon,
            component: <UserAccount />
        }
    ];
    const isMobile = useMediaQuery('(max-width:600px)');
    return (
        <>
            <Box
                className='navbar'
                display='flex'
                height='60px'
                justifyContent='space-evenly'
                alignItems='center'
                width={isMobile ? '100vw' : '300px'}
                bgcolor={primaryColor}
                position='absolute'
                bottom='0'
                borderTop={`2px solid ${lineColor}`}
                borderRight={isMobile ? 'none' : `2px solid ${lineColor}`}
                zIndex='-1'
            >
                {navbarItems.map(item => {
                    return (
                        <Box
                            display='flex'
                            flexDirection='column'
                            alignItems='center'
                            width='25%'
                            key={item.itemName}
                        >
                            {item.component}
                        </Box>
                    )
                })}
            </Box>
        </>
    )
}

export default Navbar;
