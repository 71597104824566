import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  // Icon,
  // Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import AirportShuttleIcon from "@mui/icons-material/AirportShuttle";
import {
  batteryFull,
  batteryLow,
  batteryMedium,
  iconColor,
  ignitionOff,
  ignitionOn,
  textColor,
} from "../constants/color-constants";
import {
  DisplayCoordinatesContext,
  InfoDataContext,
  // LoaderContext,
  SidebarOpenContext,
  VehicleCoordinatesContext,
  VehicleDataContext,
  VehicleDataPopupContext,
  VehiclesDataContext,
  initialApiCallContext,
} from "../App";
import { IVehicleCoordinates, IVehicleType } from "../api/Api";
// import DeleteIcon from "@mui/icons-material/Delete";
// import EditVehicle from "./EditVehicle";
// import { failed, message, success } from "../hooks/useToast";
import Battery0BarIcon from "@mui/icons-material/Battery0Bar";
import Battery2BarIcon from "@mui/icons-material/Battery2Bar";
import Battery4BarIcon from "@mui/icons-material/Battery4Bar";
import Battery5BarIcon from "@mui/icons-material/Battery5Bar";
import Battery6BarIcon from "@mui/icons-material/Battery6Bar";
import BatteryStdIcon from "@mui/icons-material/BatteryStd";
import CarRentalIcon from "@mui/icons-material/CarRental";

const Device = (props: {
  data: IVehicleType;
  setSelected: React.Dispatch<React.SetStateAction<string | null>>;
  selected: string | null;
}) => {
  const isMobile = useMediaQuery("(max-width:600px)");
  const { setSidebarOpen } = useContext(SidebarOpenContext);
  const { setVehicleData } = useContext(VehicleDataContext);
  const { setInfoData } = useContext(InfoDataContext);
  const { vehiclesInfo } = useContext(VehiclesDataContext);
  const { vehiclecoordinates } = useContext(VehicleCoordinatesContext);
  // const { setLoader } = useContext(LoaderContext);
  const { displayCoordinates, setDisplayCoordinates } = useContext(
    DisplayCoordinatesContext
  );
  const { setInitialApiCall } = useContext(initialApiCallContext);
  const { setVehicleDataPopup } = useContext(VehicleDataPopupContext);
  const [online, setOnline] = useState<boolean>();

  const filteredCoordinateData = vehiclecoordinates.filter(
    (item: IVehicleCoordinates) => item.device_id === props.data.device_id
  );
  const filteredData = vehiclesInfo.filter(
    (item: IVehicleType) => item.device_id === props.data.device_id
  );
  const foundData = filteredCoordinateData.length
    ? filteredCoordinateData
    : filteredData;

  const formatToDaysHrMin = () => {
    const createdAtDateString = filteredCoordinateData[0]?.created_at;

    if (!createdAtDateString) {
      return;
    }

    const createdAtDate: Date = new Date(createdAtDateString);

    const createMonthName = createdAtDate.toLocaleString("en-US", {
      month: "short",
    });

    const createHours = String(createdAtDate.getHours()).padStart(2, "0");
    const createMinutes = String(createdAtDate.getMinutes()).padStart(2, "0");
    // Get hours and minutes in 12-hour format with AM/PM
    // const createHours = createdAtDate.getHours() % 12 || 12; // Convert 0 to 12
    // const createMinutes = createdAtDate.getMinutes();
    // const createAmOrPm = createdAtDate.getHours() < 12 ? "AM" : "PM";

    const formattedDate = `${createMonthName} ${createdAtDate.getDate()} (${createHours}:${createMinutes})`;

    const currentDate: Date = new Date();

    const todayStartString = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate(),
      0,
      0,
      0
    ).toISOString();

    // Convert todayStartString back to Date object
    const todayStart: Date = new Date(todayStartString);

    const todayEndString = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate(),
      23,
      59,
      59
    ).toISOString();

    const todayEnd: Date = new Date(todayEndString);
    const timeDifference: number =
      currentDate.getTime() - createdAtDate.getTime();
    const days = Math.floor(timeDifference / (24 * 60 * 60 * 1000));
    const hours = Math.floor(
      (timeDifference % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000)
    );
    const minutes = Math.floor(
      (timeDifference % (60 * 60 * 1000)) / (60 * 1000)
    );
    if (createdAtDate >= todayStart && createdAtDate <= todayEnd) {
      if (days > 0) {
        return `${days} day${days > 1 ? "s" : ""} ago`;
      } else if (hours > 0) {
        return `${hours} hour${hours > 1 ? "s" : ""} ago`;
      } else if (minutes > 2) {
        return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
      } else {
        return <span style={{ color: ignitionOn }}>Online</span>;
      }
    } else {
      return <span>{`Last: ${formattedDate}`}</span>;
    }
  };

  const getBatteryIcon = () => {
    const batteryLevel = filteredCoordinateData[0]?.battery_level;

    if (batteryLevel === 0) {
      return <Battery0BarIcon sx={{ color: batteryLow }} />;
    } else if (batteryLevel > 0 && batteryLevel <= 30) {
      return <Battery2BarIcon sx={{ color: batteryLow }} />;
    } else if (batteryLevel > 30 && batteryLevel <= 50) {
      return <Battery4BarIcon sx={{ color: batteryMedium }} />;
    } else if (batteryLevel > 50 && batteryLevel <= 70) {
      return <Battery5BarIcon sx={{ color: batteryMedium }} />;
    } else if (batteryLevel > 70 && batteryLevel <= 90) {
      return <Battery6BarIcon sx={{ color: batteryFull }} />;
    } else if (batteryLevel > 90 && batteryLevel <= 100) {
      return <BatteryStdIcon sx={{ color: batteryFull }} />;
    } else {
      return <Battery0BarIcon sx={{ color: "rgb(255,255,255,0.8)" }} />;
    }
  };

  useEffect(() => {
    if (filteredCoordinateData[0]?.ignition === 1) {
      setOnline(true);
    } else {
      setOnline(false);
    }
  }, [filteredCoordinateData]);

  // const [editid, setEditid] = useState("");
  const checkDisplayCoordinates = displayCoordinates.includes(
    props.data.device_id
  );

  const handleCheckboxChange =
    (device_id: string) => (event: ChangeEvent<HTMLInputElement>) => {
      const { checked } = event.target;
      if (checked) {
        setDisplayCoordinates((prev: string[]) => [...prev, device_id]);
        setVehicleData(undefined);
        props.setSelected(null);
        setInitialApiCall(false);
      } else {
        setDisplayCoordinates((prev: string[]) =>
          prev.filter((item: string) => item !== device_id)
        );
        setVehicleData(undefined);
        props.setSelected(null);
        setInitialApiCall(false);
      }
      isMobile && setSidebarOpen(false);
    };

  useEffect(() => {
    localStorage.setItem("sgs:checkedId", JSON.stringify(displayCoordinates));
  }, [displayCoordinates]);

  // const handleEdit = (device_id: string) => {
  //   setEditid(device_id);
  // };

  // const handleDelete = (device_id: string) => {
  //   message().then((result) => {
  //     if (result.isConfirmed) {
  //       setLoader(true);
  //       deleteRegister(device_id)
  //         .then((response) => {
  //           const { status } = response;
  //           if (status === 204) {
  //             const updatedInfo = vehiclesInfo.filter(
  //               (vehicle: IVehicleType) => vehicle.device_id !== device_id
  //             );
  //             updateVehiclesInfo(updatedInfo);
  //             setInitialApiCall(false);
  //             success();
  //           } else {
  //             failed();
  //           }
  //         })
  //         .finally(() => {
  //           setLoader(false);
  //         });
  //     }
  //   });
  // };

  const handleSelected = (device_id: string) => {
    setInfoData(foundData);
    setVehicleDataPopup("vehicle");
    // if (checkDisplayCoordinates) {
    setDisplayCoordinates((prev: string[]) => [...prev, device_id]);
    setVehicleData(props.selected === device_id ? undefined : foundData);
    props.setSelected(props.selected === device_id ? null : device_id);
    // }
    if (props.selected === device_id) {
      setInitialApiCall(false);
    }
    if (isMobile) {
      setSidebarOpen(false);
    }
  };

  return (
    <>
      <Box
        // onClick={() => setVehicleData(props.data)}
        display="flex"
        justifyContent="space-between"
        p="10px 15px 10px 2px"
        gap="4px"
        alignItems="center"
        sx={{
          cursor: "pointer",
          // position: 'relative',
          "&:hover": {
            backgroundColor: "rgb(197,198,199,0.3)",
            ".name, .ignitiontext, .battery": {
              color: 'rgb(255,255,255)',
            },
            ".onlineTime": {
              color: online ? ignitionOn : 'rgb(255,255,255)',
            },
          },
          backgroundColor:
            // props.selected === props.data.device_id && checkDisplayCoordinates
            props.selected === props.data.device_id && checkDisplayCoordinates
              ? "rgb(197,198,199,0.3)"
              : "unset",
        }}
      >
        <Checkbox
          checked={checkDisplayCoordinates}
          sx={{
            fontSize: "2px",
            // position:"absolute",
            // left:"0",
            // top:"15px",
            // transform:"translateY(-50%)",
            // opacity: '0.5',
            color: iconColor,
            "&.Mui-checked": {
              color: iconColor,
            },
          }}
          onChange={handleCheckboxChange(props.data.device_id)}
        />
        <Box
          display="flex"
          width="95vw"
          gap="10px"
          onClick={() => handleSelected(props.data.device_id)}
        >
          <Box
            className="icon"
            // bgcolor="rgb(255,255,255,0.15)"
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{
              width: "42px",
              height: "42px",
              borderRadius: "21px",
            }}
          >
            <AirportShuttleIcon
              sx={{
                color: props.data.color,
                height: "25px",
                width: "25px",
              }}
            />
          </Box>

          <Box
            className="details"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            flex="1"
            color={textColor}
          >
            <Box display="flex" flexDirection="column">
              <Typography
                className="name"
                fontSize="17px"
                width={isMobile ? "140px" : "100px"}
                noWrap
                sx={{
                  color:
                    props.selected === props.data.device_id &&
                    checkDisplayCoordinates
                      ? 'rgb(255,255,255)'
                      : "unset",
                }}
              >
                {props.data.name}
              </Typography>
              <Typography
                className="onlineTime"
                fontSize="10px"
                width={isMobile ? "140px" : "100px"}
                noWrap
                // color={online ? ignitionOn : "unset"}
                // fontWeight={online ? 'bold' : 'unset'}
                sx={{
                  color: online
                    ? ignitionOn
                    : props.selected === props.data.device_id &&
                      checkDisplayCoordinates
                    ? 'rgb(255,255,255)'
                    : "unset",
                }}
              >
                {online ? "Online" : formatToDaysHrMin()}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" gap="5px">
          {/* <Tooltip title={`Ignition: ${online ? "ON" : "OFF"}`}> */}
          <Typography
            className="ignition"
            sx={{
              fontSize: "13px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <CarRentalIcon
              sx={{
                color: online ? ignitionOn : ignitionOff,
              }}
            />
            <Typography
              className="ignitiontext"
              sx={{
                fontSize: "8px",
                color:
                  props.selected === props.data.device_id &&
                  checkDisplayCoordinates
                    ? 'rgb(255,255,255)'
                    : textColor,
              }}
            >{`${online ? "ON" : "OFF"}`}</Typography>
          </Typography>
          {/* </Tooltip> */}
          {
            !online && (
              // <Tooltip
              //   title={`Battery: ${
              //     filteredCoordinateData[0]?.battery_level
              //       ? filteredCoordinateData[0]?.battery_level
              //       : 0
              //   }%`}
              // >
              <Typography
                className="battery"
                sx={{
                  fontSize: "13px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                {/* {props.data.vehicle_id} */}
                {getBatteryIcon()}
                <Typography
                  className="battery"
                  sx={{
                    fontSize: "9px",
                    color:
                      props.selected === props.data.device_id &&
                      checkDisplayCoordinates
                        ? 'rgb(255,255,255)'
                        : textColor,
                  }}
                >
                  {filteredCoordinateData[0]?.battery_level !== null &&
                  filteredCoordinateData[0]?.battery_level !== undefined
                    ? `${filteredCoordinateData[0]?.battery_level}%`
                    : "-"}
                </Typography>
              </Typography>
            )
            // </Tooltip>
          }
          {/* <Box onClick={() => handleEdit(props.data.device_id)}>
            <EditVehicle data={editid} check="device" />
          </Box>
          <Icon
            component={DeleteIcon}
            onClick={() => handleDelete(props.data.device_id)}
          /> */}
        </Box>
      </Box>
    </>
  );
};

export default Device;
