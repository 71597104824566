export const primaryColor = '#1F2833'
export const secondaryColor = '#192029'
export const lineColor = '#66FCF1'
export const textColor = '#C5C6C7'
export const iconColor = '#66FCF1'
export const selectedMenuColor = '#66FCF1'
export const selectedMenuColorHover = '#51c9c0'
export const scrollbarColor = '#808080'
export const errorMessageColor = '#ff3333'
export const batteryLow = '#FF0000'
export const batteryMedium = '#ffcc00'
export const batteryFull = '#339900'
export const ignitionOn = '#339900'
export const ignitionOff = '#FF0000'
