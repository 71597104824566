import React, { useContext, useMemo, useState } from "react";
import DeckGL from "@deck.gl/react/typed";
import { FlyToInterpolator } from "deck.gl/typed";
import { TileLayer } from "@deck.gl/geo-layers/typed";
import {
  BitmapBoundingBox,
  BitmapLayer,
  IconLayer,
} from "@deck.gl/layers/typed";
import vehicle from "../assets/images/icons.png";
import { Box } from "@mui/material";
import { MapColorContext, MapLayerContext } from "../App";

const StopMap = (props: { data: number[] }) => {
  const { layer } = useContext(MapLayerContext);
  const {mapColor} = useContext(MapColorContext);

  const [initialViewState] = useState({
    longitude: 85.308,
    latitude: 27.6972,
    zoom: 10.7,
    pitch: 0,
    bearing: 0,
    transitionDuration: 1000,
    transitionInterpolator: new FlyToInterpolator(),
  });

  const layers = useMemo(() => {
    const iconMapping = {
      vehicle: { x: 0, y: 0, width: 579, height: 579, mask: false },
      top_car: { x: 180, y: 150, width: 180, height: 160, mask: true },
    };

    return [
      new TileLayer({
        data: layer,
        minZoom: 0,
        maxZoom: 19,
        tileSize: 256,
        renderSubLayers: (props) => {
          const { boundingBox } = props.tile;
          return new BitmapLayer(props, {
            data: undefined,
            image: props.data,
            bounds: boundingBox.flatMap((x) => x) as BitmapBoundingBox,
          });
        },
      }),
      new IconLayer({
        id: "vehicle",
        data: [{ coordinates: props.data }],
        pickable: true,
        iconAtlas: vehicle,
        iconMapping: iconMapping,
        sizeScale: 6,
        getIcon: () => "top_car",
        getPosition: (d) => {
          return d.coordinates;
        },
        getSize: () => 6,
        // onClick: d => handleIconClick(d.object),
        getColor: () =>{
          const rgbArray = mapColor.match(/\d+/g).map(Number).slice(0, 3);
          return rgbArray
        }
        // getColor: [16, 14, 285],
      }),
    ];
  }, [layer, props.data, mapColor]);

  return (
    <>
      <Box display="flex">
        <DeckGL
          initialViewState={initialViewState}
          controller={true}
          layers={layers}
          getCursor={(isDragging) => {
            return isDragging.isDragging
              ? "grabbing"
              : isDragging.isHovering
              ? "pointer"
              : "grab";
          }}
          // style={{ zIndex: '2' }}
          // onClick={ d => {
          // d.object && setPopupData(d);
          //     !d.object && setPopupData(undefined);
          // }}
        ></DeckGL>
      </Box>
    </>
  );
};

export default StopMap;
