import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { IFuelType, IMobileType, IVehicleType, getFuelById } from "../api/Api";
import { DialogContent } from "@mui/material";
import {
  iconColor,
  lineColor,
  primaryColor,
  scrollbarColor,
  textColor,
} from "../constants/color-constants";
import FuelForm from "./FuelForm";
import { failed } from "../hooks/useToast";
import VehicleImage from "./VehicleImage";
import { API } from "../config";
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function DetailsTabs(props: {
  data: IVehicleType | IMobileType;
  check: string;
  setFormValidation: React.Dispatch<React.SetStateAction<boolean>>;
  setFuelValidation: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const [fuelData, setFuelData] = React.useState<IFuelType[]>([]);

  const calculateAverageMileage = (fuelData: IFuelType[]) => {
    if (fuelData.length === 2) {
      return (
        fuelData
          .reduce(
            (maxData, data) => (data.date > maxData.date ? data : maxData),
            fuelData[0]
          )
          ?.last_mileage?.toFixed(2) + " km"
      );
    }
    if (fuelData.length < 2) {
      return "No Data";
    }

    const totalMileage = fuelData.reduce(
      (total, data) => total + (data.last_mileage ?? 0),
      0
    );
    const averageMileage = (totalMileage / (fuelData.length - 1)).toFixed(2);

    return `${averageMileage} km`;
  };

  React.useEffect(() => {
    getFuelById(props.data.device_id).then((data) => {
      if (data.error) {
        failed();
      } else {
        setFuelData(data);
      }
    });
  }, [props.data.device_id]);

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          backgroundColor: primaryColor,
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="Details tabs"
          TabIndicatorProps={{ style: { backgroundColor: lineColor } }}
          sx={{
            ".MuiButtonBase-root.MuiTab-root.Mui-selected": {
              color: lineColor,
            },
            ".MuiButtonBase-root.MuiTab-root": {
              color: textColor,
            },
          }}
        >
          <Tab
            label={
              props.check === "vehicle" && "name" in props.data
                ? "Vehicle"
                : "Mobile"
            }
            {...a11yProps(0)}
          />
          {props.check === "vehicle" && "name" in props.data && (
            <Tab label="Fuel" {...a11yProps(1)} />
          )}
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <DialogContent
          sx={{
            backgroundColor: primaryColor,
            color: textColor,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            "&::-webkit-scrollbar": {
              width: "4px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: scrollbarColor,
              borderRadius: "4px",
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: lineColor,
            },
          }}
        >
          {props.check === "vehicle" && "name" in props.data && (
            <>
              <Box display="grid" gridTemplateColumns="2fr 1fr" gap="10px">
                <Typography>Vehicle Name</Typography>
                <Typography className="name">{props.data.name}</Typography>

                <Typography>Vehicle Number</Typography>
                <Typography className="vehicleId">
                  {props.data.vehicle_id}
                </Typography>

                <Typography>Driver ID</Typography>
                <Typography className="driverId">
                  {props.data.driver_id}
                </Typography>

                <Typography>Device ID</Typography>
                <Typography className="uniqueId">
                  {props.data.unique_id}
                </Typography>

                {/* <Typography>Average Mileage</Typography>
                <Typography className="averageMileage"> */}
                  {/* {props.data.mileage} km */}
                  {/* {calculateAverageMileage(fuelData)}
                </Typography> */}

                {/* <Typography>Last Mileage</Typography>
                <Typography className="lastMileage"> */}
                  {/* {props.data.mileage} km */}
                  {/* {fuelData.length > 1 && fuelData[0]?.last_mileage !== null
                    ? fuelData
                        .reduce(
                          (maxData, data) =>
                            data.date > maxData.date ? data : maxData,
                          fuelData[0]
                        )
                        ?.last_mileage?.toFixed(2) + " km"
                    : "No Data"}
                </Typography> */}

                <Typography>Fuel Capacity</Typography>
                <Typography className="fuelCapacity">
                  {props.data.fuel_capacity} L
                </Typography>

                <Typography>Last Fuel Date</Typography>
                <Typography className="lastFuelDate">
                  {fuelData.length > 0
                    ? fuelData
                        .reduce(
                          (maxDate, data) =>
                            data.date > maxDate ? data.date : maxDate,
                          fuelData[0].date
                        )
                        .substring(0, 10)
                    : "No Data"}
                </Typography>
                <Typography>Vehicle Images</Typography>
                <Typography className="vehicleImages">
                  {props.check === "vehicle" &&
                    "name" in props.data &&
                    props.data.images &&
                    props.data.images[0] && (
                      <Box marginRight="10px">
                        <img
                          src={`${API}/${props.data.images[0].image_url}`}
                          alt={`Preview`}
                          style={{
                            maxWidth: "100px",
                            maxHeight: "100px",
                            marginRight: "5px",
                            marginBottom: "5px",
                          }}
                        />
                      </Box>
                    )}
                  <Box
                    className="details"
                    textAlign="center"
                    display="flex"
                    alignItems="center"
                  >
                    {props.data.images && props.data.images.length > 0 ? (
                      <VehicleImage data={props.data} />
                    ) : (
                      <Typography fontSize="15px">
                        No Image
                      </Typography>
                    )}
                  </Box>
                </Typography>
              </Box>
            </>
          )}
          {props.check === "mobile" && "fullname" in props.data && (
            <Box display="grid" gridTemplateColumns="0.5fr 1fr" gap="10px">
              <Typography>Device ID</Typography>
              <Typography className="deviceId">
                {props.data.device_id}
              </Typography>

              <Typography>Full Name</Typography>
              <Typography className="fullName">
                {props.data.fullname}
              </Typography>

              <Typography>Email</Typography>
              <Typography className="email">{props.data.email}</Typography>

              <Typography>Phone</Typography>
              <Typography className="phone">{props.data.phone}</Typography>
            </Box>
          )}
        </DialogContent>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <FuelForm
          setFuelData={setFuelData}
          fuelData={fuelData}
          device_id={props.data.device_id}
          setFormValidation={props.setFormValidation}
          setFuelValidation={props.setFuelValidation}
          fuelCapacity={
            props.check === "vehicle" && "name" in props.data
              ? props.data.fuel_capacity
              : "0"
          }
        />
      </CustomTabPanel>
    </Box>
  );
}
