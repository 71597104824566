import React, { useContext, useEffect, useMemo, useState } from "react";
import DeckGL from "@deck.gl/react/typed";
import { FlyToInterpolator, PickingInfo } from "deck.gl/typed";
import { TileLayer } from "@deck.gl/geo-layers/typed";
import {
  BitmapBoundingBox,
  BitmapLayer,
  IconLayer,
  PathLayer,
} from "@deck.gl/layers/typed";
import vehicle from "../assets/images/icons.png";
import { Box, Typography } from "@mui/material";
import mark from "../assets/images/icons.png";
import {
  MapColorContext,
  MapLayerContext,
  VehicleCoordinatesContext,
} from "../App";
import WebMercatorViewport from "viewport-mercator-project";
import { iconColor, primaryColor, textColor } from "../constants/color-constants";
import CloseIcon from "@mui/icons-material/Close";
import { format } from "date-fns";

const TripMap = (props: { data: number[] }) => {
  const { layer } = useContext(MapLayerContext);
  const { mapColor } = useContext(MapColorContext);
  const { tripspointCoordinates } = useContext(VehicleCoordinatesContext);

  const [initialViewState] = useState({
    longitude: 85.308,
    latitude: 27.6972,
    zoom: 10.7,
    pitch: 0,
    bearing: 0,
    transitionDuration: 1000,
    transitionInterpolator: new FlyToInterpolator(),
  });

  const layers = useMemo(() => {
    const iconMapping = {
      vehicle: { x: 0, y: 0, width: 579, height: 579, mask: false },
      top_car: { x: 180, y: 150, width: 180, height: 160, mask: true },
      mark: { x: 0, y: 0, width: 180, height: 160, mask: true },
    };

    return [
      new TileLayer({
        data: layer,
        minZoom: 0,
        maxZoom: 19,
        tileSize: 256,
        renderSubLayers: (props) => {
          const { boundingBox } = props.tile;
          return new BitmapLayer(props, {
            data: undefined,
            image: props.data,
            bounds: boundingBox.flatMap((x) => x) as BitmapBoundingBox,
          });
        },
      }),
      new IconLayer({
        id: "vehicle",
        data: [{ coordinates: props.data }],
        pickable: true,
        iconAtlas: vehicle,
        iconMapping: iconMapping,
        sizeScale: 6,
        getIcon: () => "top_car",
        getPosition: (d) => JSON.parse(d.coordinates)[0],
        getSize: () => 6,
        // onClick: d => handleIconClick(d.object),
        getColor: () => {
          const rgbArray = mapColor.match(/\d+/g).map(Number).slice(0, 3);
          return rgbArray;
        },
      }),
      new PathLayer({
        id: "vehiclePath",
        data: [{ coordinates: props.data }],
        pickable: true,
        capRounded: true,
        jointRounded: true,
        billboard: true,
        widthMinPixels: 3,
        autoHighlight: true,
        highlightColor: [6, 39, 103],
        getColor: () => {
          const rgbArray = mapColor.match(/\d+/g).map(Number).slice(0, 3);
          return rgbArray;
        },
        getWidth: 0,
        getPath: (d) => JSON.parse(d.coordinates),
        // getDashArray: [4,4],
        // dashJustified: true,
        // parameters:{
        //     depthMask: false,
        // },
      }),
      new IconLayer({
        id: "vehiclepointmark",
        data: tripspointCoordinates,
        pickable: true,
        iconAtlas: mark,
        iconMapping: iconMapping,
        sizeScale: 6,
        getIcon: () => "mark",
        getPosition: (d) => {
          try {
            return d.coordinates;
          } catch (error) {
            console.error("Error parsing coordinates:", error);
            // return [0, 0];
          }
        },
        getSize: (d) => {
          return 2;
        },
        getColor: (d) => {
          const rgbArray = d.color.match(/\d+/g).map(Number).slice(0, 3);
          return rgbArray;
        },
        onClick: (d) => {
          setTripPopupData(d);
        },
      }),
    ];
  }, [layer, props.data, tripspointCoordinates, mapColor]);

  const dateFormat = "yyy-MM-dd";
  const timeFormat = "HH:mm";
  const formatDateAndTime = (value: Date) => {
    const updatedDate = new Date(value);
    updatedDate.setHours(updatedDate.getHours() + 5);
    updatedDate.setMinutes(updatedDate.getMinutes() + 45);

    // Format the updated date and time
    const formattedDate = format(updatedDate, dateFormat);
    const formattedTime = format(updatedDate, timeFormat);

    // Return the formatted date and time
    return `${formattedDate} (${formattedTime})`;
};


  const [TripPopupData, setTripPopupData] = useState<PickingInfo>();
  const [updatedPopup, setUpdatedPopup] = useState<PickingInfo>();
  const [mapViewState, setMapViewState] = useState(initialViewState);

  const calculateNewXY = (
    viewState: any,
    longitude: number,
    latitude: number
  ) => {
    const viewport = new WebMercatorViewport(viewState);
    const [x, y] = viewport.project([longitude, latitude]);
    return [x, y];
  };

  const handleViewStateChange = ({ viewState }: any) => {
    setMapViewState(viewState);
  };

  useEffect(() => {
    const updatePopupPosition = () => {
      if (TripPopupData) {
        const [newX, newY] = calculateNewXY(
          mapViewState,
          TripPopupData?.coordinate !== undefined ? TripPopupData?.coordinate[0] : 0,
          TripPopupData?.coordinate !== undefined ? TripPopupData?.coordinate[1] : 0
        );
        const updatedPopupInfo = { ...TripPopupData, x: newX, y: newY };
        setUpdatedPopup(updatedPopupInfo);
      }
     };

    updatePopupPosition();
  }, [TripPopupData, mapViewState]);

  const renderPopup = () => {
    if (TripPopupData) {
      return (
        // isHovered &&
        <Box
          width="200px"
          height="55px"
          bgcolor={primaryColor}
          position="absolute"
          top="0"
          left="0"
          padding="4px"
          sx={{
            transform: `translate(${
              updatedPopup?.x !== undefined && updatedPopup?.x - 100
            }px, ${updatedPopup?.y !== undefined && updatedPopup?.y - 85}px)`,
            // transform: `translate(${popupData.x - 100}px, ${popupData.y - 85}px)`,
            border: `1px solid ${iconColor}`,
            borderRadius: "10px",
          }}
          // onMouseEnter={()=>setIsHovered(true)}
          // onMouseLeave={!isMobile ? ()=>setIsHovered(false) : undefined}
        >
          <Box
                  className="details"
                  textAlign="center"
                  color={textColor}
                  key={TripPopupData.object.device_id}
                >
                  <CloseIcon
                    onClick={() => setTripPopupData(undefined)}
                    sx={{
                      cursor: "pointer",
                      fontSize: "20px",
                      position: "absolute",
                      right: "0",
                    }}
                  />
                  <Typography className="name" fontSize="12px" paddingTop="4px">
                    {formatDateAndTime(TripPopupData.object.fix_time)}
                  </Typography>
                  <Typography className="uniqueId" fontSize="12px">
                    {TripPopupData.object.address}
                  </Typography>
                  <Box
                    sx={{
                      cursor: "pointer",
                      position: "absolute",
                      right: "2px",
                      bottom: "1px",
                    }}
                  ></Box>
                </Box>
        </Box>
        )
    }
  }
  return (
    <>
      <Box display="flex">
        <DeckGL
          initialViewState={initialViewState}
          onViewStateChange={handleViewStateChange}
          controller={true}
          layers={layers}
          getCursor={(isDragging) => {
            return isDragging.isDragging
              ? "grabbing"
              : isDragging.isHovering
              ? "pointer"
              : "grab";
          }}
          // style={{ zIndex: '2' }}
          // onClick={ d => {
          // d.object && setPopupData(d);
          //     !d.object && setPopupData(undefined);
          // }}
        >
          {renderPopup()}
        </DeckGL>
      </Box>
    </>
  );
};
export default TripMap;
