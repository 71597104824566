import React from 'react';
import { useRoutes } from 'react-router-dom';
import MapPage from '../pages/MapPage';
// import ReportPage from '../pages/ReportPage';
import CallBackHandler from '../pages/CallBackHandler';

const Routes = () => {
    return useRoutes([
        {
            path: '/',
            element: <MapPage />
        },
        {
            path: "/callback",
            element: <CallBackHandler />,
        },
        // {
        //     path: '/report',
        //     element: <ReportPage />
        // }
    ])
}

export default Routes;
