import React, { useContext, useState } from "react";
import { Button, Modal, ToggleButton, ToggleButtonGroup, useMediaQuery } from "@mui/material";
import {
    iconColor,
  primaryColor,
  secondaryColor,
  textColor,
} from "../constants/color-constants";
import LayersIcon from "@mui/icons-material/Layers";
import { MapLayerContext } from "../App";

const ToggleMap = () => {

  const isMobile = useMediaQuery("(max-width:600px)");
  const { layer, setLayer } = useContext(MapLayerContext);
  const [open, setOpen] = useState(false);

  const handleMapModal = () => {
    setOpen(open ? false : true);
  };

  const handleChange = (
    e: React.MouseEvent<HTMLElement>,
    nextView: string
  ) => {
    e.preventDefault();
    setLayer(nextView === null ? layer : nextView);
    setOpen(false);
  };

  const mapLayer = [
    {
      name: "Google Map",
      value: "https://mt0.google.com/vt/lyrs=m&hl=sl&x={x}&y={y}&z={z}&s=Ga",
    },
    {
      name: "Google Map with Traffic",
      value:
        "https://mt0.google.com/vt/lyrs=m,traffic&hl=sl&x={x}&y={y}&z={z}&s=Ga",
    },
    {
      name: "Open Street Map",
      value: "https://c.tile.openstreetmap.org/{z}/{x}/{y}.png",
    },
    {
      name: "Google Satellite",
      value: "https://mt0.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}&s=Ga",
    },
  ];

  return (
    <>
    {  
       !open && 
      <Button
        variant="contained"
        sx={{
          position: "absolute",
          right: "3px",
          bottom: isMobile ? "75px" : "10px",
          backgroundColor: primaryColor,
          minWidth: "30px",
          padding: "6px 10px",
          zIndex: "-2",
          "&:hover": {
            backgroundColor: secondaryColor,
          },
        }}
        onClick={handleMapModal}
      >
        <LayersIcon 
            sx={{
                color: iconColor
            }}
        />
      </Button>
    }

      <Modal
        open={open}
        onClose={handleMapModal}
        disableEnforceFocus
        sx={{
          "& .MuiBackdrop-root": {
            backgroundColor: "unset",
          },
        }}
      >
        <ToggleButtonGroup
          orientation="vertical"
          exclusive
          value={layer}
          onChange={handleChange}
          sx={{
            position: "absolute",
            right: "3px",
            bottom: isMobile ? "75px" : "10px",
            backgroundColor: primaryColor,
            minWidth: "30px",
            padding: "6px 10px",
          }}
        >
          {mapLayer.map((layers) => (
            <ToggleButton
              value={layers.value}
              aria-label={layers.name}
              key={layers.value}
              sx={{
                color: textColor,
                border: "unset",
                "&.Mui-selected": {
                  color: textColor,
                  backgroundColor: secondaryColor,
                },
                "&:hover": {
                  backgroundColor: secondaryColor,
                },
              }}
            >
              {layers.name}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </Modal>
    </>
  );
};

export default ToggleMap;
