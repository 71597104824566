import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { iconColor, primaryColor, textColor } from "../constants/color-constants";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { IVehicleType } from "../api/Api";
import { API } from "../config";
import { Link } from "react-router-dom";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import AirportShuttleIcon from "@mui/icons-material/AirportShuttle";
import CloseIcon from "@mui/icons-material/Close";

const VehicleImage = (props: { data: IVehicleType }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Box
        onClick={() => setOpen(true)}
        sx={{
          cursor: "pointer",
        }}
      >
        <Typography fontSize="15px" color={iconColor}>
          View All Images
        </Typography>
      </Box>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        PaperProps={{
          sx: {
            border: `2px solid ${iconColor}`,
            borderRadius: "10px",
          },
        }}
      >
        <DialogTitle
          sx={{
            backgroundColor: primaryColor,
            color: iconColor,
            display: "flex",
            // justifyContent: isMobile ? "unset" : "space-between",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              // justifyContent: isMobile ? "unset" : "space-between",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <AirportShuttleIcon />
            <span>Vehicle Images</span>
          </Box>
          <Box>
            <CloseIcon
              onClick={() => setOpen(false)}
              sx={{
                height: "22px",
                cursor: "pointer",
                color: textColor
              }}
            />
          </Box>
        </DialogTitle>
        <DialogContent
          sx={{
            padding: "0px",
            backgroundColor: primaryColor,
          }}
        >
          <Carousel>
            {props.data.images &&
              props.data.images.map((image: any, index: any) => (
                <div key={index}>
                  <img
                    src={`${API}/${image.image_url}`}
                    alt={`Vehicle ${index}`}
                    style={{
                      maxWidth: "200px",
                      maxHeight: "200px",
                      position: "relative",
                    }}
                  />
                  <Box
                    style={{
                      position: "absolute",
                      bottom: 10,
                      right:
                        props.data.images && props.data.images?.length === 1
                          ? 0
                          : 30,
                    }}
                  >
                    <Link to={`${API}/${image.image_url}`} target="_blank">
                      <FullscreenIcon />
                    </Link>
                  </Box>
                </div>
              ))}
          </Carousel>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default VehicleImage;
