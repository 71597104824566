import { Box, Dialog, DialogTitle, Typography } from "@mui/material";
import React, { useState } from "react";
import {
  errorMessageColor,
  iconColor,
  primaryColor,
  textColor,
} from "../constants/color-constants";
import { IMobileType, IVehicleType } from "../api/Api";
import AirportShuttleIcon from "@mui/icons-material/AirportShuttle";
import CloseIcon from "@mui/icons-material/Close";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import DetailsTabs from "./DetailsTabs";

const MoreDetails = (props: {
  data: IVehicleType | IMobileType;
  check: string;
}) => {
  const [open, setOpen] = useState(false);
  const [formValidation, setFormValidation] = useState<boolean>(true);
  const [fuelValidation, setFuelValidation] = useState<boolean>(true);

  return (
    <>
      <Box
        onClick={() => setOpen(true)}
        sx={{
          cursor: "pointer",
        }}
      >
        <Typography fontSize="10px" color={iconColor}>
          More Details
        </Typography>
      </Box>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        PaperProps={{
          sx: {
            border: `2px solid ${iconColor}`,
            borderRadius: "10px",
            maxWidth: "900px",
            width: "650px"
          },
        }}
      >
        <Box position="absolute" right="0" color={textColor}>
          <CloseIcon
            onClick={() => setOpen(false)}
            sx={{
              cursor: "pointer",
              fontSize: "25px",
              padding: "8px",
            }}
          />
        </Box>
        <DialogTitle
          display="grid"
          gridTemplateColumns="1fr 1fr"
          gap="10px"
          sx={{
            backgroundColor: primaryColor,
            color: iconColor,
          }}
        >
          <Box display="flex" alignItems="center" gap="10px">
            {props.check === "vehicle" ? (
              <AirportShuttleIcon />
            ) : (
              <PhoneIphoneIcon />
            )}
            Details
          </Box>
          <Box display="flex" flexDirection="column">
            {!formValidation ? (
              <Box
                sx={{
                  color: errorMessageColor,
                  fontSize: "12px",
                }}
              >
                <span>Please Fill All Required Fields Properly</span>
              </Box>
            ) : null}
            {!fuelValidation ? (
              <Box
                sx={{
                  color: errorMessageColor,
                  fontSize: "12px",
                }}
              >
                <span>
                  Fuel Capacity is{" "}
                  {props.check === "vehicle" &&
                    "name" in props.data &&
                    props.data.fuel_capacity}{" "}
                  Liter
                </span>
              </Box>
            ) : null}
          </Box>
        </DialogTitle>
        <Box>
          <DetailsTabs
            data={props.data}
            check={props.check}
            setFormValidation={setFormValidation}
            setFuelValidation={setFuelValidation}
          />
        </Box>
      </Dialog>
    </>
  );
};

export default MoreDetails;
