import React from "react";
import Map from "../components/Map";
import { Box } from "@mui/material";
import Navbar from "../components/Navbar";
import Login from "./Login";
import Filter from "../components/Filter";
import ToggleMap from "../components/ToggleMap";

const MapPage = () => {
  const isLogin = localStorage.getItem("sgs:access-token");
  const isSession = localStorage.getItem("sgs:session_time");
  return (
    <>
      {isLogin && isSession ? (
        <Box display="flex">
          <Filter />
          <ToggleMap />
          <Map />
          <Navbar />
        </Box>
      ) : (
        <Login />
      )}
    </>
  );
};

export default MapPage;
