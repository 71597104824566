import { API, IDENTITY_SERVER_URL, MOBILE_API } from "../config";

export interface Image {
  id: number;
  image_url: string;
}
export interface IVehicleType {
  // coordinates: number[],
  name: string;
  vehicle_id: string;
  driver_id: string;
  device_id: string;
  mileage: string;
  fuel_capacity: string;
  last_fuel_date: string;
  unique_id: string;
  phone: string;
  color: string;
  organization_id: string;
  images?: Image[];
}

export interface IMobileType {
  // coordinates: number[],
  device_id: string;
  fullname: string;
  email: string;
  phone: string;
  color: string;
  organization_id: string;
}

export interface IFuelType {
  quantity: number;
  odometer: number;
  date: string;
  device_id: string;
  created_by: string;
  last_mileage: number | null;
}

export interface IVehicleCoordinates {
  coordinates: number[];
  device_id: string;
  heading: number[];
}

export interface IMobileCoordinates {
  coordinates: number[];
  device_id: string;
  tripsStatus: string;
  // heading: number[]
}

export const formItems = [
  {
    name: "Vehicle Name",
    key: "name",
    type: "string",
  },
  {
    name: "Vehicle Number",
    key: "vehicle_id",
    type: "string",
  },
  {
    name: "Driver ID",
    key: "driver_id",
    type: "string",
  },
  {
    name: "Device ID",
    key: "unique_id",
    type: "string",
  },
  {
    name: "Mileage",
    key: "mileage",
    type: "tel",
  },
  {
    name: "Fuel Capacity",
    key: "fuel_capacity",
    type: "tel",
  },
  {
    name: "Phone",
    key: "phone",
    type: "number",
  },
];

export const mobileFormItems = [
  {
    name: "Device ID",
    key: "device_id",
    type: "string",
  },
  {
    name: "Full Name",
    key: "fullname",
    type: "string",
  },
  {
    name: "Email",
    key: "email",
    type: "string",
  },
  {
    name: "Phone",
    key: "phone",
    type: "number",
  },
];

export const fuelFormItems = [
  {
    name: "Fuel (Ltr)",
    key: "quantity",
    type: "number",
  },
  {
    name: "Odometer (Km)",
    key: "odometer",
    type: "number",
  },
];

export interface IformItems {
  name: string;
  key: string;
  type: string;
}

export interface IFilterContext {
  report: string;
  setReport: React.Dispatch<React.SetStateAction<string>>;
  table: string;
  setTable: React.Dispatch<React.SetStateAction<string>>;
  device: string;
  setDevice: React.Dispatch<React.SetStateAction<string>>;
  period: string;
  setPeriod: React.Dispatch<React.SetStateAction<string>>;
  fromDate: string;
  setFromdate: React.Dispatch<React.SetStateAction<string>>;
  toDate: string;
  setTodate: React.Dispatch<React.SetStateAction<string>>;
  columns: string[];
  setColumns: React.Dispatch<React.SetStateAction<string[]>>;
  mapperiod: string;
  setMapPeriod: React.Dispatch<React.SetStateAction<string>>;
}

export const getUsers = (accessToken: string | null) => {
  return fetch(`${IDENTITY_SERVER_URL}api/user`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
    .then((res) => {
      return res.json();
    })
    .catch((error) => {
      return error;
    });
};

export const getTenants = (accessToken: string | null) => {
  return fetch(`${IDENTITY_SERVER_URL}api/admin/tenants/all`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
    .then((res) => {
      return res.json();
    })
    .catch((error) => {
      return error;
    });
};

export const getCurrentTenants = () => {
  return fetch(`${IDENTITY_SERVER_URL}api/tenant/current`)
    .then((res) => {
      return res.json();
    })
    .catch((error) => {
      return error;
    });
};

export const getvehiclesData = (
  accessToken: string | null,
  organizationId: string,
  role: string
) => {
  const apiUrl =
    role === "sa"
      ? `${API}/device`
      : `${API}/device?organization_id=${encodeURIComponent(organizationId)}`;
  return fetch(apiUrl, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
    .then((res) => {
      return res.json();
    })
    .catch((error) => {
      return error;
    });
};

export const gettripsData = (
  fromDate: string,
  toDate: string,
  device_id: string
) => {
  const apiUrl =
    device_id === "all"
      ? `${API}/trips?from=${encodeURIComponent(
          fromDate
        )}&to=${encodeURIComponent(`${toDate}T23:59:59.000Z`)}`
      : `${API}/trips?from=${encodeURIComponent(
          fromDate
        )}&to=${encodeURIComponent(
          `${toDate}T23:59:59.000Z`
        )}&device_id=${encodeURIComponent(device_id)}`;

  return fetch(apiUrl)
    .then((res) => {
      return res.json();
    })
    .catch((error) => {
      return error;
    });
};

export const gettripsPoint = (
  start_position_id: number,
  end_position_id: number,
  device_id: number
) => {
  const apiUrl = `${API}/tripspoint?start_position_id=${encodeURIComponent(
    start_position_id
  )}&end_position_id=${encodeURIComponent(end_position_id)}&device_id=${encodeURIComponent(device_id)}`;

  return fetch(apiUrl)
    .then((res) => {
      return res.json();
    })
    .catch((error) => {
      return error;
    });
};

export const getFilterMapData = (
  fromDate: string,
  toDate: string,
  device_id: string,
  table: string,
  report: string
) => {
  const apiUrl =
    report === "vehicle"
      ? table === "trip"
        ? device_id === "all"
          ? `${API}/trips?from=${encodeURIComponent(
              fromDate
            )}&to=${encodeURIComponent(`${toDate}T23:59:59.000Z`)}`
          : `${API}/trips?from=${encodeURIComponent(
              fromDate
            )}&to=${encodeURIComponent(
              `${toDate}T23:59:59.000Z`
            )}&device_id=${encodeURIComponent(device_id)}`
        : device_id === "all"
        ? `${API}/stops?from=${encodeURIComponent(
            fromDate
          )}&to=${encodeURIComponent(`${toDate}T23:59:59.000Z`)}`
        : `${API}/stops?from=${encodeURIComponent(
            fromDate
          )}&to=${encodeURIComponent(
            `${toDate}T23:59:59.000Z`
          )}&device_id=${encodeURIComponent(device_id)}`
      : device_id === "all"
      ? `${MOBILE_API}/mobile/trips?from=${encodeURIComponent(
          fromDate
        )}&to=${encodeURIComponent(`${toDate}T23:59:59.000Z`)}`
      : `${MOBILE_API}/mobile/trips?from=${encodeURIComponent(
          fromDate
        )}&to=${encodeURIComponent(
          `${toDate}T23:59:59.000Z`
        )}&device_id=${encodeURIComponent(device_id)}`;
  return fetch(apiUrl)
    .then((res) => {
      return res.json();
    })
    .catch((error) => {
      return error;
    });
};

export const getstopsData = (
  fromDate: string,
  toDate: string,
  device_id: string
) => {
  const apiUrl =
    device_id === "all"
      ? `${API}/stops?from=${encodeURIComponent(
          fromDate
        )}&to=${encodeURIComponent(`${toDate}T23:59:59.000Z`)}`
      : `${API}/stops?from=${encodeURIComponent(
          fromDate
        )}&to=${encodeURIComponent(
          `${toDate}T23:59:59.000Z`
        )}&device_id=${encodeURIComponent(device_id)}`;

  return fetch(apiUrl)
    .then((res) => {
      return res.json();
    })
    .catch((error) => {
      return error;
    });
};

export const getsummaryData = (
  fromDate: string,
  toDate: string,
  device_id: string
) => {
  const apiUrl =
    device_id === "all"
      ? `${API}/summary?from=${encodeURIComponent(
          fromDate
        )}&to=${encodeURIComponent(`${toDate}T23:59:59.000Z`)}`
      : `${API}/summary?from=${encodeURIComponent(
          fromDate
        )}&to=${encodeURIComponent(
          `${toDate}T23:59:59.000Z`
        )}&device_id=${encodeURIComponent(device_id)}`;

  return fetch(apiUrl)
    .then((res) => {
      return res.json();
    })
    .catch((error) => {
      return error;
    });
};

export const exportData = async (
  fromDate: string,
  toDate: string,
  device_id: string,
  type: string
) => {
  const url =
    device_id === "all"
      ? `${API}/export?from=${encodeURIComponent(
          fromDate
        )}&to=${encodeURIComponent(
          `${toDate}T23:59:59.000Z`
        )}&type=${encodeURIComponent(type)}`
      : `${API}/export?from=${encodeURIComponent(
          fromDate
        )}&to=${encodeURIComponent(
          `${toDate}T23:59:59.000Z`
        )}&device_id=${encodeURIComponent(device_id)}&type=${encodeURIComponent(
          type
        )}`;

  try {
    const response = await fetch(url);
    const blob = await response.blob();

    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = `${type}_report_vehicle.xlsx`;

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
  } catch (error) {
    console.error("Error downloading the file:", error);
  }
};

export const registerVehicle = (form: any) => {
  return fetch(`${API}/device`, {
    method: "POST",
    body: form,
  })
    .then((res) => {
      return res.json().then((data) => {
        if (data.message) {
          return { message: data.message, status: data.status };
        }
      });
    })
    .catch((error) => {
      return error;
    });
};

export const updateVehicle = (device_id: string, form: any) => {
  return fetch(`${API}/device?device_id=${encodeURIComponent(device_id)}`, {
    method: "PUT",
    body: form,
  })
    .then((res) => {
      return res.json().then((data) => {
        if (data.message) {
          return { message: data.message, status: data.status };
        }
      });
    })
    .catch((error) => {
      return error;
    });
};

export const deleteRegister = (device_id: string) => {
  return fetch(`${API}/device?device_id=${encodeURIComponent(device_id)}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "Application/json",
    },
  })
    .then((res) => {
      return res.json().then((data) => {
        if (data.status) {
          return { status: data.status };
        }
      });
    })
    .catch((error) => {
      return error;
    });
};

export const registerFuel = (fuel: IFuelType) => {
  return fetch(`${API}/fuel`, {
    method: "POST",
    headers: {
      "Content-Type": "Application/json",
    },
    body: JSON.stringify(fuel),
  })
    .then((res) => {
      return res.json().then((data) => {
        if (data.message) {
          return { message: data.message, status: data.status };
        }
      });
    })
    .catch((error) => {
      return error;
    });
};

export const getFuelById = (device_id: string) => {
  return fetch(`${API}/fuel?device_id=${encodeURIComponent(device_id)}`)
    .then((res) => {
      return res.json();
    })
    .catch((error) => {
      return error;
    });
};

export const getFuelByPrimaryId = (id: number) => {
  return fetch(`${API}/fuelbyid?id=${encodeURIComponent(id)}`)
    .then((res) => {
      return res.json();
    })
    .catch((error) => {
      return error;
    });
};

export const updateFuel = (id: number, fuel: IFuelType) => {
  return fetch(`${API}/fuel?id=${encodeURIComponent(id)}`, {
    method: "PUT",
    headers: {
      "Content-Type": "Application/json",
    },
    body: JSON.stringify(fuel),
  })
    .then((res) => {
      return res.json().then((data) => {
        if (data.message) {
          return { message: data.message, status: data.status };
        }
      });
    })
    .catch((error) => {
      return error;
    });
};

export const deleteFuel = (id: number) => {
  return fetch(`${API}/fuel?id=${encodeURIComponent(id)}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "Application/json",
    },
  })
    .then((res) => {
      return res.json().then((data) => {
        if (data.status) {
          return { status: data.status };
        }
      });
    })
    .catch((error) => {
      return error;
    });
};

export const deleteImage = (id: number) => {
  return fetch(`${API}/image?id=${encodeURIComponent(id)}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "Application/json",
    },
  })
    .then((res) => {
      return res.json().then((data) => {
        if (data.status) {
          return { status: data.status };
        }
      });
    })
    .catch((error) => {
      return error;
    });
};

export const getCoordinates = (accessToken: string | null) => {
  return fetch(`${API}/data`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
    .then((res) => {
      return res.json();
    })
    .catch((error) => {
      return error;
    });
};

export const getFilterCoordinates = (fromDate: string, toDate: string) => {
  return fetch(
    `${API}/replay?from=${encodeURIComponent(fromDate)}&to=${encodeURIComponent(
      `${toDate}T23:59:59.000Z`
    )}`
  )
    .then((res) => {
      return res.json();
    })
    .catch((error) => {
      return error;
    });
};

// Mobile API
export const getMobilesData = (
  accessToken: string | null,
  organizationId: string,
  role: string
) => {
  const apiUrl =
    role === "sa"
      ? `${MOBILE_API}/mobile/device`
      : `${MOBILE_API}/mobile/device?organization_id=${encodeURIComponent(
          organizationId
        )}`;
  return fetch(apiUrl, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
    .then((res) => {
      return res.json();
    })
    .catch((error) => {
      return error;
    });
};

export const getMobileCoordinates = (accessToken: string | null) => {
  return fetch(`${MOBILE_API}/mobile/data`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
    .then((res) => {
      return res.json();
    })
    .catch((error) => {
      return error;
    });
};

export const registerMobiles = (registerMobile: IMobileType) => {
  return fetch(`${MOBILE_API}/mobile/device`, {
    method: "POST",
    headers: {
      "Content-Type": "Application/json",
    },
    body: JSON.stringify(registerMobile),
  })
    .then((res) => {
      return res.json().then((data) => {
        if (data.message) {
          return { message: data.message, status: data.status };
        }
      });
    })
    .catch((error) => {
      return error;
    });
};

export const deleteMobileRegister = (device_id: string) => {
  return fetch(
    `${MOBILE_API}/mobile/device?device_id=${encodeURIComponent(device_id)}`,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "Application/json",
      },
    }
  )
    .then((res) => {
      return res.json().then((data) => {
        if (data.status) {
          return { status: data.status };
        }
      });
    })
    .catch((error) => {
      return error;
    });
};

export const updateMobile = (
  device_id: string,
  registerMobile: IMobileType
) => {
  return fetch(
    `${MOBILE_API}/mobile/device?device_id=${encodeURIComponent(device_id)}`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "Application/json",
      },
      body: JSON.stringify(registerMobile),
    }
  )
    .then((res) => {
      return res.json().then((data) => {
        if (data.message) {
          return { message: data.message, status: data.status };
        }
      });
    })
    .catch((error) => {
      return error;
    });
};

export const getMobileFilterCoordinates = (
  fromDate: string,
  toDate: string
) => {
  return fetch(
    `${MOBILE_API}/mobile/replay?from=${encodeURIComponent(
      fromDate
    )}&to=${encodeURIComponent(`${toDate}T23:59:59.000Z`)}`
  )
    .then((res) => {
      return res.json();
    })
    .catch((error) => {
      return error;
    });
};

export const getmobiletripsData = (
  fromDate: string,
  toDate: string,
  device_id: string
) => {
  const apiUrl =
    device_id === "all"
      ? `${MOBILE_API}/mobile/trips?from=${encodeURIComponent(
          fromDate
        )}&to=${encodeURIComponent(`${toDate}T23:59:59.000Z`)}`
      : `${MOBILE_API}/mobile/trips?from=${encodeURIComponent(
          fromDate
        )}&to=${encodeURIComponent(
          `${toDate}T23:59:59.000Z`
        )}&device_id=${encodeURIComponent(device_id)}`;

  return fetch(apiUrl)
    .then((res) => {
      return res.json();
    })
    .catch((error) => {
      return error;
    });
};

export const exportMobileData = async (
  fromDate: string,
  toDate: string,
  device_id: string,
  type: string
) => {
  const url =
    device_id === "all"
      ? `${MOBILE_API}/mobile/export?from=${encodeURIComponent(
          fromDate
        )}&to=${encodeURIComponent(
          `${toDate}T23:59:59.000Z`
        )}&type=${encodeURIComponent(type)}`
      : `${MOBILE_API}/mobile/export?from=${encodeURIComponent(
          fromDate
        )}&to=${encodeURIComponent(
          `${toDate}T23:59:59.000Z`
        )}&device_id=${encodeURIComponent(device_id)}&type=${encodeURIComponent(
          type
        )}`;

  try {
    const response = await fetch(url);
    const blob = await response.blob();

    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = `${type}_report_mobile.xlsx`;

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
  } catch (error) {
    console.error("Error downloading the file:", error);
  }
};
