import React, { useContext, useState } from "react";
import {
  Box,
  DialogContent,
  Typography,
  TextField,
  useMediaQuery,
} from "@mui/material";
import {
  iconColor,
  lineColor,
  primaryColor,
  scrollbarColor,
  textColor,
} from "../constants/color-constants";
import {
  IFuelType,
  deleteFuel,
  fuelFormItems,
  getFuelByPrimaryId,
  registerFuel,
  updateFuel,
} from "../api/Api";
import {
  DatePicker,
  DateTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { format } from "date-fns";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LoaderContext, UserDetailsContext } from "../App";
import { failed, message, success } from "../hooks/useToast";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

const FuelForm = (props: {
  setFuelData: React.Dispatch<React.SetStateAction<IFuelType[]>>;
  fuelData: IFuelType[];
  device_id: string;
  setFormValidation: React.Dispatch<React.SetStateAction<boolean>>;
  setFuelValidation: React.Dispatch<React.SetStateAction<boolean>>;
  fuelCapacity: string;
}) => {
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [editId, setEditId] = useState<number>(0);
  const { userName } = useContext(UserDetailsContext);
  const { setLoader } = useContext(LoaderContext);
  const isMobile = useMediaQuery("(max-width:780px)");

  const defaultFuelState: IFuelType = {
    quantity: 0,
    odometer: 0,
    date: "",
    device_id: props.device_id,
    created_by: userName,
    last_mileage: null,
  };

  const defaultEditFuelState: IFuelType = {
    quantity: 0,
    odometer: 0,
    date: "",
    device_id: props.device_id,
    created_by: userName,
    last_mileage: null,
  };

  const [editFuelData, setEditFuelData] =
    useState<IFuelType>(defaultEditFuelState);
  const [fuel, setFuel] = useState<IFuelType>(defaultFuelState);

  const handleDateChange = (date: Date | null) => {
    if (date) {
      const formattedDate = format(new Date(date), "yyyy-MM-dd HH:mm:ss");
      setFuel((prevFuel) => ({
        ...prevFuel,
        date: formattedDate,
      }));
    }
  };

  const handleEditDateChange = (date: Dayjs | null) => {
    if (date) {
      const convertDate = date.toDate();
      const formattedDate = format(
        new Date(convertDate),
        "yyyy-MM-dd HH:mm:ss"
      );
      setEditFuelData((prevFuel) => ({
        ...prevFuel,
        date: formattedDate,
      }));
    }
  };

  // const handleFuelPopup = () => {
  //   setOpen(false);
  //   setFormValidation(true);
  // };

  const handleValidate = () => {
    // e.preventDefault();
    if (fuel.quantity <= 0 || fuel.odometer <= 0 || !fuel.date) {
      props.setFormValidation(false);
      props.setFuelValidation(true);
    } else if (fuel.quantity > parseInt(props.fuelCapacity, 10)) {
      props.setFuelValidation(false);
      props.setFormValidation(true);
    } else {
      handleSubmit();
      // setOpen(false);
      setLoader(true);
    }
  };

  const handleEditValidate = () => {
    if (
      editFuelData.quantity <= 0 ||
      editFuelData.odometer <= 0 ||
      !editFuelData.date
    ) {
      props.setFormValidation(false);
      props.setFuelValidation(true);
    } else if (editFuelData.quantity > parseInt(props.fuelCapacity, 10)) {
      props.setFuelValidation(false);
      props.setFormValidation(true);
    } else {
      handleEditSubmit();
      // setOpen(false);
      setLoader(true);
    }
  };

  const handleSubmit = () => {
    registerFuel(fuel)
      .then((response) => {
        const { message, status } = response;
        if (message && status === 200) {
          props.setFuelData([...props.fuelData, message]);
          // setInitialApiCall(false);
          success();
          props.setFormValidation(true);
          props.setFuelValidation(true);
          setFuel({
            quantity: 0,
            odometer: 0,
            date: "",
            device_id: props.device_id,
            created_by: userName,
            last_mileage: null,
          });
          setOpen(false);
        } else {
          failed();
        }
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const handleEditSubmit = () => {
    updateFuel(editId, editFuelData)
      .then((response) => {
        const { message, status } = response;
        if (message && status === 200) {
          // setInitialApiCall(false);
          const updatedInfo = props.fuelData.map(
            (fuel: any) => (fuel.id === editId ? message : fuel) //response
          );
          props.setFuelData(updatedInfo);
          success();
          props.setFormValidation(true);
          props.setFuelValidation(true);
          setOpen(false);
        } else {
          failed();
        }
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const handleEdit = (id: number) => {
    getFuelByPrimaryId(id).then((data) => {
      if (data.error) {
        failed();
      } else {
        // setFuelData(data);
        setEditFuelData({
          quantity: data[0].quantity,
          odometer: data[0].odometer,
          date: data[0].date,
          device_id: data[0].device_id,
          created_by: userName,
          last_mileage: null,
        });
      }
    });
  };

  const handleDelete = (id: number) => {
    message().then((result) => {
      if (result.isConfirmed) {
        setLoader(true);
        deleteFuel(id)
          .then((response) => {
            const { status } = response;
            if (status === 204) {
              const updatedInfo = props.fuelData.filter(
                (fuel: any) => fuel.id !== id
              );
              props.setFuelData(updatedInfo);
              success();
            } else {
              failed();
            }
          })
          .finally(() => {
            setLoader(false);
          });
      }
    });
  };

  const renderFuelForm = () => (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={["DateTimePicker"]} sx={{
          overflow: "unset",
        }}>
          <DateTimePicker
            label="Date and Time *"
            format="YYYY-MM-DD HH:mm"
            // autoFocus
            sx={{
              width: isMobile ? "100%" : "10px",
              minWidth: isMobile ? "unset" : "205px !important",
              "& .MuiFormLabel-root.MuiInputLabel-root, .MuiSvgIcon-root": {
                color: iconColor,
                lineHeight: "unset",
              },
              // ".css-1jy569b-MuiFormLabel-root-MuiInputLabel-root":{
              //   color: iconColor,
              // },
              "& .MuiInputBase-input.MuiOutlinedInput-input": {
                color: textColor,
                padding: "11.5px 14px",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: textColor,
              },
              "& .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                {
                  borderColor: lineColor,
                },
            }}
            onChange={(date: Date | null) => handleDateChange(date)}
          />
        </DemoContainer>
      </LocalizationProvider>
      {fuelFormItems.map((item, index) => (
        <TextField
          label={item.name}
          type={item.type}
          fullWidth
          variant="standard"
          key={index}
          required
          onChange={(e) => {
            setFuel((prevFuel) => ({
              ...prevFuel,
              [item.key]: e.target.value,
            }));
          }}
          sx={{
            margin: "4px",
            width: isMobile ? "100%" : (item.key === "quantity" ? "100px" : "unset"),
            "& label.Mui-focused": {
              color: iconColor,
            },
            "& .MuiInput-underline:after": {
              borderBottomColor: iconColor,
            },
            "& .MuiInputBase-root.MuiInput-root": {
              color: textColor,
            },
            "& .MuiFormLabel-root.MuiInputLabel-root": {
              color: lineColor,
            },
            "& .MuiInputBase-root.MuiInput-root:before": {
              borderBottom: `1px solid gray`,
            },
          }}
        />
      ))}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          color: iconColor,
          gap: "10px",
        }}
      >
        <CheckIcon
          onClick={() => {
            handleValidate();
          }}
          sx={{
            cursor: "pointer",
            // width: '500px'
          }}
        />
        <CloseIcon
          onClick={() => {
            setOpen(false);
            setOpenEdit(false);
            props.setFormValidation(true);
            props.setFuelValidation(true);
          }}
          sx={{
            cursor: "pointer",
            // width: '500px'
          }}
        />
      </Box>
    </>
  );

  const renderEditFuelForm = () => (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={["DateTimePicker"]} sx={{
          overflow: "unset",
        }}>
          <DateTimePicker
            label="Date and Time *"
            value={dayjs(new Date(editFuelData.date))}
            format="YYYY-MM-DD HH:mm"
            // autoFocus
            sx={{
              width: isMobile ? "100%" : "10px",
              minWidth: isMobile ? "unset" : "205px !important",
              "& .MuiFormLabel-root.MuiInputLabel-root, .MuiSvgIcon-root": {
                color: iconColor,
                lineHeight: "unset",
              },
              // ".css-1jy569b-MuiFormLabel-root-MuiInputLabel-root":{
              //   color: iconColor,
              // },
              "& .MuiInputBase-input.MuiOutlinedInput-input": {
                color: textColor,
                padding: "11.5px 14px",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: textColor,
              },
              "& .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                {
                  borderColor: lineColor,
                },
            }}
            onChange={(date: Dayjs | null) => handleEditDateChange(date)}
          />
        </DemoContainer>
      </LocalizationProvider>
      {fuelFormItems.map((item, index) => (
        <TextField
          label={item.name}
          type={item.type}
          fullWidth
          variant="standard"
          key={index}
          value={editFuelData[item.key as keyof IFuelType]}
          required
          onChange={(e) => {
            setEditFuelData((prevFuel) => ({
              ...prevFuel,
              [item.key]: e.target.value,
            }));
          }}
          sx={{
            margin: "4px",
            width: isMobile ? "100%" : (item.key === "quantity" ? "100px" : "unset"),
            "& label.Mui-focused": {
              color: iconColor,
            },
            "& .MuiInput-underline:after": {
              borderBottomColor: iconColor,
            },
            "& .MuiInputBase-root.MuiInput-root": {
              color: textColor,
            },
            "& .MuiFormLabel-root.MuiInputLabel-root": {
              color: lineColor,
            },
            "& .MuiInputBase-root.MuiInput-root:before": {
              borderBottom: `1px solid gray`,
            },
          }}
        />
      ))}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          color: iconColor,
          gap: "10px",
        }}
      >
        <CheckIcon
          onClick={() => {
            handleEditValidate();
          }}
          sx={{
            cursor: "pointer",
            // width: '500px'
          }}
        />
        <CloseIcon
          onClick={() => {
            setOpen(false);
            setOpenEdit(false);
            props.setFormValidation(true);
            props.setFuelValidation(true);
          }}
          sx={{
            cursor: "pointer",
            // width: '500px'
          }}
        />
      </Box>
    </>
  );

  return (
    <>
      {/* <Box
        sx={{
          padding: "20px 20px 0px 20px",
          backgroundColor: primaryColor,
          // width: '500px'
        }}
      >
        <Typography>Add Fuel</Typography>
      </Box> */}
      {!open && (
        <Box
          onClick={() => setOpen(true)}
          sx={{
            padding: "20px",
            backgroundColor: primaryColor,
            cursor: "pointer",
            // width: '500px'
          }}
        >
          <Box color={iconColor} display="flex">
            <AddIcon />
            <Typography>Add Fuel</Typography>
          </Box>
        </Box>
      )}
      {/*
       <Dialog
        open={open}
        onClose={handleFuelPopup}
        PaperProps={{
          sx: {
            border: `2px solid ${iconColor}`,
            borderRadius: "10px",
          },
        }}
        sx={{
          margin: "-100px 32px 32px 32px",
        }}
      >
        <DialogTitle
          display="flex"
          alignItems="center"
          gap="5px"
          sx={{
            backgroundColor: primaryColor,
            color: iconColor,
          }}
        >
          <span>Fuel</span>
          {!formValidation ? (
            <Box
              sx={{
                color: errorMessageColor,
                fontSize: "12px",
              }}
            >
              <span>Please Fill All Required Fields Properly</span>
            </Box>
          ) : null}
        </DialogTitle> */}
      {open && (
        <>
          {/* {!formValidation ? (
            <Box
              sx={{
                color: errorMessageColor,
                fontSize: "12px",
                backgroundColor: primaryColor,
              }}
            >
              <span>Please Fill All Required Fields Properly</span>
            </Box>
          ) : null} */}
          <DialogContent
            sx={{
              display: "flex",
              alignItems: "center",
              flexWrap: isMobile ? "wrap" : "unset",
              gap: isMobile ? "5px" : "35px",
              padding: "5px 24px 20px 24px",
              backgroundColor: primaryColor,
              color: textColor,
              "& .MuiPaper-root-MuiDialog-paper":{
                maxWidth: "800px",
              },
              "&::-webkit-scrollbar": {
                width: "4px",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: scrollbarColor,
                borderRadius: "4px",
              },
              "&::-webkit-scrollbar-track": {
                backgroundColor: lineColor,
              },
              scrollbarColor: `${scrollbarColor} ${lineColor}`,
              scrollbarWidth: "thin",
            }}
          >
            {!openEdit ? renderFuelForm() : renderEditFuelForm()}
          </DialogContent>
          {/* <DialogActions
            sx={{
              backgroundColor: primaryColor,
              color: textColor,
              // justifyContent: 'space-between',
              "&.MuiDialogActions-root.MuiDialogActions-spacing.MuiDialogActions-root":
                {
                  padding: "8px 15px",
                },
            }}
          >
            <Button
              onClick={(e) => {
                handleValidate(e);
              }}
              sx={{
                color: iconColor,
              }}
            >
              Done
            </Button>
          </DialogActions> */}
        </>
      )}
      {/* </Dialog> */}
      <TableContainer
        style={{ maxHeight: 200 }}
        sx={{
          "&::-webkit-scrollbar": {
            width: "4px",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: scrollbarColor,
            borderRadius: "4px",
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: lineColor,
          },
          scrollbarColor: `${scrollbarColor} ${lineColor}`,
          scrollbarWidth: "thin",
        }}
      >
        <Table stickyHeader sx={{ backgroundColor: primaryColor }}>
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  backgroundColor: primaryColor,
                  borderBottom: `1px solid ${lineColor}`,
                  color: textColor,
                  padding: "16px 40px",
                }}
              >
                Date
              </TableCell>
              <TableCell
                sx={{
                  backgroundColor: primaryColor,
                  borderBottom: `1px solid ${lineColor}`,
                  color: textColor,
                  padding: "16px 40px",
                }}
              >
                Fuel (Ltr)
              </TableCell>
              <TableCell
                sx={{
                  backgroundColor: primaryColor,
                  borderBottom: `1px solid ${lineColor}`,
                  color: textColor,
                  padding: "16px 40px",
                }}
              >
                Odometer (Km)
              </TableCell>
              <TableCell
                sx={{
                  backgroundColor: primaryColor,
                  borderBottom: `1px solid ${lineColor}`,
                  color: textColor,
                  padding: "16px 40px",
                }}
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.fuelData &&
              props.fuelData
                .slice()
                .sort(
                  (a, b) =>
                    new Date(b.date).getTime() - new Date(a.date).getTime()
                )
                .map((items: any) => {
                  return (
                    <TableRow key={items.id}>
                      <TableCell
                        sx={{
                          backgroundColor: primaryColor,
                          borderBottom: `1px solid ${lineColor}`,
                          color: textColor,
                          padding: "16px 40px",
                        }}
                      >
                        {/* {formatDate(items.date)} */}
                        {items.date}
                      </TableCell>
                      <TableCell
                        sx={{
                          backgroundColor: primaryColor,
                          borderBottom: `1px solid ${lineColor}`,
                          color: textColor,
                          padding: "16px 40px",
                        }}
                      >
                        {items.quantity}
                      </TableCell>
                      <TableCell
                        sx={{
                          backgroundColor: primaryColor,
                          borderBottom: `1px solid ${lineColor}`,
                          color: textColor,
                          padding: "16px 40px",
                        }}
                      >
                        {items.odometer}
                      </TableCell>
                      <TableCell
                        sx={{
                          backgroundColor: primaryColor,
                          borderBottom: `1px solid ${lineColor}`,
                          padding: "16px 40px",
                        }}
                      >
                        <EditIcon
                          sx={{
                            fontSize: "20px",
                            color: iconColor,
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setOpen(true);
                            setOpenEdit(true);
                            setEditId(items.id);
                            handleEdit(items.id);
                          }}
                        />

                        <DeleteIcon
                          sx={{
                            fontSize: "20px",
                            color: iconColor,
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            handleDelete(items.id);
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
            {props.fuelData.length === 0 && (
              <TableCell
                colSpan={3}
                sx={{
                  textAlign: "center",
                  backgroundColor: primaryColor,
                  borderBottom: `1px solid ${lineColor}`,
                  color: textColor,
                  padding: "16px 60px",
                }}
              >
                {" "}
                No Data
              </TableCell>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {/* <Box
        display="grid"
        gridTemplateColumns="3fr 3fr 3fr"
        gap="2px"
        sx={{
          padding: "0px 20px 20px 20px",
          backgroundColor: primaryColor,
          color: textColor,
        }}
      >
        <Typography
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          Date
        </Typography>
        <Typography
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          Fuel
        </Typography>
        <Typography
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          Action
        </Typography>
      </Box>
      <Box
        display="grid"
        gridTemplateColumns="4fr 2fr 2fr"
        gap="2px"
        sx={{
          padding: "0px 20px 20px 20px",
          backgroundColor: primaryColor,
          color: textColor,
          height: "200px",
          overflowY: "auto",
          // width: '500px'
          "&::-webkit-scrollbar": {
            width: "4px",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: scrollbarColor,
            borderRadius: "4px",
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: lineColor,
          },
          scrollbarColor: `${scrollbarColor} ${lineColor}`,
          scrollbarWidth: "thin",
        }}
      >
        {props.data &&
          props.data.map((items: any) => (
            <>
              <Typography>Date: {formatDate(items.date)}</Typography>
              <Typography>Fuel: {items.quantity} liters</Typography> */}
      {/* <Typography sx={{ whiteSpace: "nowrap" }}>
                Name: {items.created_by}
              </Typography> */}
      {/* <Box
                sx={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <EditIcon
                  sx={{
                    fontSize: "20px",
                    color: iconColor,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setOpen(true);
                    setOpenEdit(true);
                    setEditId(items.id);
                    handleEdit(items.id);
                  }}
                />
              </Box>
            </>
          ))}
      </Box> */}
    </>
  );
};

export default FuelForm;
