import React, { ChangeEvent, useContext, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import AirportShuttleIcon from "@mui/icons-material/AirportShuttle";
import {
  iconColor,
  lineColor,
  primaryColor,
  scrollbarColor,
  textColor,
  errorMessageColor,
  selectedMenuColor,
  selectedMenuColorHover,
} from "../constants/color-constants";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  IMobileType,
  IVehicleType,
  IformItems,
  formItems,
  mobileFormItems,
  registerVehicle,
  registerMobiles,
} from "../api/Api";
import { format } from "date-fns";
import {
  LoaderContext,
  MobilesDataContext,
  RadioButtonValueContext,
  UserDetailsContext,
  VehiclesDataContext,
  initialApiCallContext,
} from "../App";
import { success, failed } from "../hooks/useToast";
import { MuiColorInput } from "mui-color-input";
import { ColorInput } from "@ctrl/tinycolor";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import { SelectComponent } from "./Report";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

const RegisterVehicle = () => {
  const [open, setOpen] = useState(false);
  const isMobile = useMediaQuery("(max-width:475px)");
  const { organizationId, tenants, setOrganizationId, role } =
    useContext(UserDetailsContext);

  const defaultVehicleState: IVehicleType = {
    name: "",
    vehicle_id: "",
    driver_id: "",
    unique_id: "",
    device_id: "",
    mileage: "",
    fuel_capacity: "",
    last_fuel_date: "",
    phone: "",
    color: "rgb(255, 255, 255)",
    organization_id: organizationId,
  };

  const defaultMobileState: IMobileType = {
    device_id: "",
    fullname: "",
    email: "",
    phone: "",
    color: "rgb(255, 255, 255)",
    organization_id: organizationId,
  };

  const { vehiclesInfo, updateVehiclesInfo } = useContext(VehiclesDataContext);
  const { mobilesInfo, setmobilesInfo } = useContext(MobilesDataContext);
  const { setLoader } = useContext(LoaderContext);
  const { radioValue, setradioValue } = useContext(RadioButtonValueContext);
  const { setInitialApiCall } = useContext(initialApiCallContext);

  const [register, setRegister] = useState<IVehicleType>(defaultVehicleState);
  const [registerMobile, setRegisterMobile] =
    useState<IMobileType>(defaultMobileState);
  const [formValidation, setFormValidation] = useState<boolean>(true);
  const [color, setColor] = useState<ColorInput>("rgb(255, 255, 255)");
  const [colorMobile, setColorMobile] =
    useState<ColorInput>("rgb(255, 255, 255)");
  const [toggleForm, setToggleForm] = useState(radioValue);
  const [images, setImages] = useState<string[]>([]);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);

  const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;

    if (files) {
      const newFilesArray: File[] = Array.from(files);
      setSelectedFiles([...selectedFiles, ...newFilesArray]);

      const newImages: string[] = [];
      const readers: FileReader[] = [];

      for (let i = 0; i < newFilesArray.length; i++) {
        const reader = new FileReader();
        readers.push(reader);

        reader.onload = (event) => {
          if (event.target) {
            newImages.push(event.target.result as string);
            setImages([...images, ...newImages]);
          }
        };

        reader.readAsDataURL(newFilesArray[i]);
      }
    }
  };

  const handleDateChange = (date: Date | null) => {
    if (date) {
      const formattedDate = format(new Date(date), "yyyy-MM-dd");
      setRegister((prevRegister: IVehicleType) => ({
        ...prevRegister,
        last_fuel_date: formattedDate,
      }));
    }
  };

  const handleColorChange = (newColor: ColorInput) => {
    setColor(newColor);
    setRegister((prevRegister: IVehicleType) => ({
      ...prevRegister,
      color: newColor.toString(),
    }));
  };

  const handleMobileColorChange = (newColor: ColorInput) => {
    setColorMobile(newColor);
    setRegisterMobile((prevRegister) => ({
      ...prevRegister,
      color: newColor.toString(),
    }));
  };

  const changeOrganizationId = (organizationId: string) => {
    setOrganizationId(organizationId);
    setRegister((prevRegister: IVehicleType) => ({
      ...prevRegister,
      organization_id: organizationId,
    }));
    setRegisterMobile((prevRegister) => ({
      ...prevRegister,
      organization_id: organizationId,
    }));
  };

  const handleRadioChange = (e: any) => {
    setToggleForm(e.target.value);
  };

  const handleSidebarRadioChange = (e: any) => {
    setradioValue(e.target.value);
  };

  const handleValidate = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const emailvalidate = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (
      toggleForm === "device" &&
      (!register.name ||
        !register.vehicle_id ||
        !register.driver_id ||
        !register.unique_id ||
        !register.mileage ||
        !register.fuel_capacity ||
        !register.last_fuel_date ||
        !register.phone ||
        !/^\d{10}$/.test(String(register.phone)))
    ) {
      setFormValidation(false);
    } else if (
      toggleForm === "mobile" &&
      (!registerMobile.device_id ||
        !registerMobile.fullname ||
        !registerMobile.email ||
        !emailvalidate.test(registerMobile.email) ||
        !registerMobile.phone ||
        !/^\d{10}$/.test(String(registerMobile.phone)))
    ) {
      setFormValidation(false);
    } else {
      if (toggleForm === "device") {
        handleSubmit();
      } else {
        handleMobileSubmit();
      }
      setOpen(false);
      setLoader(true);
    }
  };

  const handleSubmit = () => {
    const form = new FormData();
    form.append("name", register.name);
    form.append("vehicle_id", register.vehicle_id);
    form.append("driver_id", register.driver_id);
    form.append("unique_id", register.unique_id);
    form.append("device_id", register.device_id);
    form.append("mileage", register.mileage);
    form.append("fuel_capacity", register.fuel_capacity);
    form.append("last_fuel_date", register.last_fuel_date);
    form.append("phone", register.phone);
    form.append("color", register.color);
    form.append("organization_id", register.organization_id);
    if (selectedFiles.length > 0) {
      for (let i = 0; i < selectedFiles.length; i++) {
        form.append(`image${i}`, selectedFiles[i]);
      }
    }
    registerVehicle(form)
      .then((response) => {
        const { message, status } = response;
        if (message && status === 200) {
          updateVehiclesInfo([...vehiclesInfo, message]);
          setInitialApiCall(false);
          success();
          setFormValidation(true);
          setImages([]);
          setSelectedFiles([]);
        } else {
          failed();
        }
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const handleMobileSubmit = () => {
    registerMobiles(registerMobile)
      .then((response) => {
        const { message, status } = response;
        if (message && status === 200) {
          setmobilesInfo([...mobilesInfo, message]);
          setInitialApiCall(false);
          success();
          setFormValidation(true);
        } else {
          failed();
        }
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const handleRegisterPopup = () => {
    setImages([]);
    setSelectedFiles([]);
    setOpen(false);
    setFormValidation(true);
    setToggleForm(radioValue);
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        // gap="5px"
        justifyContent={
          useMediaQuery("(max-width:600px)") ? "space-between" : "unset"
        }
        paddingLeft="13px"
        // onClick={() => setOpen(true)}
        // sx={{
        //   cursor: "pointer",
        // }}
      >
        <RadioGroup
          name="use-radio-group"
          value={radioValue}
          onChange={handleSidebarRadioChange}
        >
          <Box display="flex" color={textColor}>
            <FormControlLabel
              value="device"
              label="Vehicle"
              control={<Radio style={{ color: `${iconColor}` }} />}
              // sx={{
              //   marginRight: "4px",
              // }}
            />
            {role === "sa" ? (
              <FormControlLabel
                value="mobile"
                label="Mobile"
                control={<Radio style={{ color: `${iconColor}` }} />}
                // sx={{
                //   marginRight: "4px",
                // }}
              />
            ) : null}
          </Box>
        </RadioGroup>
        {role === "sa" && (
          <Box
            display="flex"
            alignItems="center"
            gap="5px"
            onClick={() => {
              setOpen(true);
              setToggleForm(radioValue);
            }}
            sx={{
              cursor: "pointer",
            }}
          >
            <AddIcon
              sx={{
                color: iconColor,
                height: "25px",
                width: "25px",
              }}
            />

            <Typography color={iconColor} variant="h6" fontSize="18px">
              Add
            </Typography>
          </Box>
        )}
      </Box>

      <Dialog
        open={open}
        onClose={handleRegisterPopup}
        PaperProps={{
          sx: {
            border: `2px solid ${iconColor}`,
            borderRadius: "10px",
          },
        }}
      >
        <DialogTitle
          sx={{
            backgroundColor: primaryColor,
            color: iconColor,
            display: "flex",
            justifyContent: isMobile ? "unset" : "space-between",
            flexWrap: "wrap",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            {toggleForm === "device" ? (
              <>
                <AirportShuttleIcon />
                <span>Register Vehicle</span>
              </>
            ) : (
              <>
                <PhoneIphoneIcon />
                <span>Register Mobile</span>
              </>
            )}
          </Box>
          {!formValidation ? (
            <Box
              sx={{
                color: errorMessageColor,
                fontSize: "12px",
              }}
            >
              <span>Please Fill All Required Fields Properly</span>
            </Box>
          ) : null}
          <Box
            width={isMobile ? "45%" : "18%"}
            marginTop={isMobile ? "10px" : "unset"}
          >
            <FormControl size="small" fullWidth>
              <InputLabel sx={{ color: iconColor }}>Tenants</InputLabel>
              <SelectComponent
                className="tenants"
                value={organizationId}
                label="Tenants"
                // onChange={handleChange}
                onChange={(e) => changeOrganizationId(e.target.value as string)}
                MenuProps={{
                  sx: {
                    "& .MuiMenuItem-root.Mui-selected": {
                      backgroundColor: selectedMenuColor,
                    },
                    "& .MuiButtonBase-root.MuiMenuItem-root:hover": {
                      backgroundColor: selectedMenuColorHover,
                    },
                    "& .MuiPaper-root.MuiPopover-paper.MuiMenu-paper::-webkit-scrollbar":
                      {
                        width: "4px",
                      },
                    "& .MuiPaper-root.MuiPopover-paper.MuiMenu-paper::-webkit-scrollbar-thumb":
                      {
                        backgroundColor: scrollbarColor,
                        borderRadius: "4px",
                      },
                    "& .MuiPaper-root.MuiPopover-paper.MuiMenu-paper::-webkit-scrollbar-track":
                      {
                        backgroundColor: lineColor,
                      },
                    "& .MuiPaper-root.MuiPopover-paper.MuiMenu-paper": {
                      scrollbarColor: `${scrollbarColor} ${lineColor}`,
                      scrollbarWidth: "thin",
                    },
                  },
                }}
              >
                {tenants.map((item: any) => {
                  return (
                    <MenuItem
                      value={item.organization.id}
                      key={item.organization.id}
                    >
                      {item.organization.ticket_id}
                    </MenuItem>
                  );
                })}
              </SelectComponent>
            </FormControl>
          </Box>
        </DialogTitle>
        {toggleForm === "device" ? (
          <DialogContent
            sx={{
              backgroundColor: primaryColor,
              color: textColor,
              "&::-webkit-scrollbar": {
                width: "4px",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: scrollbarColor,
                borderRadius: "4px",
              },
              "&::-webkit-scrollbar-track": {
                backgroundColor: lineColor,
              },
              scrollbarColor: `${scrollbarColor} ${lineColor}`,
              scrollbarWidth: "thin",
            }}
          >
            {formItems.map((item: IformItems) => {
              return (
                <TextField
                  // autoFocus
                  // margin="dense"
                  label={item.name}
                  type={item.type}
                  fullWidth
                  variant="standard"
                  key={item.key}
                  required
                  onChange={(e) => {
                    setRegister((prevRegister: IVehicleType) => ({
                      ...prevRegister,
                      [item.key]: e.target.value,
                    }));
                  }}
                  sx={{
                    margin: "4px",
                    "& label.Mui-focused": {
                      color: iconColor,
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: iconColor,
                    },
                    "& .MuiInputBase-root.MuiInput-root": {
                      color: textColor,
                    },
                    "& .MuiFormLabel-root.MuiInputLabel-root": {
                      color: lineColor,
                    },
                    "& .MuiInputBase-root.MuiInput-root:before": {
                      borderBottom: `1px solid gray`,
                    },
                  }}
                />
              );
            })}
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DatePicker"]}>
                <DatePicker
                  label="Last Fuel Date *"
                  format="YYYY-MM-DD"
                  // autoFocus
                  sx={{
                    margin: "4px",
                    width: "100%",
                    "& .MuiFormLabel-root.MuiInputLabel-root, .MuiSvgIcon-root":
                      {
                        color: iconColor,
                      },
                    // ".css-1jy569b-MuiFormLabel-root-MuiInputLabel-root":{
                    //   color: iconColor,
                    // },
                    "& .MuiInputBase-input.MuiOutlinedInput-input": {
                      color: textColor,
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: textColor,
                    },
                    "& .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: lineColor,
                      },
                  }}
                  onChange={(date: Date | null) => handleDateChange(date)}
                />
              </DemoContainer>
            </LocalizationProvider>
            <MuiColorInput
              value={color}
              label={"Choose Color"}
              onChange={handleColorChange}
              sx={{
                width: "100%",
                marginTop: "10px",
                "& .MuiFormLabel-root.MuiInputLabel-root": {
                  color: iconColor,
                },
                "& .MuiInputBase-root.MuiOutlinedInput-root": {
                  color: textColor,
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: textColor,
                },
                "& .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    borderColor: lineColor,
                  },
              }}
            />
            <Box marginTop="10px">
              <label htmlFor="upload-button">
                <Button
                  component="label"
                  role={undefined}
                  variant="contained"
                  startIcon={<CloudUploadIcon />}
                >
                  Upload Image
                  <input
                    id="upload-button"
                    type="file"
                    onChange={handleImageChange}
                    accept="image/*"
                    style={{ display: "none" }}
                  />
                </Button>
              </label>
              <Box
                display="flex"
                flexDirection="row"
                flexWrap="wrap"
                marginTop="10px"
              >
                {images.map((image: string, index: number) => (
                  <Box key={index} marginRight="10px">
                    <img
                      src={image}
                      alt={`Preview ${index + 1}`}
                      style={{
                        maxWidth: "100px",
                        maxHeight: "100px",
                        marginRight: "5px",
                        marginBottom: "5px",
                      }}
                    />
                  </Box>
                ))}
              </Box>
            </Box>
          </DialogContent>
        ) : (
          <DialogContent
            sx={{
              backgroundColor: primaryColor,
              color: textColor,
              "&.MuiDialogContent-root": {
                overflowY: "unset",
              },
            }}
          >
            {mobileFormItems.map((item: IformItems) => {
              return (
                <TextField
                  // autoFocus
                  // margin="dense"
                  label={item.name}
                  type={item.type}
                  fullWidth
                  variant="standard"
                  key={item.key}
                  required
                  onChange={(e) => {
                    setRegisterMobile((prevMobileRegister) => ({
                      ...prevMobileRegister,
                      [item.key]: e.target.value,
                    }));
                  }}
                  sx={{
                    margin: "4px",
                    "& label.Mui-focused": {
                      color: iconColor,
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: iconColor,
                    },
                    "& .MuiInputBase-root.MuiInput-root": {
                      color: textColor,
                    },
                    "& .MuiFormLabel-root.MuiInputLabel-root": {
                      color: lineColor,
                    },
                    "& .MuiInputBase-root.MuiInput-root:before": {
                      borderBottom: `1px solid gray`,
                    },
                  }}
                />
              );
            })}
            <MuiColorInput
              value={colorMobile}
              label={"Choose Color"}
              onChange={handleMobileColorChange}
              sx={{
                width: "100%",
                marginTop: "10px",
                "& .MuiFormLabel-root.MuiInputLabel-root": {
                  color: iconColor,
                },
                "& .MuiInputBase-root.MuiOutlinedInput-root": {
                  color: textColor,
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: textColor,
                },
                "& .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    borderColor: lineColor,
                  },
              }}
            />
          </DialogContent>
        )}
        <DialogActions
          sx={{
            backgroundColor: primaryColor,
            color: textColor,
            display: "flex",
            flexWrap: isMobile ? "wrap" : "unset",
            justifyContent: isMobile ? "center" : "space-between",
            // justifyContent: 'space-between',
            "&.MuiDialogActions-root.MuiDialogActions-spacing.MuiDialogActions-root":
              {
                padding: "8px 15px",
              },
          }}
        >
          {/* {
            !formValidation?
            <Box
              sx={{
                color: errorMessageColor,
                fontSize:'12px',
                paddingLeft:'4%'
              }}
            >
              <p>Please Fill All Required Fields</p>
            </Box>
            :
            <Box>

            </Box>
          }   */}
          {/* <Box> */}
          <Box>
            <RadioGroup
              name="use-radio-group"
              defaultValue={radioValue}
              onChange={handleRadioChange}
            >
              <Box display="flex" color={textColor}>
                <FormControlLabel
                  value="device"
                  label="Vehicle"
                  control={<Radio style={{ color: `${iconColor}` }} />}
                />
                <FormControlLabel
                  value="mobile"
                  label="Mobile"
                  control={<Radio style={{ color: `${iconColor}` }} />}
                />
              </Box>
            </RadioGroup>
          </Box>
          <Box
            sx={{
              "&.MuiBox-root": {
                marginLeft: "0px",
              },
            }}
          >
            <Button
              onClick={handleRegisterPopup}
              sx={{
                color: iconColor,
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={(e) => {
                handleValidate(e);
              }}
              sx={{
                color: iconColor,
              }}
            >
              Register
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default RegisterVehicle;
