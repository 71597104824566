import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  useMediaQuery,
} from "@mui/material";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  iconColor,
  lineColor,
  primaryColor,
  scrollbarColor,
  selectedMenuColor,
  selectedMenuColorHover,
  textColor,
} from "../constants/color-constants";
import { Icon, Tooltip } from "@mui/material";
import { format, sub } from "date-fns";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import { SelectComponent } from "./Report";
import {
  IMobileType,
  IVehicleType,
  exportData,
  exportMobileData,
  getFilterCoordinates,
  getFilterMapData,
  getMobileFilterCoordinates,
} from "../api/Api";
import {
  CalledReplayContext,
  DisplayCoordinatesContext,
  FilterContext,
  LoaderContext,
  MobileCoordinatesContext,
  MobilesDataContext,
  PathFilterContext,
  ReportExportContext,
  ShowFilterContext,
  UserDetailsContext,
  // SidebarMarginTop,
  VehicleCoordinatesContext,
  VehiclesDataContext,
  ViewportDataContext,
  initialApiCallContext,
} from "../App";
import { GridColDef } from "@mui/x-data-grid";
import { failed } from "../hooks/useToast";
import SearchIcon from "@mui/icons-material/Search";
import DownloadIcon from "@mui/icons-material/Download";

const Filter = () => {
  const isMobile = useMediaQuery("(max-width:780px)");
  const { setLoader } = useContext(LoaderContext);

  // const [mapperiod, setMapPeriod] = useState("today");
  // const [mapfromDate, setMapfromdate] = useState<string>("");
  // const [maptoDate, setMaptodate] = useState<string>("");

  const { vehiclesInfo } = useContext(VehiclesDataContext);
  const { mobilesInfo } = useContext(MobilesDataContext);
  const { calledreplay, setCalledreplay } = useContext(CalledReplayContext);
  const { showFilter } = useContext(ShowFilterContext);
  const { setVehicleCoordinates } = useContext(VehicleCoordinatesContext);
  const { setMobileCoordinates } = useContext(MobileCoordinatesContext);
  const { setInitialApiCall } = useContext(initialApiCallContext);
  const { role } = useContext(UserDetailsContext);

  // const { setSidebarMarginTop } = useContext(SidebarMarginTop);

  // const { showMapCustom, setShowMapCustom } = useContext(ShowMapCustomContext)
  const {
    report,
    setReport,
    table,
    setTable,
    device,
    setDevice,
    period,
    setPeriod,
    fromDate,
    setFromdate,
    toDate,
    setTodate,
    columns,
    setColumns,
    mapperiod,
    setMapPeriod,
  } = useContext(FilterContext);
  const { displayCoordinates } = useContext(DisplayCoordinatesContext);
  const { reportExport, showExport } = useContext(ReportExportContext);
  const { setViewportData } = useContext(ViewportDataContext);
  const { pathFilter } = useContext(PathFilterContext);

  //   const [columns, setColumns] = useState<string[]>([]);

  const dateFormat = "yyy-MM-dd";
  const currentDate = format(new Date(), dateFormat);
  const weekDate = format(sub(new Date(), { weeks: 1 }), dateFormat);
  const prevweekDate = format(sub(new Date(), { weeks: 2 }), dateFormat);
  const monthDate = format(sub(new Date(), { months: 1 }), dateFormat);
  const prevmonthDate = format(sub(new Date(), { months: 2 }), dateFormat);
  const yearDate = format(sub(new Date(), { years: 1 }), dateFormat);
  const yesterdayDate = format(sub(new Date(), { days: 1 }), dateFormat);

  // const [mapperiod, setMapPeriod] = useState("default");
  const [mapfromDate, setMapfromdate] = useState<string>(
    fromDate === "" ? currentDate : fromDate
  );
  const [maptoDate, setMaptodate] = useState<string>(currentDate);
  const reportDate = fromDate === "" ? currentDate : fromDate;
  // const navbarElement = document.querySelector(".navbar");
  // if (navbarElement) {
  //   const navbarHeight = navbarElement.getBoundingClientRect().height - 60;
  //   setSidebarMarginTop(navbarHeight)
  // }

  const handleCustomDateChange = useCallback(
    (date: Dayjs | null, label: string) => {
      if (date) {
        try {
          const convertDate = date.toDate();
          const formattedDate = format(new Date(convertDate), dateFormat);
          label === "from"
            ? setFromdate(formattedDate)
            : setTodate(formattedDate);
        } catch {}
      }
    },
    [setFromdate, setTodate]
  );

  const handleMapCustomDateChange = (date: Dayjs | null, label: string) => {
    if (date) {
      try {
        const convertDate = date.toDate();
        const formattedDate = format(new Date(convertDate), dateFormat);
        label === "from"
          ? setMapfromdate(formattedDate)
          : setMaptodate(formattedDate);
      } catch {}
    }
  };

  useEffect(() => {
    if (period === "today") {
      setFromdate(currentDate);
      setTodate(currentDate);
    } else if (period === "yesterday") {
      setFromdate(yesterdayDate);
      setTodate(yesterdayDate);
    } else if (period === "thisWeek") {
      setFromdate(weekDate);
      setTodate(currentDate);
    } else if (period === "prevWeek") {
      setFromdate(prevweekDate);
      setTodate(weekDate);
    } else if (period === "thisMonth") {
      setFromdate(monthDate);
      setTodate(currentDate);
    } else if (period === "prevMonth") {
      setFromdate(prevmonthDate);
      setTodate(monthDate);
    } else if (period === "thisYear") {
      setFromdate(yearDate);
      setTodate(currentDate);
    }
  }, [
    period,
    weekDate,
    currentDate,
    monthDate,
    yearDate,
    setFromdate,
    setTodate,
    yesterdayDate,
    prevweekDate,
    prevmonthDate,
  ]);

  useEffect(() => {
    if (mapperiod === "default") {
      // setShowMapCustom(false)
      setInitialApiCall(false);
      setCalledreplay(false);
    }
    // else if (mapperiod === "today") {
    //   setMapfromdate(currentDate);
    //   setMaptodate(currentDate);
    //   setCalledreplay(true);
    //   // setShowMapCustom(false)
    // }
    else if (mapperiod === "yesterday") {
      setMapfromdate(yesterdayDate);
      setMaptodate(yesterdayDate);
      setCalledreplay(true);
    } else if (mapperiod === "thisWeek") {
      setMapfromdate(weekDate);
      setMaptodate(currentDate);
      setCalledreplay(true);
      // setShowMapCustom(false)
    } else if (mapperiod === "prevWeek") {
      setMapfromdate(prevweekDate);
      setMaptodate(weekDate);
      setCalledreplay(true);
    } else if (mapperiod === "thisMonth") {
      setMapfromdate(monthDate);
      setMaptodate(currentDate);
      setCalledreplay(true);
      // setShowMapCustom(false)
    } else if (mapperiod === "prevMonth") {
      setMapfromdate(prevmonthDate);
      setMaptodate(monthDate);
      setCalledreplay(true);
    } else if (mapperiod === "thisYear") {
      setMapfromdate(yearDate);
      setMaptodate(currentDate);
      setCalledreplay(true);
      // setShowMapCustom(false)
    } else if (mapperiod === "custom") {
      // setShowMapCustom(true)
      setCalledreplay(true);
    }
  }, [
    currentDate,
    mapperiod,
    monthDate,
    weekDate,
    yearDate,
    setCalledreplay,
    setInitialApiCall,
    yesterdayDate,
    prevweekDate,
    prevmonthDate,
  ]);

  useEffect(() => {
    if (calledreplay) {
      setLoader(true);
      Promise.all([
        getFilterCoordinates(mapfromDate, maptoDate),
        getMobileFilterCoordinates(mapfromDate, maptoDate),
      ])
        .then(([vehicleData, mobileData]) => {
          if (vehicleData.error || mobileData.error) {
            failed();
          } else {
            setVehicleCoordinates(vehicleData);
            setMobileCoordinates(mobileData);
          }
        })
        .finally(() => {
          setLoader(false);
        });
    }
  }, [
    mapfromDate,
    maptoDate,
    calledreplay,
    displayCoordinates,
    vehiclesInfo,
    setLoader,
    setMobileCoordinates,
    setVehicleCoordinates,
  ]);

  useEffect(() => {
    if (pathFilter) {
      setLoader(true);
      getFilterMapData(fromDate, toDate, device, table, report)
        .then((data) => {
          if (data.error) {
            failed();
          } else {
            const uniqueDeviceIds =
              report === "vehicle"
                ? vehiclesInfo.map((vehicle: IVehicleType) => vehicle.device_id)
                : mobilesInfo.map((vehicle: IMobileType) => vehicle.device_id);
            const filteredData = data.filter((trip: any) => {
              const DeviceIdString = trip.device_id.toString();
              return uniqueDeviceIds.includes(DeviceIdString);
            });
            if (report === "vehicle") {
              setVehicleCoordinates(filteredData);
              setMobileCoordinates();
            } else {
              setVehicleCoordinates();
              setMobileCoordinates(filteredData);
            }
            // setViewportData(filteredData);
          }
        })
        .finally(() => {
          setLoader(false);
        });
    }
  }, [
    setLoader,
    vehiclesInfo,
    pathFilter,
    fromDate,
    toDate,
    device,
    setVehicleCoordinates,
    table,
    setViewportData,
    setMobileCoordinates,
    report,
    mobilesInfo,
  ]);

  const toMapDateField = useMemo(
    () => (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        {/* <DemoContainer components={["DatePicker"]}> */}
        <DatePicker
          label="To"
          format="YYYY-MM-DD"
          // autoFocus
          value={dayjs(new Date())}
          sx={{
            "& .MuiOutlinedInput-input": {
              height: "8px",
            },
            "& .MuiFormLabel-root.MuiInputLabel-root": {
              color: textColor,
              marginTop: "-6px",
            },
            "& .MuiSvgIcon-root": {
              color: textColor,
            },
            // ".css-1jy569b-MuiFormLabel-root-MuiInputLabel-root":{
            //   color: iconColor,
            // },
            "& .MuiInputBase-input.MuiOutlinedInput-input": {
              color: textColor,
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: lineColor,
            },
            "& .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
              {
                borderColor: lineColor,
              },
            "& .MuiInputBase-root.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
              {
                borderColor: selectedMenuColorHover,
              },
          }}
          onChange={(date: Dayjs | null) =>
            handleMapCustomDateChange(date, "to")
          }
        />
        {/* </DemoContainer> */}
      </LocalizationProvider>
    ),
    []
  );

  const toDateField = useMemo(
    () => (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        {/* <DemoContainer components={["DatePicker"]}> */}
        <DatePicker
          label="To"
          format="YYYY-MM-DD"
          // autoFocus
          value={dayjs(new Date())}
          sx={{
            "& .MuiOutlinedInput-input": {
              height: "8px",
            },
            "& .MuiFormLabel-root.MuiInputLabel-root": {
              color: textColor,
              marginTop: "-6px",
            },
            "& .MuiSvgIcon-root": {
              color: textColor,
            },
            // ".css-1jy569b-MuiFormLabel-root-MuiInputLabel-root":{
            //   color: iconColor,
            // },
            "& .MuiInputBase-input.MuiOutlinedInput-input": {
              color: textColor,
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: lineColor,
            },
            "& .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
              {
                borderColor: lineColor,
              },
            "& .MuiInputBase-root.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
              {
                borderColor: selectedMenuColorHover,
              },
          }}
          onChange={(date: Dayjs | null) => handleCustomDateChange(date, "to")}
        />
        {/* </DemoContainer> */}
      </LocalizationProvider>
    ),
    [handleCustomDateChange]
  );

  useEffect(() => {
    report === "vehicle"
      ? table === "trip"
        ? setColumns([
            "device_name",
            "start_address",
            "end_address",
            "start_time",
            "end_time",
            "road_snap",
          ])
        : table === "stop"
        ? setColumns([
            "device_name",
            "address",
            "start_time",
            "end_time",
            "action",
          ])
        : setColumns([
            "device_name",
            "distance",
            "max_speed",
            "average_speed",
            "engine_hours",
          ])
      : setColumns([
          "fullname",
          "start_location",
          "end_location",
          "start_time",
          "end_time",
          "road_snap",
        ]);
  }, [table, setColumns, report]);

  const tripColumns: GridColDef[] = useMemo(
    () => [
      {
        field: "device_name",
        headerName: "Vehicle Name",
      },
      {
        field: "vehicle_id",
        headerName: "Vehicle ID",
      },
      {
        field: "driver_id",
        headerName: "Driver ID",
      },
      {
        field: "device_id",
        headerName: "Device ID",
      },
      // {
      //   field: "mileage",
      //   headerName: "Mileage (km)",
      // },
      // {
      //   field: "capacity",
      //   headerName: "Fuel Capacity (L)",
      // },
      {
        field: "start_address",
        headerName: "Start Address",
      },
      {
        field: "end_address",
        headerName: "End Address",
      },
      {
        field: "start_time",
        headerName: "Start Time",
      },
      {
        field: "end_time",
        headerName: "End Time",
      },
      {
        field: "duration",
        headerName: "Duration",
      },
      {
        field: "distance",
        headerName: "Distance (km)",
      },
      {
        field: "max_speed",
        headerName: "Max. Speed (km/h)",
      },
      // {
      //   field: "spent_fuel",
      //   headerName: "Spent Fuel",
      // },
      {
        field: "start_odometer",
        headerName: "Start Odometer (km)",
      },
      {
        field: "end_odometer",
        headerName: "End Odometer (km)",
      },
      {
        field: "road_snap",
        headerName: "Action",
      },
    ],
    []
  );

  const stopColumns: GridColDef[] = useMemo(
    () => [
      {
        field: "device_name",
        headerName: "Vehicle Name",
      },
      {
        field: "vehicle_id",
        headerName: "Vehicle ID",
      },
      {
        field: "driver_id",
        headerName: "Driver ID",
      },
      {
        field: "device_id",
        headerName: "Device ID",
      },
      // {
      //   field: "mileage",
      //   headerName: "Mileage (km)",
      // },
      // {
      //   field: "capacity",
      //   headerName: "Fuel Capacity (L)",
      // },
      {
        field: "address",
        headerName: "Address",
      },
      // {
      //   field: "position_id",
      //   headerName: "Position ID",
      // },
      {
        field: "start_time",
        headerName: "Start Time",
      },
      {
        field: "end_time",
        headerName: "End Time",
      },
      {
        field: "duration",
        headerName: "Duration",
      },
      {
        field: "engine_hours",
        headerName: "Engine Hours",
      },
      {
        field: "longitude",
        headerName: "Longitude",
      },
      {
        field: "latitde",
        headerName: "Latitude",
      },
      // {
      //   field: "distance",
      //   headerName: "Distance (km)",
      // },
      // {
      //   field: "max_speed",
      //   headerName: "Max. Speed (km/h)",
      // },
      // {
      //   field: "average_speed",
      //   headerName: "Avg. Speed (km/h)",
      // },
      // {
      //   field: "spent_fuel",
      //   headerName: "Spent Fuel",
      // },
      {
        field: "start_odometer",
        headerName: "Start Odometer (km)",
      },
      {
        field: "end_odometer",
        headerName: "End Odometer (km)",
      },
      {
        field: "action",
        headerName: "Action",
      },
    ],
    []
  );

  const summaryColumns: GridColDef[] = useMemo(
    () => [
      {
        field: "device_name",
        headerName: "Vehicle Name",
      },
      {
        field: "distance",
        headerName: "Distance (km)",
      },
      {
        field: "max_speed",
        headerName: "Max. Speed (km/h)",
      },
      {
        field: "average_speed",
        headerName: "Avg. Speed (km/h)",
      },
      // {
      //   field: "spent_fuel",
      //   headerName: "Spent Fuel",
      // },
      {
        field: "start_odometer",
        headerName: "Start Odometer (km)",
      },
      {
        field: "end_odometer",
        headerName: "End Odometer (km)",
      },
      {
        field: "start_time",
        headerName: "Start Time",
      },
      {
        field: "end_time",
        headerName: "End Time",
      },
      {
        field: "engine_hours",
        headerName: "Engine Hours",
      },
    ],
    []
  );

  const mobileTripColumns: GridColDef[] = useMemo(
    () => [
      {
        field: "fullname",
        headerName: "Full Name",
      },
      {
        field: "start_location",
        headerName: "Start Address",
      },
      {
        field: "end_location",
        headerName: "End Address",
      },
      {
        field: "start_time",
        headerName: "Start Time",
      },
      {
        field: "end_time",
        headerName: "End Time",
      },
      {
        field: "distance",
        headerName: "Distance (km)",
      },
      {
        field: "duration",
        headerName: "Duration",
      },
      {
        field: "max_speed",
        headerName: "Max. Speed (km/h)",
      },
      {
        field: "average_speed",
        headerName: "Avg. Speed (km/h)",
      },
      {
        field: "road_snap",
        headerName: "Action",
      },
    ],
    []
  );

  const handleExport = () => {
    setLoader(true);
    exportData(fromDate, toDate, device, table)
      .catch(() => {
        failed();
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const handleMobileExport = () => {
    setLoader(true);
    exportMobileData(fromDate, toDate, device, table)
      .catch(() => {
        failed();
      })
      .finally(() => {
        setLoader(false);
      });
  };

  return (
    <>
      <Box
        className="navbar"
        bgcolor={primaryColor}
        position="absolute"
        left="0"
        // width={isMobile ? '150px' : '200px'}
        borderBottom={`2px solid ${lineColor}`}
        // borderRight={`2px solid ${lineColor}`}
        width={
          showFilter || isMobile
            ? "100%"
            : mapperiod === "custom"
            ? "630px"
            : "300px"
        }
        // zIndex='-3'
      >
        <Box
          className="filter"
          display="flex"
          flexWrap={isMobile ? "wrap" : "unset"}
          p="15px"
          gap="15px"
          justifyContent="space-between"
        >
          {!showFilter ? (
            <>
              <Box minWidth={isMobile ? "100%" : "265px"}>
                <FormControl size="small" fullWidth>
                  {/* {dateField} */}
                  <InputLabel sx={{ color: iconColor }}>Period</InputLabel>
                  <SelectComponent
                    className="mapperiod"
                    value={mapperiod}
                    label="Period"
                    // onChange={handleChange}
                    onChange={(e) => {
                      setMapPeriod(e.target.value as string);
                    }}
                    MenuProps={{
                      sx: {
                        "& .MuiMenuItem-root.Mui-selected": {
                          backgroundColor: selectedMenuColor,
                        },
                        "& .MuiButtonBase-root.MuiMenuItem-root:hover": {
                          backgroundColor: selectedMenuColorHover,
                        },
                      },
                    }}
                  >
                    <MenuItem value="default">Today</MenuItem>
                    {/* <MenuItem value="today">Today</MenuItem> */}
                    <MenuItem value="yesterday">Yesterday</MenuItem>
                    <MenuItem value="thisWeek">This Week</MenuItem>
                    <MenuItem value="prevWeek">Previous Week</MenuItem>
                    <MenuItem value="thisMonth">This Month</MenuItem>
                    <MenuItem value="prevMonth">Previous Month</MenuItem>
                    <MenuItem value="thisYear">This Year</MenuItem>
                    <MenuItem value="custom">Custom</MenuItem>
                  </SelectComponent>
                </FormControl>
              </Box>

              {mapperiod === "custom" ? (
                <Box
                  className="filter"
                  display="flex"
                  flexWrap={isMobile ? "wrap" : "unset"}
                  // p="15px"
                  // mt="15px"
                  gap="5px"
                  justifyContent={isMobile ? "space-between" : "start"}
                >
                  <Box width={isMobile ? "45%" : "50%"}>
                    <FormControl size="small" fullWidth>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        {/* <DemoContainer components={["DatePicker"]}> */}
                        <DatePicker
                          label="From"
                          format="YYYY-MM-DD"
                          // autoFocus
                          value={dayjs(mapfromDate)}
                          sx={{
                            "& .MuiOutlinedInput-input": {
                              height: "8px",
                            },
                            "& .MuiFormLabel-root.MuiInputLabel-root": {
                              color: textColor,
                              marginTop: "-6px",
                            },
                            "& .MuiSvgIcon-root": {
                              color: textColor,
                            },
                            // ".css-1jy569b-MuiFormLabel-root-MuiInputLabel-root":{
                            //   color: iconColor,
                            // },
                            "& .MuiInputBase-input.MuiOutlinedInput-input": {
                              color: textColor,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: lineColor,
                            },
                            "& .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: lineColor,
                              },
                            "& .MuiInputBase-root.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: selectedMenuColorHover,
                              },
                          }}
                          onChange={(date: Dayjs | null) =>
                            handleMapCustomDateChange(date, "from")
                          }
                        />
                        {/* </DemoContainer> */}
                      </LocalizationProvider>
                    </FormControl>
                  </Box>
                  <Box width={isMobile ? "45%" : "50%"}>
                    <FormControl size="small" fullWidth>
                      {toMapDateField}
                    </FormControl>
                  </Box>
                </Box>
              ) : null}
            </>
          ) : (
            <>
              <Box width={isMobile ? "45%" : "18%"}>
                <FormControl size="small" fullWidth>
                  <InputLabel sx={{ color: iconColor }}>GPS</InputLabel>{" "}
                  {/* report */}
                  <SelectComponent
                    className="report"
                    value={report}
                    label="Report"
                    onChange={(e) => {
                      setReport(e.target.value as string);
                      setTable("trip");
                      setDevice("all");
                    }}
                    MenuProps={{
                      sx: {
                        "& .MuiMenuItem-root.Mui-selected": {
                          backgroundColor: selectedMenuColor,
                        },
                        "& .MuiButtonBase-root.MuiMenuItem-root:hover": {
                          backgroundColor: selectedMenuColorHover,
                        },
                      },
                    }}
                  >
                    <MenuItem value="vehicle">Vehicle</MenuItem>
                    {role === "sa" && (
                      <MenuItem value="mobile">Mobile</MenuItem>
                    )}
                  </SelectComponent>
                </FormControl>
              </Box>

              <Box width={isMobile ? "45%" : "18%"}>
                <FormControl size="small" fullWidth>
                  <InputLabel sx={{ color: iconColor }}>Report</InputLabel>{" "}
                  {/* table */}
                  <SelectComponent
                    className="table"
                    value={table}
                    label="Table"
                    onChange={(e) => setTable(e.target.value as string)}
                    MenuProps={{
                      sx: {
                        "& .MuiMenuItem-root.Mui-selected": {
                          backgroundColor: selectedMenuColor,
                        },
                        "& .MuiButtonBase-root.MuiMenuItem-root:hover": {
                          backgroundColor: selectedMenuColorHover,
                        },
                      },
                    }}
                  >
                    <MenuItem value="trip">Trip</MenuItem>
                    {report === "vehicle" && [
                      <MenuItem key="stop" value="stop">
                        Stop
                      </MenuItem>,
                      !pathFilter && (
                        <MenuItem key="dailySummary" value="dailySummary">
                          Daily Summary
                        </MenuItem>
                      ),
                    ]}
                  </SelectComponent>
                </FormControl>
              </Box>

              <Box width={isMobile ? "45%" : "18%"}>
                <FormControl size="small" fullWidth>
                  <InputLabel sx={{ color: iconColor }}>Device</InputLabel>
                  <SelectComponent
                    className="device"
                    value={device}
                    label="Device"
                    // onChange={handleChange}
                    onChange={(e) => setDevice(e.target.value as string)}
                    MenuProps={{
                      sx: {
                        "& .MuiMenuItem-root.Mui-selected": {
                          backgroundColor: selectedMenuColor,
                        },
                        "& .MuiButtonBase-root.MuiMenuItem-root:hover": {
                          backgroundColor: selectedMenuColorHover,
                        },
                        "& .MuiPaper-root.MuiPopover-paper.MuiMenu-paper::-webkit-scrollbar":
                          {
                            width: "4px",
                          },
                        "& .MuiPaper-root.MuiPopover-paper.MuiMenu-paper::-webkit-scrollbar-thumb":
                          {
                            backgroundColor: scrollbarColor,
                            borderRadius: "4px",
                          },
                        "& .MuiPaper-root.MuiPopover-paper.MuiMenu-paper::-webkit-scrollbar-track":
                          {
                            backgroundColor: lineColor,
                          },
                        "& .MuiPaper-root.MuiPopover-paper.MuiMenu-paper": {
                          scrollbarColor: `${scrollbarColor} ${lineColor}`,
                          scrollbarWidth: "thin",
                        },
                      },
                    }}
                  >
                    <MenuItem value="all">All</MenuItem>
                    {report === "vehicle"
                      ? vehiclesInfo &&
                        vehiclesInfo.map((item: IVehicleType) => {
                          return (
                            <MenuItem
                              value={item.device_id}
                              key={item.device_id}
                            >
                              {item.name}
                            </MenuItem>
                          );
                        })
                      : mobilesInfo &&
                        mobilesInfo.map((item: IMobileType) => {
                          return (
                            <MenuItem
                              value={item.device_id}
                              key={item.device_id}
                            >
                              {item.fullname}
                            </MenuItem>
                          );
                        })}
                  </SelectComponent>
                </FormControl>
              </Box>

              <Box width={isMobile ? "45%" : "18%"}>
                <FormControl size="small" fullWidth>
                  <InputLabel sx={{ color: iconColor }}>Period</InputLabel>
                  <SelectComponent
                    className="period"
                    value={period}
                    label="Period"
                    // onChange={handleChange}
                    onChange={(e) => setPeriod(e.target.value as string)}
                    MenuProps={{
                      sx: {
                        "& .MuiMenuItem-root.Mui-selected": {
                          backgroundColor: selectedMenuColor,
                        },
                        "& .MuiButtonBase-root.MuiMenuItem-root:hover": {
                          backgroundColor: selectedMenuColorHover,
                        },
                      },
                    }}
                  >
                    <MenuItem value="today">Today</MenuItem>
                    <MenuItem value="yesterday">Yesterday</MenuItem>
                    <MenuItem value="thisWeek">This Week</MenuItem>
                    <MenuItem value="prevWeek">Previous Week</MenuItem>
                    <MenuItem value="thisMonth">This Month</MenuItem>
                    <MenuItem value="prevMonth">Previous Month</MenuItem>
                    <MenuItem value="thisYear">This Year</MenuItem>
                    <MenuItem value="custom">Custom</MenuItem>
                  </SelectComponent>
                </FormControl>
              </Box>

              {period === "custom" ? (
                <>
                  <Box width={isMobile ? "45%" : "20%"}>
                    <FormControl size="small" fullWidth>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        {/* <DemoContainer components={["DatePicker"]}> */}
                        <DatePicker
                          label="From"
                          format="YYYY-MM-DD"
                          // autoFocus
                          value={dayjs(reportDate)}
                          sx={{
                            "& .MuiOutlinedInput-input": {
                              height: "8px",
                            },
                            "& .MuiFormLabel-root.MuiInputLabel-root": {
                              color: textColor,
                              marginTop: "-6px",
                            },
                            "& .MuiSvgIcon-root": {
                              color: textColor,
                            },
                            // ".css-1jy569b-MuiFormLabel-root-MuiInputLabel-root":{
                            //   color: iconColor,
                            // },
                            "& .MuiInputBase-input.MuiOutlinedInput-input": {
                              color: textColor,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: lineColor,
                            },
                            "& .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: lineColor,
                              },
                            "& .MuiInputBase-root.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: selectedMenuColorHover,
                              },
                          }}
                          onChange={(date: Dayjs | null) =>
                            handleCustomDateChange(date, "from")
                          }
                        />
                        {/* </DemoContainer> */}
                      </LocalizationProvider>
                    </FormControl>
                  </Box>
                  <Box width={isMobile ? "45%" : "20%"}>
                    <FormControl size="small" fullWidth>
                      {toDateField}
                    </FormControl>
                  </Box>
                </>
              ) : null}
              {!pathFilter && (
                <Box width={isMobile ? "45%" : "20%"}>
                  <FormControl size="small" fullWidth>
                    <InputLabel sx={{ color: iconColor }}>Columns</InputLabel>
                    <SelectComponent
                      multiple
                      className="columns"
                      value={columns}
                      label="Columns"
                      onChange={(e) => setColumns(e.target.value as string[])}
                      MenuProps={{
                        sx: {
                          "& .MuiMenuItem-root.Mui-selected": {
                            backgroundColor: selectedMenuColor,
                          },
                          "& .MuiButtonBase-root.MuiMenuItem-root:hover": {
                            backgroundColor: selectedMenuColorHover,
                          },
                          "& .MuiPaper-root.MuiPopover-paper.MuiMenu-paper::-webkit-scrollbar":
                            {
                              width: "4px",
                            },
                          "& .MuiPaper-root.MuiPopover-paper.MuiMenu-paper::-webkit-scrollbar-thumb":
                            {
                              backgroundColor: scrollbarColor,
                              borderRadius: "4px",
                            },
                          "& .MuiPaper-root.MuiPopover-paper.MuiMenu-paper::-webkit-scrollbar-track":
                            {
                              backgroundColor: lineColor,
                            },
                          "& .MuiPaper-root.MuiPopover-paper.MuiMenu-paper": {
                            scrollbarColor: `${scrollbarColor} ${lineColor}`,
                            scrollbarWidth: "thin",
                          },
                        },
                      }}
                    >
                      {report === "vehicle"
                        ? table === "trip"
                          ? tripColumns.map((item) => {
                              return (
                                <MenuItem key={item.field} value={item.field}>
                                  {item.headerName}
                                </MenuItem>
                              );
                            })
                          : table === "stop"
                          ? stopColumns.map((item) => {
                              return (
                                <MenuItem key={item.field} value={item.field}>
                                  {item.headerName}
                                </MenuItem>
                              );
                            })
                          : summaryColumns.map((item) => {
                              return (
                                <MenuItem key={item.field} value={item.field}>
                                  {item.headerName}
                                </MenuItem>
                              );
                            })
                        : mobileTripColumns.map((item) => {
                            return (
                              <MenuItem key={item.field} value={item.field}>
                                {item.headerName}
                              </MenuItem>
                            );
                          })}
                    </SelectComponent>
                  </FormControl>
                </Box>
              )}
              {/* <Box width={isMobile ? "45%" : "20.2%"}>
                <TextField
                  // autoFocus
                  // margin="dense"
                  label="Search"
                  // type={item.type}
                  fullWidth
                  variant="outlined"
                  // key={item.key}
                  disabled
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon
                          sx={{
                            color: textColor,
                            opacity: 0.5,
                          }}
                        />
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    "& label.Mui-focused": {
                      color: iconColor,
                    },
                    "& .MuiFormLabel-root.MuiInputLabel-root": {
                      color: lineColor,
                      // top: "-6px",
                    },
                    "& .MuiOutlinedInput-input": {
                      padding: "8.5px 2px",
                      color: textColor,
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: iconColor,
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: iconColor,
                      },
                      "&:hover fieldset": {
                        borderColor: selectedMenuColorHover,
                      },
                      "&.Mui-disabled": {
                        "& fieldset": {
                          borderColor: iconColor,
                        },
                      },
                    },
                  }}
                />
              </Box> */}
              {showExport && (
                <Box
                  width={isMobile ? "45%" : "2%"}
                  display="flex"
                  alignItems="center"
                >
                  <Tooltip title={reportExport ? "Export Excel" : "No Data"}>
                    <Icon
                      component={DownloadIcon}
                      sx={{
                        color: iconColor,
                        height: "25px",
                        cursor: reportExport ? "pointer" : "not-allowed",
                        opacity: reportExport ? 1 : 0.5,
                      }}
                      onClick={() => {
                        if (reportExport) {
                          report === "vehicle"
                            ? handleExport()
                            : handleMobileExport();
                        }
                      }}
                    />
                  </Tooltip>
                </Box>
              )}
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

export default Filter;
