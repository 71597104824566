import React from 'react'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2'

export const success = () => toast.success('Success!');
export const failed = () => toast.error("Error!");
export const message = () =>{
  return Swal.fire({
    icon: 'question',
    title: 'Are you sure?',
    showCancelButton: true,
    confirmButtonText: 'Yes, Delete It!',
    confirmButtonColor: "#DD6B55",
    allowOutsideClick: false,
    customClass: {
      container: 'my-swal'
    }  
  });
}

export function useToast() {
  return (
    <div>
        <ToastContainer
            position="bottom-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick={false}
            rtl={false}
            draggable
            pauseOnHover
            theme="light"
            limit={1}
        />
    </div>
  )
}