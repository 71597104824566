import React, { useContext, useEffect, useState } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
  iconColor,
  lineColor,
  scrollbarColor,
  textColor,
} from "../constants/color-constants";
import { IVehicleType, getsummaryData } from "../api/Api";
import { LoaderContext, ReportExportContext, VehiclesDataContext } from "../App";
import { failed } from "../hooks/useToast";

const SummaryTable = (props: {
  columns: GridColDef[];
  fromDate: string;
  toDate: string;
  device: string;
}) => {
  const [summaryData, setSummaryData] = useState([]);
  const { setLoader } = useContext(LoaderContext);
  const { setReportExport } = useContext(ReportExportContext);
  const { vehiclesInfo } = useContext(VehiclesDataContext);

  useEffect(() => {
    setLoader(true);
    getsummaryData(props.fromDate, props.toDate, props.device)
      .then((data) => {
        if (data.error) {
          failed();
        } else {
          const uniqueDeviceIds = vehiclesInfo.map(
            (vehicle: IVehicleType) => vehicle.device_id
          );
          const filteredTrips = data.filter((trip: any) => {
            const tripDeviceIdString = trip.device_id.toString();
            return uniqueDeviceIds.includes(tripDeviceIdString);
          });
          setSummaryData(filteredTrips);

          if (filteredTrips.length > 0) {
            setReportExport(true);
          } else {
            setReportExport(false);
          }
        }
      })
      .finally(() => {
        setLoader(false);
      });
  }, [props.fromDate, props.toDate, props.device, setLoader, setReportExport, vehiclesInfo]);
  return (
    <>
      <DataGrid
        rows={summaryData}
        columns={props.columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 100,
            },
          },
        }}
        pageSizeOptions={[100]}
        disableRowSelectionOnClick
        getRowId={(row) => row.id}
        disableColumnMenu
        sx={{
          color: textColor,
          border: "none",
          "& .MuiDataGrid-cell": {
            borderColor: iconColor,
          },
          "& .MuiDataGrid-cell:focus-within": {
            outline: "none",
          },
          "& .MuiDataGrid-footerContainer": {
            border: "none",
            "& .MuiTablePagination-root": {
              color: iconColor, 
            },
          },
          "& .MuiDataGrid-columnHeaders": {
            borderColor: iconColor,
          },
          // "& p.MuiTablePagination-displayedRows.css-levciy-MuiTablePagination-displayedRows":
          //   {
          //     color: iconColor,
          //   },
          "& button.MuiButtonBase-root.Mui-disabled.MuiIconButton-root.Mui-disabled.MuiIconButton-colorInherit.MuiIconButton-sizeMedium.css-zylse7-MuiButtonBase-root-MuiIconButton-root":
            {
              color: "rgb(0,0,0,0)",
            },
          "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
            width: "4px",
            height: "4px",
          },
          "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb": {
            backgroundColor: scrollbarColor,
            borderRadius: "4px",
          },
          "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track": {
            backgroundColor: lineColor,
          },
          "& .MuiDataGrid-virtualScroller": {
            scrollbarColor: `${scrollbarColor} ${lineColor}`,
            scrollbarWidth: "thin",
          },
        }}
      />
    </>
  );
};

export default SummaryTable;
