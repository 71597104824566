import React, { useContext, useEffect, useState } from "react";
import { Box, Icon, Typography, useMediaQuery } from "@mui/material";
import {
  InfoDataContext,
  LoaderContext,
  MobileCoordinatesContext,
  MobilesDataContext,
  UserDetailsContext,
  VehicleCoordinatesContext,
  VehicleDataPopupContext,
  VehiclesDataContext,
  initialApiCallContext,
} from "../App";
import {
  iconColor,
  primaryColor,
  textColor,
} from "../constants/color-constants";
import CloseIcon from "@mui/icons-material/Close";
import {
  IMobileCoordinates,
  IMobileType,
  IVehicleCoordinates,
  IVehicleType,
  deleteMobileRegister,
  deleteRegister,
} from "../api/Api";
import EditVehicle from "./EditVehicle";
import DeleteIcon from "@mui/icons-material/Delete";
import { failed, message, success } from "../hooks/useToast";
import MoreDetails from "./MoreDetails";

const InfoBox = () => {
  const isMobile = useMediaQuery("(max-width:600px)");
  // const { vehicleData, setVehicleData } = useContext(VehicleDataContext);
  const { infoData, setInfoData } = useContext(InfoDataContext);
  const { vehiclesInfo, updateVehiclesInfo } = useContext(VehiclesDataContext);
  const { mobilesInfo, setmobilesInfo } = useContext(MobilesDataContext);
  const { setLoader } = useContext(LoaderContext);
  const { vehicleDataPopup } = useContext(VehicleDataPopupContext);
  const { setInitialApiCall } = useContext(initialApiCallContext);
  const { role } = useContext(UserDetailsContext);
  const { vehiclecoordinates } = useContext(VehicleCoordinatesContext);
  const { mobilecoordinates } = useContext(MobileCoordinatesContext);
  // const filteredData =
  // device === 'device'
  // ? vehiclesInfo.length && vehiclesInfo.filter((item: IVehicleType) => item.device_id === fetchDeviceid)
  // : mobilesInfo.length && mobilesInfo.filter((item: IMobileType) => item.device_id === fetchDeviceid);
  const [vehiclefilteredData, setvehicleFilteredData] = useState<
    IVehicleType[] | undefined
  >(undefined);
  const [mobilefilteredData, setMobileFilteredData] = useState<
    IMobileType[] | undefined
  >(undefined);
  const [infoCoordinates, setInfoCoordinates] = useState<any[] | undefined>(
    undefined
  );

  useEffect(() => {
    if (infoData !== undefined) {
      const filtered =
        vehicleDataPopup === "vehicle"
          ? vehiclesInfo.length &&
            vehiclesInfo.filter(
              (item: IVehicleType) => item.device_id === infoData[0].device_id
            )
          : mobilesInfo.length &&
            mobilesInfo.filter(
              (item: IMobileType) => item.device_id === infoData[0].device_id
            );
      const filteredCoordinates =
        vehicleDataPopup === "vehicle"
          ? vehiclecoordinates.length &&
            vehiclecoordinates.filter(
              (item: IVehicleCoordinates) =>
                item.device_id === infoData[0].device_id
            )
          : mobilecoordinates.length &&
            mobilecoordinates.filter(
              (item: IMobileCoordinates) =>
                item.device_id === infoData[0].device_id
            );
      //   const filtered =
      //     vehiclesInfo.length &&
      //     vehiclesInfo.filter(
      //       (item: IVehicleType) => item.device_id === vehicleData[0].device_id
      //     );
      vehicleDataPopup === "vehicle"
        ? setvehicleFilteredData(filtered)
        : setMobileFilteredData(filtered);

      setInfoCoordinates(filteredCoordinates);
    }
  }, [
    mobilesInfo,
    infoData,
    vehicleDataPopup,
    vehiclesInfo,
    vehiclecoordinates,
    mobilecoordinates,
  ]);

  const calculateSpeed = (speed: number) => {
    if (speed !== undefined && speed !== null) {
      const totalSpeed = speed.toFixed(2);
      return `${totalSpeed} km/h`;
    }
    return "0.00 km/h";
  };

  const renderAddress = (address: string) => {
    if (address !== undefined && address !== null) {
      return address;
    }
    return "No Data";
  };

  const renderTotalDistance = (distance: number) => {
    if (distance !== undefined && distance !== null) {
      const totalDistance = (distance / 1000).toFixed(2);
      return `${totalDistance} km`;
    }
    return "No Data";
  };

  const handleDelete = (device_id: string) => {
    message().then((result) => {
      if (result.isConfirmed) {
        setLoader(true);
        deleteRegister(device_id)
          .then((response) => {
            const { status } = response;
            if (status === 204) {
              const updatedInfo = vehiclesInfo.filter(
                (vehicle: IVehicleType) => vehicle.device_id !== device_id
              );
              updateVehiclesInfo(updatedInfo);
              setInitialApiCall(false);
              setInfoData(undefined);
              success();
            } else {
              failed();
            }
          })
          .finally(() => {
            setLoader(false);
          });
      }
    });
  };

  const handleMobileDelete = (device_id: string) => {
    message().then((result) => {
      if (result.isConfirmed) {
        setLoader(true);
        deleteMobileRegister(device_id)
          .then((response) => {
            const { status } = response;
            if (status === 204) {
              const updatedInfo = mobilesInfo.filter(
                (mobile: IMobileType) => mobile.device_id !== device_id
              );
              setmobilesInfo(updatedInfo);
              setInitialApiCall(false);
              success();
            } else {
              failed();
            }
          })
          .finally(() => {
            setLoader(false);
          });
      }
    });
  };

  return (
    <>
      <Box
        display={infoData ? "flex" : "none"}
        flexDirection="column"
        position="fixed"
        bottom={isMobile ? "75px" : "10px"}
        left={isMobile ? "3px" : "320px"}
        right={isMobile ? "3px" : "unset"}
        bgcolor={primaryColor}
        color={textColor}
        p="15px"
        borderRadius="5px"
        gap="10px"
        zIndex={isMobile ? "-2" : "1"}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center" gap="15px">
          <Typography fontSize="20px">
            {vehicleDataPopup === "vehicle"
              ? vehiclefilteredData?.[0]?.name
              : mobilefilteredData?.[0]?.fullname}
          </Typography>
          <Box display="flex" justifyContent="space-between" gap="25px">
            
              <Box display="flex" gap="15px">
                <Box display="flex" flexDirection="column" color={iconColor}>
                  {vehicleDataPopup === "vehicle" ? (
                    <EditVehicle
                      data={vehiclefilteredData?.[0]?.device_id ?? "0"}
                      check="device"
                    />
                  ) : (
                    <EditVehicle
                      data={mobilefilteredData?.[0]?.device_id ?? "0"}
                      check="mobile"
                    />
                  )}
                </Box>
              {role === "sa" && (
                <Box display="flex" flexDirection="column">
                  <Icon
                    component={DeleteIcon}
                    onClick={() =>
                      vehicleDataPopup === "vehicle"
                        ? handleDelete(
                            vehiclefilteredData?.[0]?.device_id ?? "0"
                          )
                        : handleMobileDelete(
                            mobilefilteredData?.[0]?.device_id ?? "0"
                          )
                    }
                    sx={{
                      color: iconColor,
                      cursor: "pointer",
                    }}
                  />
                </Box>
              )}
              </Box>
            <CloseIcon
              onClick={() => setInfoData(undefined)}
              sx={{
                height: "22px",
                cursor: "pointer",
              }}
            />
          </Box>
        </Box>

        <Box>
          {/* <Box display="flex" flexDirection="column" color={iconColor}>
            <Typography>Speed: </Typography>
            <Typography>Address: </Typography>
            {/* <Typography>Mileage</Typography>
            <Typography>Fuel Capacity</Typography>
            <Typography>Last Refuel Date</Typography> */}
          {/* </Box> */}

          <Box display="flex" flexDirection="column">
            <Typography>
              {calculateSpeed(infoCoordinates?.[0]?.speed)}
            </Typography>
            <Typography>
              {renderAddress(infoCoordinates?.[0]?.address)}
              {/* {vehicleData?.[0]?.address || vehicleData?.[0]?.address === null
                ? "No Data"
                : vehicleData?.[0]?.address} */}
            </Typography>
            <Typography>
              {renderTotalDistance(infoCoordinates?.[0]?.total_distance)}
            </Typography>
            {/* <Typography>{props.data?.mileage} km</Typography> */}
            {/* <Typography>{props.data?.capacity} litres</Typography> */}
            {/* <Typography>2023/01/01</Typography> */}
          </Box>
          {vehicleDataPopup === "vehicle"
            ? vehiclefilteredData?.map((vehicle: IVehicleType) => (
                <Box
                  className="details"
                  textAlign="center"
                  color={textColor}
                  key={vehicle.device_id}
                >
                  <MoreDetails data={vehicle} check={vehicleDataPopup} />
                </Box>
              ))
            : mobilefilteredData?.map((mobile: IMobileType) => (
                <Box
                  className="details"
                  textAlign="center"
                  color={textColor}
                  key={mobile.device_id}
                >
                  <MoreDetails data={mobile} check={vehicleDataPopup} />
                </Box>
              ))}
        </Box>
      </Box>
    </>
  );
};

export default InfoBox;
