import React, {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  TextField,
  useMediaQuery,
} from "@mui/material";
import AirportShuttleIcon from "@mui/icons-material/AirportShuttle";
import {
  iconColor,
  lineColor,
  primaryColor,
  scrollbarColor,
  textColor,
  errorMessageColor,
  selectedMenuColor,
  selectedMenuColorHover,
} from "../constants/color-constants";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  IMobileType,
  IVehicleType,
  IformItems,
  Image,
  deleteImage,
  formItems,
  mobileFormItems,
  updateMobile,
  updateVehicle,
} from "../api/Api";
import { format } from "date-fns";
import {
  LoaderContext,
  MobilesDataContext,
  UserDetailsContext,
  VehiclesDataContext,
  initialApiCallContext,
} from "../App";
import { success, failed, message } from "../hooks/useToast";
import { MuiColorInput } from "mui-color-input";
import { ColorInput } from "@ctrl/tinycolor";
import EditIcon from "@mui/icons-material/Edit";
import dayjs, { Dayjs } from "dayjs";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import { API } from "../config";
import { SelectComponent } from "./Report";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";

const EditVehicle = (props: { data: string; check: string }) => {
  const [open, setOpen] = useState(false);

  const { vehiclesInfo, updateVehiclesInfo } = useContext(VehiclesDataContext);
  const { mobilesInfo, setmobilesInfo } = useContext(MobilesDataContext);
  const { setInitialApiCall } = useContext(initialApiCallContext);
  const { tenants, role } = useContext(UserDetailsContext);

  const isMobile = useMediaQuery("(max-width:460px)");
  const defaultVehicleState: IVehicleType = {
    name: "",
    vehicle_id: "",
    driver_id: "",
    unique_id: "",
    device_id: "",
    mileage: "",
    fuel_capacity: "",
    last_fuel_date: "",
    phone: "",
    color: "",
    organization_id: "",
  };

  const defaultMobileState: IMobileType = {
    device_id: "",
    fullname: "",
    email: "",
    phone: "",
    color: "",
    organization_id: "",
  };

  const { setLoader } = useContext(LoaderContext);
  const [register, setRegister] = useState<IVehicleType>(defaultVehicleState);
  const [registerMobile, setRegisterMobile] =
    useState<IMobileType>(defaultMobileState);
  const [formValidation, setFormValidation] = useState<boolean>(true);
  const [images, setImages] = useState<Image[]>([]);
  const [uploadImages, setUploadImages] = useState<string[]>([]);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);

  const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;

    if (files) {
      const newFilesArray: File[] = Array.from(files);
      setSelectedFiles([...selectedFiles, ...newFilesArray]);

      const newImages: string[] = [];
      const readers: FileReader[] = [];

      for (let i = 0; i < newFilesArray.length; i++) {
        const reader = new FileReader();
        readers.push(reader);

        reader.onload = (event) => {
          if (event.target) {
            newImages.push(event.target.result as string);
            setUploadImages([...uploadImages, ...newImages]);
          }
        };

        reader.readAsDataURL(newFilesArray[i]);
      }
    }
  };

  const handleFilterData = useCallback(
    (device_id: string) => {
      const filteredData = vehiclesInfo.filter(
        (item: IVehicleType) => item.device_id === device_id
      );
      filteredData.map((items: IVehicleType) => {
        return setRegister({
          name: items.name,
          vehicle_id: items.vehicle_id,
          driver_id: items.driver_id,
          unique_id: items.unique_id,
          device_id: items.device_id,
          mileage: items.mileage,
          fuel_capacity: items.fuel_capacity,
          last_fuel_date: items.last_fuel_date,
          phone: items.phone,
          color: items.color ? items.color : "rgb(255, 255, 255)",
          organization_id: items.organization_id,
        });
      });
    },
    [vehiclesInfo, setRegister]
  );

  const handleImageFilterData = useCallback(
    (device_id: string) => {
      setImages([]);
      const filteredData = vehiclesInfo.filter(
        (item: IVehicleType) => item.device_id === device_id
      );
      // eslint-disable-next-line array-callback-return
      filteredData.map((items: IVehicleType) => {
        const vehicleImages =
          items.images?.map((imageObject: any) => ({
            id: imageObject.id,
            image_url: `${API}/${imageObject.image_url}`,
          })) || [];
        setImages((prevImages) => [...prevImages, ...vehicleImages]);
      });
    },
    [vehiclesInfo]
  );

  const handleMobileFilterData = useCallback(
    (device_id: string) => {
      const filteredData = mobilesInfo.filter(
        (item: IMobileType) => item.device_id === device_id
      );
      filteredData.map((items: IMobileType) => {
        return setRegisterMobile({
          device_id: items.device_id,
          fullname: items.fullname,
          email: items.email,
          phone: items.phone,
          color: items.color ? items.color : "rgb(255, 255, 255)",
          organization_id: items.organization_id,
        });
      });
    },
    [mobilesInfo, setRegisterMobile]
  );

  useEffect(() => {
    if (props.data && props.check === "device") {
      handleFilterData(props.data);
      handleImageFilterData(props.data);
    } else {
      handleMobileFilterData(props.data);
    }
  }, [
    vehiclesInfo,
    props.data,
    props.check,
    handleFilterData,
    handleMobileFilterData,
    handleImageFilterData,
  ]);

  const handleDateChange = (date: Dayjs | null) => {
    if (date) {
      try {
        const convertDate = date.toDate();
        const formattedDate = format(new Date(convertDate), "yyyy-MM-dd");
        setRegister((prevRegister) => ({
          ...prevRegister,
          last_fuel_date: formattedDate,
        }));
      } catch {}
    }
  };

  const handleColorChange = (newColor: ColorInput) => {
    setRegister((prevRegister) => ({
      ...prevRegister,
      color: newColor.toString(),
    }));
  };

  const handleMobileColorChange = (newColor: ColorInput) => {
    setRegisterMobile((prevRegister) => ({
      ...prevRegister,
      color: newColor.toString(),
    }));
  };

  const changeOrganizationId = (organizationId: string) => {
    props.check === "device"
      ? setRegister((prevRegister) => ({
          ...prevRegister,
          organization_id: organizationId,
        }))
      : setRegisterMobile((prevRegister) => ({
          ...prevRegister,
          organization_id: organizationId,
        }));
  };

  const handleValidate = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const emailvalidate = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (
      props.check === "device" &&
      (!register.name ||
        !register.vehicle_id ||
        !register.driver_id ||
        !register.unique_id ||
        !register.mileage ||
        !register.fuel_capacity ||
        !register.last_fuel_date ||
        !register.phone ||
        !/^\d{10}$/.test(String(register.phone)))
    ) {
      setFormValidation(false);
    } else if (
      props.check === "mobile" &&
      (!registerMobile.device_id ||
        !registerMobile.fullname ||
        !registerMobile.email ||
        !emailvalidate.test(registerMobile.email) ||
        !registerMobile.phone ||
        !/^\d{10}$/.test(String(registerMobile.phone)))
    ) {
      setFormValidation(false);
    } else {
      if (props.check === "device") {
        handleUpdate();
      } else {
        handleMobileUpdate();
      }
      setOpen(false);
      setLoader(true);
    }
  };

  const handleUpdate = () => {
    const form = new FormData();
    form.append("name", register.name);
    form.append("vehicle_id", register.vehicle_id);
    form.append("driver_id", register.driver_id);
    form.append("unique_id", register.unique_id);
    form.append("device_id", register.device_id);
    form.append("mileage", register.mileage);
    form.append("fuel_capacity", register.fuel_capacity);
    form.append("last_fuel_date", register.last_fuel_date);
    form.append("phone", register.phone);
    form.append("color", register.color);
    form.append("organization_id", register.organization_id);
    if (selectedFiles.length > 0) {
      for (let i = 0; i < selectedFiles.length; i++) {
        form.append(`image${i}`, selectedFiles[i]);
      }
    }
    updateVehicle(props.data, form)
      .then((response) => {
        const { message, status } = response;
        if (message && status === 200) {
          const updatedInfo = vehiclesInfo.map(
            (vehicle: IVehicleType) =>
              vehicle.device_id === props.data ? message : vehicle //response
          );
          updateVehiclesInfo(updatedInfo);
          setInitialApiCall(false);
          success();
          setFormValidation(true);
          setUploadImages([]);
          setSelectedFiles([]);
        } else {
          failed();
        }
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const handleMobileUpdate = () => {
    updateMobile(props.data, registerMobile)
      .then((response) => {
        const { message, status } = response;
        if (message && status === 200) {
          const updatedInfo = mobilesInfo.map(
            (mobile: IMobileType) =>
              mobile.device_id === props.data ? registerMobile : mobile //response
          );
          setmobilesInfo(updatedInfo);
          setInitialApiCall(false);
          success();
          setFormValidation(true);
        } else {
          failed();
        }
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const handleImageDelete = (id: number) => {
    message().then((result) => {
      if (result.isConfirmed) {
        setLoader(true);
        deleteImage(id)
          .then((response) => {
            const { status } = response;
            if (status === 204) {
              const updatedVehiclesInfo = vehiclesInfo.map(
                (vehicle: IVehicleType) => {
                  if (vehicle.images && vehicle.images.length > 0) {
                    const updatedImages = vehicle.images.filter(
                      (image: Image) => image.id !== id
                    );
                    return { ...vehicle, images: updatedImages };
                  } else {
                    return vehicle;
                  }
                }
              );
              updateVehiclesInfo(updatedVehiclesInfo);
              setUploadImages([]);
              setSelectedFiles([]);
              success();
            } else {
              failed();
            }
          })
          .finally(() => {
            setLoader(false);
          });
      }
    });
  };

  const handleRegisterPopup = () => {
    setUploadImages([]);
    setSelectedFiles([]);
    handleImageFilterData(props.data);
    setOpen(false);
    setFormValidation(true);
    handleFilterData(props.data);
  };

  return (
    <>
      <EditIcon
        sx={{
          fontSize: "20px",
          color: iconColor,
          cursor: "pointer",
        }}
        onClick={() => {
          setOpen(true);
        }}
      />

      <Dialog
        open={open}
        onClose={handleRegisterPopup}
        PaperProps={{
          sx: {
            border: `2px solid ${iconColor}`,
            borderRadius: "10px",
            zIndex: "-10px",
          },
        }}
      >
        <DialogTitle
          sx={{
            backgroundColor: primaryColor,
            color: iconColor,
            display: "flex",
            justifyContent: isMobile ? "unset" : "space-between",
            flexWrap: "wrap",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            {props.check === "device" ? (
              <>
                <AirportShuttleIcon />
                <span>Edit Vehicle</span>
              </>
            ) : (
              <>
                <PhoneIphoneIcon />
                <span>Edit Mobile</span>
              </>
            )}
          </Box>
          {!formValidation ? (
            <Box
              sx={{
                color: errorMessageColor,
                fontSize: "12px",
              }}
            >
              <span>Please Fill All Required Fields Properly</span>
            </Box>
          ) : null}
          <Box width={isMobile ? "45%" : "18%"}>
            <FormControl size="small" fullWidth>
              <InputLabel sx={{ color: iconColor }}>Tenants</InputLabel>
              <SelectComponent
                className="tenants"
                value={
                  props.check === "device"
                    ? register.organization_id
                    : registerMobile.organization_id
                }
                label="Tenants"
                // onChange={handleChange}
                onChange={(e) => changeOrganizationId(e.target.value as string)}
                MenuProps={{
                  sx: {
                    "& .MuiMenuItem-root.Mui-selected": {
                      backgroundColor: selectedMenuColor,
                    },
                    "& .MuiButtonBase-root.MuiMenuItem-root:hover": {
                      backgroundColor: selectedMenuColorHover,
                    },
                    "& .MuiPaper-root.MuiPopover-paper.MuiMenu-paper::-webkit-scrollbar":
                      {
                        width: "4px",
                      },
                    "& .MuiPaper-root.MuiPopover-paper.MuiMenu-paper::-webkit-scrollbar-thumb":
                      {
                        backgroundColor: scrollbarColor,
                        borderRadius: "4px",
                      },
                    "& .MuiPaper-root.MuiPopover-paper.MuiMenu-paper::-webkit-scrollbar-track":
                      {
                        backgroundColor: lineColor,
                      },
                    "& .MuiPaper-root.MuiPopover-paper.MuiMenu-paper": {
                      scrollbarColor: `${scrollbarColor} ${lineColor}`,
                      scrollbarWidth: "thin",
                    },
                  },
                }}
              >
                {tenants.map((item: any) => {
                  return (
                    <MenuItem
                      value={item.organization.id}
                      key={item.organization.id}
                    >
                      {item.organization.ticket_id}
                    </MenuItem>
                  );
                })}
              </SelectComponent>
            </FormControl>
          </Box>
        </DialogTitle>
        {props.check === "device" ? (
          <DialogContent
            sx={{
              backgroundColor: primaryColor,
              color: textColor,
              "&::-webkit-scrollbar": {
                width: "4px",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: scrollbarColor,
                borderRadius: "4px",
              },
              "&::-webkit-scrollbar-track": {
                backgroundColor: lineColor,
              },
              scrollbarColor: `${scrollbarColor} ${lineColor}`,
              scrollbarWidth: "thin",
            }}
          >
            {formItems.map((item) => {
              const isDisabled = item.key === "unique_id" && role !== 'sa';
              return (
                <TextField
                  // autoFocus
                  // margin="dense"
                  label={item.name}
                  type={item.type}
                  disabled={isDisabled}
                  fullWidth
                  variant="standard"
                  key={item.key}
                  value={register[item.key as keyof IVehicleType]}
                  required
                  onChange={(e) => {
                    setRegister((prevRegister) => ({
                      ...prevRegister,
                      [item.key]: e.target.value,
                    }));
                  }}
                  sx={{
                    margin: "4px",
                    "& label.Mui-focused": {
                      color: iconColor,
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: iconColor,
                    },
                    "& .MuiInputBase-root.MuiInput-root": {
                      color: textColor,
                    },
                    "& .MuiFormLabel-root.MuiInputLabel-root": {
                      color: lineColor,
                    },
                    "& .MuiInputBase-root.MuiInput-root:before": {
                      borderBottom: `1px solid gray`,
                    },
                    "& .MuiInputBase-input.Mui-disabled": {
                      // Styles for disabled input
                      opacity: 0.5,
                      WebkitTextFillColor: textColor,
                      // color: 'red', // Modify color as needed
                    },
                  }}
                />
              );
            })}
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DatePicker"]}>
                <DatePicker
                  label="Last Fuel Date *"
                  // autoFocus
                  value={dayjs(new Date(register.last_fuel_date))}
                  format="YYYY-MM-DD"
                  sx={{
                    margin: "4px",
                    width: "100%",
                    "& .MuiFormLabel-root.MuiInputLabel-root, .MuiSvgIcon-root":
                      {
                        color: iconColor,
                      },
                    // ".css-1jy569b-MuiFormLabel-root-MuiInputLabel-root":{
                    //   color: iconColor,
                    // },
                    "& .MuiInputBase-input.MuiOutlinedInput-input": {
                      color: textColor,
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: textColor,
                    },
                    "& .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: lineColor,
                      },
                  }}
                  onChange={(date: Dayjs | null) => handleDateChange(date)}
                />
              </DemoContainer>
            </LocalizationProvider>
            <MuiColorInput
              value={register.color}
              label={"Choose Color"}
              onChange={handleColorChange}
              sx={{
                width: "100%",
                marginTop: "10px",
                "& .MuiFormLabel-root.MuiInputLabel-root": {
                  color: iconColor,
                },
                "& .MuiInputBase-root.MuiOutlinedInput-root": {
                  color: textColor,
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: textColor,
                },
                "& .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    borderColor: lineColor,
                  },
              }}
            />
            <Box marginTop="10px">
              <label htmlFor="upload-button">
                <Button
                  component="label"
                  role={undefined}
                  variant="contained"
                  startIcon={<CloudUploadIcon />}
                >
                  Upload Image
                  <input
                    id="upload-button"
                    type="file"
                    onChange={handleImageChange}
                    accept="image/*"
                    style={{ display: "none" }}
                  />
                </Button>
              </label>
              <Box
                display="flex"
                flexDirection="row"
                flexWrap="wrap"
                marginTop="10px"
              >
                {uploadImages.map((image: string, index: number) => (
                  <Box key={index} marginRight="10px">
                    <img
                      src={image}
                      alt={`Preview ${index + 1}`}
                      style={{
                        maxWidth: "100px",
                        maxHeight: "100px",
                        marginRight: "5px",
                        marginBottom: "5px",
                      }}
                    />
                  </Box>
                ))}
              </Box>
              <Box
                display="flex"
                flexDirection="row"
                flexWrap="wrap"
                marginTop="10px"
              >
                {images.map((image: Image, index: number) => (
                  <Box key={index} marginRight="10px" position="relative">
                    <DeleteIcon
                      style={{
                        position: "absolute",
                        top: "0px",
                        color: iconColor,
                        cursor: "pointer",
                      }}
                      onClick={() => handleImageDelete(image.id)}
                    />
                    <img
                      src={image.image_url}
                      alt={`Preview ${index + 1}`}
                      style={{
                        maxWidth: "100px",
                        maxHeight: "100px",
                        marginRight: "5px",
                        marginBottom: "5px",
                      }}
                    />
                  </Box>
                ))}
              </Box>
            </Box>
          </DialogContent>
        ) : (
          <DialogContent
            sx={{
              backgroundColor: primaryColor,
              color: textColor,
              "&.MuiDialogContent-root": {
                overflowY: "unset",
              },
            }}
          >
            {mobileFormItems.map((item: IformItems) => {
              return (
                <TextField
                  // autoFocus
                  // margin="dense"
                  label={item.name}
                  type={item.type}
                  fullWidth
                  variant="standard"
                  key={item.key}
                  value={registerMobile[item.key as keyof IMobileType]}
                  required
                  onChange={(e) => {
                    setRegisterMobile((prevMobileRegister) => ({
                      ...prevMobileRegister,
                      [item.key]: e.target.value,
                    }));
                  }}
                  sx={{
                    margin: "4px",
                    "& label.Mui-focused": {
                      color: iconColor,
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: iconColor,
                    },
                    "& .MuiInputBase-root.MuiInput-root": {
                      color: textColor,
                    },
                    "& .MuiFormLabel-root.MuiInputLabel-root": {
                      color: lineColor,
                    },
                    "& .MuiInputBase-root.MuiInput-root:before": {
                      borderBottom: `1px solid gray`,
                    },
                  }}
                />
              );
            })}
            <MuiColorInput
              value={registerMobile.color}
              label={"Choose Color"}
              onChange={handleMobileColorChange}
              sx={{
                width: "100%",
                marginTop: "10px",
                "& .MuiFormLabel-root.MuiInputLabel-root": {
                  color: iconColor,
                },
                "& .MuiInputBase-root.MuiOutlinedInput-root": {
                  color: textColor,
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: textColor,
                },
                "& .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    borderColor: lineColor,
                  },
              }}
            />
          </DialogContent>
        )}
        <DialogActions
          sx={{
            backgroundColor: primaryColor,
            color: textColor,
            // display: 'flex',
            // justifyContent: 'space-between',
          }}
        >
          {/* {
              !formValidation?
              <Box
                sx={{
                  color: errorMessageColor,
                  fontSize:'12px',
                  paddingLeft:'4%'
                }}
              >
                <p>Please Fill All Required Fields</p>
              </Box>
              :
              <Box>
  
              </Box>
            }   */}
          {/* <Box> */}
          <Button
            onClick={handleRegisterPopup}
            sx={{
              color: iconColor,
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={(e) => {
              handleValidate(e);
            }}
            sx={{
              color: iconColor,
            }}
          >
            Save
          </Button>
          {/* </Box> */}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EditVehicle;
