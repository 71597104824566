import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import { Box, Checkbox, Typography, useMediaQuery } from "@mui/material";
// import AirportShuttleIcon from '@mui/icons-material/AirportShuttle';
import {
  iconColor,
  textColor,
} from "../constants/color-constants";
import {
  DisplayCoordinatesContext,
  InfoDataContext,
  // LoaderContext,
  MobileCoordinatesContext,
  MobilesDataContext,
  SidebarOpenContext,
  VehicleDataContext,
  VehicleDataPopupContext,
  initialApiCallContext,
} from "../App";
import {
  IMobileCoordinates,
  IMobileType,
  // deleteMobileRegister,
} from "../api/Api";
// import DeleteIcon from "@mui/icons-material/Delete";
// import EditVehicle from "./EditVehicle";
// import { failed, message, success } from "../hooks/useToast";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";

const MobileDevice = (props: {
  data: IMobileType;
  setSelected: React.Dispatch<React.SetStateAction<string | null>>;
  selected: string | null;
}) => {
  const isMobile = useMediaQuery("(max-width:600px)");
  const { setSidebarOpen } = useContext(SidebarOpenContext);
  const { setVehicleData } = useContext(VehicleDataContext);
  // const { vehiclesInfo, updateVehiclesInfo } = useContext(VehiclesDataContext)
  const { setInfoData } = useContext(InfoDataContext);
  const { mobilesInfo } = useContext(MobilesDataContext);
  const { mobilecoordinates } = useContext(MobileCoordinatesContext);
  // const { setLoader } = useContext(LoaderContext);
  const { displayCoordinates, setDisplayCoordinates } = useContext(
    DisplayCoordinatesContext
  );
  const { setInitialApiCall } = useContext(initialApiCallContext);
  const { setVehicleDataPopup } = useContext(VehicleDataPopupContext);
  const [online, setOnline] = useState<boolean>();

  const filteredCoordinateData = mobilecoordinates.filter(
    (item: IMobileCoordinates) => item.device_id === props.data.device_id
  );

  const filteredData = mobilesInfo.filter(
    (item: IMobileType) => item.device_id === props.data.device_id
  );
  const foundData = filteredCoordinateData.length
    ? filteredCoordinateData
    : filteredData;

  const formatToDaysHrMin = () => {
    const createdAtDateString = filteredCoordinateData[0]?.created_at;

    if (!createdAtDateString) {
      return;
    }

    const createdAtDate: Date = new Date(createdAtDateString);

    const createMonthName = createdAtDate.toLocaleString("en-US", {
      month: "short",
    });

    const createHours = String(createdAtDate.getHours()).padStart(2, "0");
    const createMinutes = String(createdAtDate.getMinutes()).padStart(2, "0");
    // Get hours and minutes in 12-hour format with AM/PM
    // const createHours = createdAtDate.getHours() % 12 || 12; // Convert 0 to 12
    // const createMinutes = createdAtDate.getMinutes();
    // const createAmOrPm = createdAtDate.getHours() < 12 ? "AM" : "PM";

    const formattedDate = `${createMonthName} ${createdAtDate.getDate()} (${createHours}:${createMinutes})`;

    const currentDate: Date = new Date();

    const todayStartString = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate(),
      0,
      0,
      0
    ).toISOString();

    // Convert todayStartString back to Date object
    const todayStart: Date = new Date(todayStartString);

    const todayEndString = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate(),
      23,
      59,
      59
    ).toISOString();

    const todayEnd: Date = new Date(todayEndString);
    const timeDifference: number =
      currentDate.getTime() - createdAtDate.getTime();
    const days = Math.floor(timeDifference / (24 * 60 * 60 * 1000));
    const hours = Math.floor(
      (timeDifference % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000)
    );
    const minutes = Math.floor(
      (timeDifference % (60 * 60 * 1000)) / (60 * 1000)
    );
    if (createdAtDate >= todayStart && createdAtDate <= todayEnd) {
      if (days > 0) {
        return `${days} day${days > 1 ? "s" : ""} ago`;
      } else if (hours > 0) {
        return `${hours} hour${hours > 1 ? "s" : ""} ago`;
      } else if (minutes > 0) {
        return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
      } else {
        return "a few seconds ago";
      }
    } else {
      return <span>{`Last: ${formattedDate}`}</span>;
    }
  };

  useEffect(() => {
    if (filteredCoordinateData[0]?.tripsStatus === "1") {
      setOnline(true);
    } else {
      setOnline(false);
    }
  }, [filteredCoordinateData]);

  // const [editid, setEditid] = useState("");
  const checkdisplayCoordinates = displayCoordinates.includes(
    props.data.device_id
  );

  const handleCheckboxChange =
    (device_id: string) => (event: ChangeEvent<HTMLInputElement>) => {
      const { checked } = event.target;
      if (checked) {
        setDisplayCoordinates((prev: string[]) => [...prev, device_id]);
        setVehicleData(undefined);
        props.setSelected(null);
        setInitialApiCall(false);
      } else {
        setDisplayCoordinates((prev: string[]) =>
          prev.filter((item: string) => item !== device_id)
        );
        setVehicleData(undefined);
        props.setSelected(null);
        setInitialApiCall(false);
      }
      isMobile && setSidebarOpen(false);
    };

  useEffect(() => {
    localStorage.setItem("sgs:checkedId", JSON.stringify(displayCoordinates));
  }, [displayCoordinates]);

  // const handleEdit = (device_id: string) => {
  //   setEditid(device_id);
  // };

  // const handleDelete = (device_id: string) => {
  //   message().then((result) => {
  //     if (result.isConfirmed) {
  //       setLoader(true);
  //       deleteMobileRegister(device_id)
  //         .then((response) => {
  //           const { status } = response;
  //           if (status === 204) {
  //             const updatedInfo = mobilesInfo.filter(
  //               (mobile: IMobileType) => mobile.device_id !== device_id
  //             );
  //             setmobilesInfo(updatedInfo);
  //             setInitialApiCall(false);
  //             success();
  //           } else {
  //             failed();
  //           }
  //         })
  //         .finally(() => {
  //           setLoader(false);
  //         });
  //     }
  //   });
  // };

  const handleSelected = (device_id: string) => {
    setInfoData(foundData);
    setVehicleDataPopup("mobile");
    // if (checkdisplayCoordinates) {
    setDisplayCoordinates((prev: string[]) => [...prev, device_id]);
    setVehicleData(props.selected === device_id ? undefined : foundData);
    props.setSelected(props.selected === device_id ? null : device_id);
    // }
    if (props.selected === device_id) {
      setInitialApiCall(false);
    }
    if (isMobile) {
      setSidebarOpen(false);
    }
  };

  return (
    <>
      <Box
        // onClick={() => setVehicleData(props.data)}
        display="flex"
        justifyContent="space-between"
        p="10px 15px 10px 2px"
        gap="4px"
        alignItems="center"
        sx={{
          cursor: "pointer",
          // position: 'relative',
          "&:hover": {
            backgroundColor: "rgb(197,198,199,0.3)",
            ".name, .deviceId": {
              color: 'rgb(255,255,255)',
            },
            ".onlineTime": {
              color: online ? "rgb(0,128,0)" : 'rgb(255,255,255)',
            },
          },
          backgroundColor:
            props.selected === props.data.device_id && checkdisplayCoordinates
              ? "rgb(197,198,199,0.3)"
              : "unset",
        }}
      >
        <Checkbox
          checked={checkdisplayCoordinates}
          sx={{
            fontSize: "2px",
            // position:"absolute",
            // left:"0",
            // top:"15px",
            // transform:"translateY(-50%)",
            // opacity: '0.5',
            color: iconColor,
            "&.Mui-checked": {
              color: iconColor,
            },
          }}
          onChange={handleCheckboxChange(props.data.device_id)}
        />
        <Box
          display="flex"
          width="95vw"
          gap="10px"
          onClick={() => handleSelected(props.data.device_id)}
        >
          <Box
            className="icon"
            bgcolor={online ? "rgb(0,128,0)" : "rgb(255,255,255,0.15)"}
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{
              width: "42px",
              height: "42px",
              borderRadius: "21px",
            }}
          >
            <PhoneIphoneIcon
              sx={{
                color: props.data.color,
                height: "25px",
                width: "25px",
              }}
            />
          </Box>

          <Box
            className="details"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            flex="1"
            color={textColor}
          >
            <Box display="flex" flexDirection="column">
              <Typography
                className="name"
                fontSize="17px"
                width={isMobile ? "100px" : "100px"}
                noWrap
                sx={{
                  color:
                    props.selected === props.data.device_id &&
                    checkdisplayCoordinates
                      ? 'rgb(255,255,255)'
                      : "unset",
                }}
              >
                {props.data.fullname}
              </Typography>
              <Typography
                className="onlineTime"
                fontSize="10px"
                width={isMobile ? "140px" : "100px"}
                noWrap
                // color={online ? "rgb(0,128,0)" : "unset"}
                sx={{
                  color: online
                    ? "rgb(0,128,0)"
                    : props.selected === props.data.device_id &&
                      checkdisplayCoordinates
                    ? 'rgb(255,255,255)'
                    : "unset",
                }}
              >
                {online ? "online" : formatToDaysHrMin()}
              </Typography>
            </Box>
            <Typography
              className="deviceId"
              width={isMobile ? "90px" : "80px"}
              noWrap
              sx={{
                fontSize: "13px",
                color:
                  props.selected === props.data.device_id &&
                  checkdisplayCoordinates
                    ? 'rgb(255,255,255)'
                    : textColor,
              }}
            >
              {props.data.device_id}
            </Typography>
          </Box>
        </Box>
        {/* <Box display="flex" alignItems="center" gap="5px" color={iconColor}>
          <Box onClick={() => handleEdit(props.data.device_id)}>
            <EditVehicle data={editid} check="mobile" />
          </Box>
          <Icon
            component={DeleteIcon}
            onClick={() => handleDelete(props.data.device_id)}
          />
        </Box> */}
      </Box>
    </>
  );
};

export default MobileDevice;
