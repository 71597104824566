import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Box,
  Button,
  DialogActions,
  Icon,
  Modal,
  Select,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import TripTable from "./TripTable";
import { styled } from "@mui/system";
import {
  iconColor,
  primaryColor,
  textColor,
  lineColor,
} from "../constants/color-constants";
import StopTable from "./StopTable";
import { GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import DescriptionIcon from "@mui/icons-material/Description";
import {
  FilterContext,
  LoaderContext,
  MapColorContext,
  PathFilterContext,
  ReportExportContext,
  ShowFilterContext,
  SidebarOpenContext,
  VehicleCoordinatesContext,
  initialApiCallContext,
} from "../App";
// import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { format } from "date-fns";
// import { IVehicleType } from '../api/Api';
import TripMap from "./TripMap";
import CloseIcon from "@mui/icons-material/Close";
import StopMap from "./StopMap";
import MobileTripTable from "./MobileTripTable";
import MobileTripMap from "./MobileTripMap";
import ToggleMap from "./ToggleMap";
import SummaryTable from "./SummaryTable";
import { gettripsPoint } from "../api/Api";
import { failed } from "../hooks/useToast";
// import dayjs, { Dayjs } from 'dayjs';

export const SelectComponent = styled(Select)({
  color: textColor,
  ".MuiOutlinedInput-notchedOutline": {
    borderColor: iconColor,
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: iconColor,
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: iconColor,
  },
  ".MuiSvgIcon-root ": {
    fill: `${textColor} !important`,
  },
});

const Report = () => {
  const isMobile = useMediaQuery("(max-width:600px)");
  const isMobileHeight = useMediaQuery("(max-height:800px)");

  const { report, table, device, fromDate, toDate, columns, period } =
    useContext(FilterContext);
  const { setMapColor } = useContext(MapColorContext);
  const { pathFilter, setPathFilter } = useContext(PathFilterContext);
  const { setLoader } = useContext(LoaderContext);
  const { setTripspointCoordinates } = useContext(VehicleCoordinatesContext);

  const [tripCoordinates, setTripCoordinates] = useState<number[]>([]);
  const [stopCoordinates, setStopCoordinates] = useState<number[]>([]);
  const [mobileTripCoordinates, setMobileTripCoordinates] = useState<number[]>(
    []
  );

  const dateFormat = "yyy-MM-dd";
  const timeFormat = "HH:mm";

  const formatDateAndTime = (value: Date) => {
    const formattedDate = format(new Date(value), dateFormat);
    const formattedTime = format(new Date(value), timeFormat);
    return `${formattedDate} (${formattedTime})`;
  };

  const formatToHrMin = (duration: number) => {
    const minutes = Math.floor(duration / 60000);
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;

    if (hours > 0) {
      return `${hours} hour(s) ${remainingMinutes} minute(s)`;
    } else {
      return `${remainingMinutes} minute(s)`;
    }
  };

  const calculateDistance = (distance: number) => {
    const totalDistance = (distance / 1000).toFixed(2);
    return `${totalDistance} km`;
  };

  const calculateMobileDistance = (distance: number) => {
    if (distance !== null) {
      const totalDistance = distance.toFixed(3);
      return `${totalDistance} km`;
    }
  };

  const calculateSpeed = (speed: number) => {
    if (speed !== null) {
      const totalSpeed = speed.toFixed(2);
      return `${totalSpeed} km/h`;
    }
  };

  const checkIsValue = (params: GridValueGetterParams) => {
    const addressValue = params.value || "-";
    return addressValue;
  };

  const convertCoordinates = (coordinates: number) => {
    return `${coordinates.toFixed(6)}`;
  };

  const setCoordinatesPoint = useCallback(
    (start_position_id: number, end_position_id: number, device_id: number) => {
      setLoader(true);
      gettripsPoint(start_position_id, end_position_id, device_id)
        .then((data) => {
          if (data.error) {
            failed();
          } else {
            setTripspointCoordinates(data);
          }
        })
        .finally(() => {
          setLoader(false);
        });
    },
    [setTripspointCoordinates, setLoader] 
  );

  const renderTripActionButton = useCallback(
    (
      roadSnapValue: number[],
      color: string,
      start_position_id: number,
      end_position_id: number,
      device_id: number
    ): React.ReactNode => {
      return roadSnapValue.length > 2 ? (
        <DialogActions
          sx={{
            color: textColor,
          }}
        >
          <Button
            onClick={() => {
              setCoordinatesPoint(
                start_position_id,
                end_position_id,
                device_id
              );
              setTripCoordinates(roadSnapValue);
              setOpenMap(true);
              setMapColor(color);
            }}
            sx={{
              color: iconColor,
            }}
          >
            View on Map
          </Button>
        </DialogActions>
      ) : (
        <span>Unavailable</span>
      );
    },
    [setTripCoordinates, setMapColor, setCoordinatesPoint]
  );

  const renderMobileTripActionButton = useCallback(
    (roadSnapValue: number[], color: string): React.ReactNode => {
      return roadSnapValue && roadSnapValue.length ? (
        <DialogActions
          sx={{
            color: textColor,
          }}
        >
          <Button
            onClick={() => {
              setMobileTripCoordinates(roadSnapValue);
              setOpenMap(true);
              setMapColor(color);
            }}
            sx={{
              color: iconColor,
            }}
          >
            View on Map
          </Button>
        </DialogActions>
      ) : (
        <span>Unavailable</span>
      );
    },
    [setMobileTripCoordinates, setMapColor]
  );

  const renderStopActionButton = useCallback(
    (longitude: number, latitude: number, color: string): React.ReactNode => {
      return longitude && latitude ? (
        <DialogActions
          sx={{
            color: textColor,
          }}
        >
          <Button
            onClick={() => {
              setStopCoordinates([longitude, latitude]);
              setOpenMap(true);
              setMapColor(color);
            }}
            sx={{
              color: iconColor,
            }}
          >
            View on Map
          </Button>
        </DialogActions>
      ) : (
        <span>Unavailable</span>
      );
    },
    [setStopCoordinates, setMapColor]
  );

  const [openMap, setOpenMap] = useState(false);

  const tripColumns: GridColDef[] = useMemo(
    () => [
      {
        field: "device_name",
        headerName: "Vehicle Name",
        headerAlign: "center",
        editable: false,
        sortable: false,
        align: "center",
        flex: isMobile ? 0 : 1,
        width: isMobile ? 150 : 0,
      },
      {
        field: "vehicle_id",
        headerName: "Vehicle ID",
        headerAlign: "center",
        editable: false,
        sortable: false,
        align: "center",
        flex: isMobile ? 0 : 1,
        width: isMobile ? 150 : 0,
      },
      {
        field: "driver_id",
        headerName: "Driver ID",
        sortable: false,
        headerAlign: "center",
        editable: false,
        align: "center",
        flex: isMobile ? 0 : 1,
        width: isMobile ? 150 : 0,
      },
      {
        field: "device_id",
        headerName: "Device ID",
        sortable: false,
        headerAlign: "center",
        editable: false,
        align: "center",
        flex: isMobile ? 0 : 1,
        width: isMobile ? 150 : 0,
      },
      // {
      //     field: 'mileage',
      //     headerName: 'Mileage (km)',
      //     sortable: false,
      //     headerAlign: 'center',
      //     editable: false,
      //     align: 'center',
      //     flex: isMobile ? 0 : 1,
      //     width: isMobile ? 150 : 0
      // },
      // {
      //     field: 'capacity',
      //     headerName: 'Fuel Capacity (L)',
      //     sortable: false,
      //     headerAlign: 'center',
      //     editable: false,
      //     align: 'center',
      //     flex: isMobile ? 0 : 1,
      //     width: isMobile ? 150 : 0
      // },
      {
        field: "start_address",
        headerName: "Start Address",
        sortable: false,
        headerAlign: "center",
        editable: false,
        align: "center",
        flex: isMobile ? 0 : 1,
        width: isMobile ? 150 : 0,
        valueGetter: checkIsValue,
      },
      {
        field: "end_address",
        headerName: "End Address",
        sortable: false,
        headerAlign: "center",
        editable: false,
        align: "center",
        flex: isMobile ? 0 : 1,
        width: isMobile ? 150 : 0,
        valueGetter: checkIsValue,
      },
      {
        field: "start_time",
        headerName: "Start Time",
        sortable: false,
        headerAlign: "center",
        editable: false,
        align: "center",
        flex: isMobile ? 0 : 1,
        width: isMobile ? 150 : 0,
        valueGetter: (params) => formatDateAndTime(params.value as Date),
      },
      {
        field: "end_time",
        headerName: "End Time",
        sortable: false,
        headerAlign: "center",
        editable: false,
        align: "center",
        flex: isMobile ? 0 : 1,
        width: isMobile ? 150 : 0,
        valueGetter: (params) => formatDateAndTime(params.value as Date),
      },
      {
        field: "duration",
        headerName: "Duration",
        sortable: false,
        headerAlign: "center",
        editable: false,
        align: "center",
        flex: isMobile ? 0 : 1,
        width: isMobile ? 150 : 0,
        valueGetter: (params) => formatToHrMin(params.value as number),
      },
      {
        field: "distance",
        headerName: "Distance (km)",
        sortable: false,
        headerAlign: "center",
        editable: false,
        align: "center",
        flex: isMobile ? 0 : 1,
        width: isMobile ? 150 : 0,
        valueGetter: (params) => calculateDistance(params.value as number),
      },
      {
        field: "max_speed",
        headerName: "Max. Speed (km/h)",
        sortable: false,
        headerAlign: "center",
        editable: false,
        align: "center",
        flex: isMobile ? 0 : 1,
        width: isMobile ? 150 : 0,
        valueGetter: (params) => calculateSpeed(params.value as number),
      },
      {
        field: "average_speed",
        headerName: "Avg. Speed (km/h)",
        sortable: false,
        headerAlign: "center",
        editable: false,
        align: "center",
        flex: isMobile ? 0 : 1,
        width: isMobile ? 150 : 0,
        valueGetter: (params) => calculateSpeed(params.value as number),
      },
      // {
      //     field: "spent_fuel",
      //     headerName: "Spent Fuel",
      //     sortable: false,
      //     headerAlign: 'center',
      //     editable: false,
      //     align: 'center',
      //     flex: isMobile ? 0 : 1,
      //     width: isMobile ? 150 : 0,
      //     valueGetter: checkIsValue,
      // },
      {
        field: "start_odometer",
        headerName: "Start Odometer (km)",
        sortable: false,
        headerAlign: "center",
        editable: false,
        align: "center",
        flex: isMobile ? 0 : 1,
        width: isMobile ? 150 : 0,
        valueGetter: (params) => calculateDistance(params.value as number),
      },
      {
        field: "end_odometer",
        headerName: "End Odometer (km)",
        sortable: false,
        headerAlign: "center",
        editable: false,
        align: "center",
        flex: isMobile ? 0 : 1,
        width: isMobile ? 150 : 0,
        valueGetter: (params) => calculateDistance(params.value as number),
      },
      {
        field: "road_snap",
        headerName: "Action",
        sortable: false,
        headerAlign: "center",
        editable: false,
        align: "center",
        flex: isMobile ? 0 : 1,
        width: isMobile ? 150 : 0,
        renderCell: (params) =>
          renderTripActionButton(
            params.value as number[],
            params.row.color,
            params.row.start_position_id,
            params.row.end_position_id,
            params.row.device_id,
          ),
      },
    ],
    [isMobile, renderTripActionButton]
  );

  const mobileTripColumns: GridColDef[] = useMemo(
    () => [
      {
        field: "fullname",
        headerName: "Full Name",
        headerAlign: "center",
        editable: false,
        sortable: false,
        align: "center",
        flex: isMobile ? 0 : 1,
        width: isMobile ? 150 : 0,
      },
      {
        field: "start_location",
        headerName: "Start Address",
        headerAlign: "center",
        editable: false,
        sortable: false,
        align: "center",
        flex: isMobile ? 0 : 1,
        width: isMobile ? 150 : 0,
        valueGetter: checkIsValue,
      },
      {
        field: "end_location",
        headerName: "End Address",
        sortable: false,
        headerAlign: "center",
        editable: false,
        align: "center",
        flex: isMobile ? 0 : 1,
        width: isMobile ? 150 : 0,
      },
      {
        field: "start_time",
        headerName: "Start Time",
        sortable: false,
        headerAlign: "center",
        editable: false,
        align: "center",
        flex: isMobile ? 0 : 1,
        width: isMobile ? 150 : 0,
        valueGetter: (params) => formatDateAndTime(params.value as Date),
      },
      {
        field: "end_time",
        headerName: "End Time",
        sortable: false,
        headerAlign: "center",
        editable: false,
        align: "center",
        flex: isMobile ? 0 : 1,
        width: isMobile ? 150 : 0,
        valueGetter: (params) => formatDateAndTime(params.value as Date),
      },
      {
        field: "distance",
        headerName: "Distance (km)",
        sortable: false,
        headerAlign: "center",
        editable: false,
        align: "center",
        flex: isMobile ? 0 : 1,
        width: isMobile ? 150 : 0,
        valueGetter: (params) =>
          calculateMobileDistance(params.value as number),
      },
      {
        field: "duration",
        headerName: "Duration",
        sortable: false,
        headerAlign: "center",
        editable: false,
        align: "center",
        flex: isMobile ? 0 : 1,
        width: isMobile ? 150 : 0,
        valueGetter: (params) => formatToHrMin(params.value as number),
      },
      {
        field: "max_speed",
        headerName: "Max. Speed(km/h)",
        sortable: false,
        headerAlign: "center",
        editable: false,
        align: "center",
        flex: isMobile ? 0 : 1,
        width: isMobile ? 150 : 0,
        valueGetter: (params) => calculateSpeed(params.value as number),
      },
      {
        field: "average_speed",
        headerName: "Avg. Speed (km/h)",
        sortable: false,
        headerAlign: "center",
        editable: false,
        align: "center",
        flex: isMobile ? 0 : 1,
        width: isMobile ? 150 : 0,
        valueGetter: (params) => calculateSpeed(params.value as number),
      },
      {
        field: "road_snap",
        headerName: "Action",
        sortable: false,
        headerAlign: "center",
        editable: false,
        align: "center",
        flex: isMobile ? 0 : 1,
        width: isMobile ? 150 : 0,
        renderCell: (params) =>
          renderMobileTripActionButton(
            params.value as number[],
            params.row.color
          ),
      },
    ],
    [isMobile, renderMobileTripActionButton]
  );

  const [tripColumnsDisplay, setTripColumnsDisplay] = useState<GridColDef[]>(
    []
  );

  useEffect(() => {
    const displayColumns: GridColDef[] = [];
    report === "vehicle"
      ? tripColumns.map(
          (column) =>
            columns.includes(column.field) && displayColumns.push(column)
        )
      : mobileTripColumns.map(
          (column) =>
            columns.includes(column.field) && displayColumns.push(column)
        );
    setTripColumnsDisplay(displayColumns);
  }, [columns, tripColumns, report, mobileTripColumns]);

  const stopColumns: GridColDef[] = useMemo(
    () => [
      {
        field: "device_name",
        headerName: "Vehicle Name",
        headerAlign: "center",
        editable: false,
        sortable: false,
        align: "center",
        flex: isMobile ? 0 : 1,
        width: isMobile ? 150 : 0,
      },
      {
        field: "vehicle_id",
        headerName: "Vehicle ID",
        headerAlign: "center",
        editable: false,
        sortable: false,
        align: "center",
        flex: isMobile ? 0 : 1,
        width: isMobile ? 150 : 0,
      },
      {
        field: "driver_id",
        headerName: "Driver ID",
        sortable: false,
        headerAlign: "center",
        editable: false,
        align: "center",
        flex: isMobile ? 0 : 1,
        width: isMobile ? 150 : 0,
      },
      {
        field: "device_id",
        headerName: "Device ID",
        sortable: false,
        headerAlign: "center",
        editable: false,
        align: "center",
        flex: isMobile ? 0 : 1,
        width: isMobile ? 150 : 0,
      },
      // {
      //     field: 'mileage',
      //     headerName: 'Mileage (km)',
      //     sortable: false,
      //     headerAlign: 'center',
      //     editable: false,
      //     align: 'center',
      //     flex: isMobile ? 0 : 1,
      //     width: isMobile ? 150 : 0
      // },
      // {
      //     field: 'capacity',
      //     headerName: 'Fuel Capacity (L)',
      //     sortable: false,
      //     headerAlign: 'center',
      //     editable: false,
      //     align: 'center',
      //     flex: isMobile ? 0 : 1,
      //     width: isMobile ? 150 : 0
      // },
      {
        field: "address",
        headerName: "Address",
        sortable: false,
        headerAlign: "center",
        editable: false,
        align: "center",
        flex: isMobile ? 0 : 1,
        width: isMobile ? 150 : 0,
        valueGetter: checkIsValue,
      },
      // {
      //     field: 'position_id',
      //     headerName: 'Position ID',
      //     sortable: false,
      //     headerAlign: 'center',
      //     editable: false,
      //     align: 'center',
      //     flex: isMobile ? 0 : 1,
      //     width: isMobile ? 150 : 0
      // },
      {
        field: "start_time",
        headerName: "Start Time",
        sortable: false,
        headerAlign: "center",
        editable: false,
        align: "center",
        flex: isMobile ? 0 : 1,
        width: isMobile ? 150 : 0,
        valueGetter: (params) => formatDateAndTime(params.value as Date),
      },
      {
        field: "end_time",
        headerName: "End Time",
        sortable: false,
        headerAlign: "center",
        editable: false,
        align: "center",
        flex: isMobile ? 0 : 1,
        width: isMobile ? 150 : 0,
        valueGetter: (params) => formatDateAndTime(params.value as Date),
      },
      {
        field: "duration",
        headerName: "Duration",
        sortable: false,
        headerAlign: "center",
        editable: false,
        align: "center",
        flex: isMobile ? 0 : 1,
        width: isMobile ? 150 : 0,
        valueGetter: (params) => formatToHrMin(params.value as number),
      },
      {
        field: "engine_hours",
        headerName: "Engine Hours",
        sortable: false,
        headerAlign: "center",
        editable: false,
        align: "center",
        flex: isMobile ? 0 : 1,
        width: isMobile ? 150 : 0,
        valueGetter: (params) => formatToHrMin(params.value as number),
      },
      {
        field: "longitude",
        headerName: "Longitude",
        sortable: false,
        headerAlign: "center",
        editable: false,
        align: "center",
        flex: isMobile ? 0 : 1,
        width: isMobile ? 150 : 0,
        valueGetter: (params) => convertCoordinates(params.value as number),
      },
      {
        field: "latitde",
        headerName: "Latitude",
        sortable: false,
        headerAlign: "center",
        editable: false,
        align: "center",
        flex: isMobile ? 0 : 1,
        width: isMobile ? 150 : 0,
        valueGetter: (params) => convertCoordinates(params.value as number),
      },
      // {
      //     field: "distance",
      //     headerName: "Distance (km)",
      //     sortable: false,
      //     headerAlign: 'center',
      //     editable: false,
      //     align: 'center',
      //     flex: isMobile ? 0 : 1,
      //     width: isMobile ? 150 : 0,
      //     valueGetter: checkIsValue,
      // },
      // {
      //     field: "max_speed",
      //     headerName: "Max. Speed (km/h)",
      //     sortable: false,
      //     headerAlign: 'center',
      //     editable: false,
      //     align: 'center',
      //     flex: isMobile ? 0 : 1,
      //     width: isMobile ? 150 : 0,
      //     valueGetter: checkIsValue,
      // },
      // {
      //     field: "average_speed",
      //     headerName: "Avg. Speed (km/h)",
      //     sortable: false,
      //     headerAlign: 'center',
      //     editable: false,
      //     align: 'center',
      //     flex: isMobile ? 0 : 1,
      //     width: isMobile ? 150 : 0,
      //     valueGetter: checkIsValue,
      // },
      // {
      //     field: "spent_fuel",
      //     headerName: "Spent Fuel",
      //     sortable: false,
      //     headerAlign: 'center',
      //     editable: false,
      //     align: 'center',
      //     flex: isMobile ? 0 : 1,
      //     width: isMobile ? 150 : 0,
      //     valueGetter: checkIsValue,
      // },
      {
        field: "start_odometer",
        headerName: "Start Odometer (km)",
        sortable: false,
        headerAlign: "center",
        editable: false,
        align: "center",
        flex: isMobile ? 0 : 1,
        width: isMobile ? 150 : 0,
        valueGetter: (params) => calculateDistance(params.value as number),
      },
      {
        field: "end_odometer",
        headerName: "End Odometer (km)",
        sortable: false,
        headerAlign: "center",
        editable: false,
        align: "center",
        flex: isMobile ? 0 : 1,
        width: isMobile ? 150 : 0,
        valueGetter: (params) => calculateDistance(params.value as number),
      },
      {
        field: "action",
        headerName: "Action",
        sortable: false,
        headerAlign: "center",
        editable: false,
        align: "center",
        flex: isMobile ? 0 : 1,
        width: isMobile ? 150 : 0,
        renderCell: (params) =>
          renderStopActionButton(
            params.row.longitude,
            params.row.latitde,
            params.row.color
          ),
      },
    ],
    [isMobile, renderStopActionButton]
  );

  const [stopColumnsDisplay, setStopColumnsDisplay] = useState<GridColDef[]>(
    []
  );

  useEffect(() => {
    const displayColumns: GridColDef[] = [];
    stopColumns.map(
      (column) => columns.includes(column.field) && displayColumns.push(column)
    );
    setStopColumnsDisplay(displayColumns);
  }, [columns, stopColumns]);

  const summaryColumns: GridColDef[] = useMemo(
    () => [
      {
        field: "device_name",
        headerName: "Vehicle Name",
        headerAlign: "center",
        editable: false,
        sortable: false,
        align: "center",
        flex: isMobile ? 0 : 1,
        width: isMobile ? 150 : 0,
      },
      {
        field: "distance",
        headerName: "Distance (km)",
        sortable: false,
        headerAlign: "center",
        editable: false,
        align: "center",
        flex: isMobile ? 0 : 1,
        width: isMobile ? 150 : 0,
        valueGetter: (params) => calculateDistance(params.value as number),
      },
      {
        field: "max_speed",
        headerName: "Max. Speed (km/h)",
        sortable: false,
        headerAlign: "center",
        editable: false,
        align: "center",
        flex: isMobile ? 0 : 1,
        width: isMobile ? 150 : 0,
        valueGetter: (params) => calculateSpeed(params.value as number),
      },
      {
        field: "average_speed",
        headerName: "Avg. Speed (km/h)",
        sortable: false,
        headerAlign: "center",
        editable: false,
        align: "center",
        flex: isMobile ? 0 : 1,
        width: isMobile ? 150 : 0,
        valueGetter: (params) => calculateSpeed(params.value as number),
      },
      // {
      //     field: "spent_fuel",
      //     headerName: "Spent Fuel",
      //     sortable: false,
      //     headerAlign: 'center',
      //     editable: false,
      //     align: 'center',
      //     flex: isMobile ? 0 : 1,
      //     width: isMobile ? 150 : 0,
      //     valueGetter: checkIsValue,
      // },
      {
        field: "start_odometer",
        headerName: "Start Odometer (km)",
        sortable: false,
        headerAlign: "center",
        editable: false,
        align: "center",
        flex: isMobile ? 0 : 1,
        width: isMobile ? 150 : 0,
        valueGetter: (params) => calculateDistance(params.value as number),
      },
      {
        field: "end_odometer",
        headerName: "End Odometer (km)",
        sortable: false,
        headerAlign: "center",
        editable: false,
        align: "center",
        flex: isMobile ? 0 : 1,
        width: isMobile ? 150 : 0,
        valueGetter: (params) => calculateDistance(params.value as number),
      },
      {
        field: "start_time",
        headerName: "Start Time",
        sortable: false,
        headerAlign: "center",
        editable: false,
        align: "center",
        flex: isMobile ? 0 : 1,
        width: isMobile ? 150 : 0,
        valueGetter: (params) => formatDateAndTime(params.value as Date),
      },
      {
        field: "end_time",
        headerName: "End Time",
        sortable: false,
        headerAlign: "center",
        editable: false,
        align: "center",
        flex: isMobile ? 0 : 1,
        width: isMobile ? 150 : 0,
        valueGetter: (params) => formatDateAndTime(params.value as Date),
      },
      {
        field: "engine_hours",
        headerName: "Engine Hours",
        sortable: false,
        headerAlign: "center",
        editable: false,
        align: "center",
        flex: isMobile ? 0 : 1,
        width: isMobile ? 150 : 0,
        valueGetter: (params) => formatToHrMin(params.value as number),
      },
    ],
    [isMobile]
  );

  const [summaryColumnsDisplay, setSummaryColumnsDisplay] = useState<
    GridColDef[]
  >([]);

  useEffect(() => {
    const displayColumns: GridColDef[] = [];
    summaryColumns.map(
      (column) => columns.includes(column.field) && displayColumns.push(column)
    );
    setSummaryColumnsDisplay(displayColumns);
  }, [columns, summaryColumns]);

  const [open, setOpen] = useState(false);

  // const { sidebarOpen, setSidebarOpen } = useContext(SidebarOpenContext);
  const { setSidebarOpen } = useContext(SidebarOpenContext);
  const { setShowFilter } = useContext(ShowFilterContext);
  const { setShowExport } = useContext(ReportExportContext);
  const { setInitialApiCall } = useContext(initialApiCallContext);

  return (
    <>
      <Tooltip title="Report">
        <Icon
          component={DescriptionIcon}
          sx={{
            color: iconColor,
            height: "25px",
            cursor: "pointer",
          }}
          onClick={() => {
            setSidebarOpen(false);
            setShowFilter(true);
            setShowExport(true);
            setPathFilter(false);
            open ? setOpen(false) : setOpen(true);
            setLoader(true);
            // !pathFilter && setInitialApiCall(false);
          }}
        />
      </Tooltip>
      <Modal
        open={open}
        disableEnforceFocus
        onClose={() => {
          setOpen(false);
          setShowFilter(false);
          setShowExport(false);
        }}
        sx={{
          maxWidth: "1200px",
          height: isMobile && isMobileHeight ? "350px" : "455px",
          backgroundColor: primaryColor,
          margin: isMobile
            ? `${period === "custom" ? "38vh" : "30vh"} 10px`
            : "auto",
          // margin: isMobile ? 'auto 10px' : 'auto',
          borderRadius: "10px",
          overflow: "hidden",
          zIndex: "-1",
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          border={`2px solid ${iconColor}`}
          height={isMobile ? "99.2%" : "99.4%"}
          width={isMobile ? "99.1%" : "99.7%"}
          borderRadius="10px"
        >
          {/* <Box
                        className='filter'
                        display='flex'
                        flexWrap={isMobile ? 'wrap' : 'unset'}
                        p='15px'
                        gap='15px'
                        justifyContent='space-between'
                    > */}
          {/* <Box width={isMobile ? '45%' : '25%'}>
                            <FormControl size='small' fullWidth>
                                <InputLabel sx={{ color: iconColor }}>Table</InputLabel>
                                <SelectComponent
                                    className='table'
                                    value={table}
                                    label='Table'
                                    onChange={e => setTable(e.target.value as string)}
                                    MenuProps={{
                                        sx: {
                                            "& .MuiMenuItem-root.Mui-selected": {
                                                backgroundColor: selectedMenuColor,
                                            },
                                            "& .MuiButtonBase-root.MuiMenuItem-root:hover": {
                                                backgroundColor: selectedMenuColorHover,
                                            }
                                        },
                                    }}
                                >
                                    <MenuItem value='trip' >Trip</MenuItem>
                                    <MenuItem value='stop'>Stop</MenuItem>
                                </SelectComponent>
                            </FormControl>
                        </Box> */}

          {/* <Box width={isMobile ? '45%' : '25%'}>
                            <FormControl size='small' fullWidth>
                                <InputLabel sx={{ color: iconColor }}>Device</InputLabel>
                                <SelectComponent
                                    className='device'
                                    value={device}
                                    label='Device'
                                    // onChange={handleChange}
                                    onChange={e => setDevice(e.target.value as string)}
                                    MenuProps={{
                                        sx: {
                                            "& .MuiMenuItem-root.Mui-selected": {
                                                backgroundColor: selectedMenuColor,
                                            },
                                            "& .MuiButtonBase-root.MuiMenuItem-root:hover": {
                                                backgroundColor: selectedMenuColorHover,
                                            },
                                            '& .MuiPaper-root.MuiPopover-paper.MuiMenu-paper::-webkit-scrollbar': {
                                                width: '4px',
                                            },
                                            '& .MuiPaper-root.MuiPopover-paper.MuiMenu-paper::-webkit-scrollbar-thumb': {
                                                backgroundColor: scrollbarColor,
                                                borderRadius: '4px',
                                            },
                                            '& .MuiPaper-root.MuiPopover-paper.MuiMenu-paper::-webkit-scrollbar-track': {
                                                backgroundColor: lineColor,
                                            },
                                            '& .MuiPaper-root.MuiPopover-paper.MuiMenu-paper': {
                                                scrollbarColor: `${scrollbarColor} ${lineColor}`,
                                                scrollbarWidth: 'thin',
                                              }
                                        },
                                    }}
                                >
                                    <MenuItem value='all'>All</MenuItem>
                                    {
                                        vehiclesInfo && vehiclesInfo.map((item: IVehicleType) => {
                                            return <MenuItem value={item.device_id} key={item.device_id}>{item.name}</MenuItem>
                                        })
                                    }

                                </SelectComponent>
                            </FormControl>
                        </Box> */}

          {/* <Box width={isMobile ? '45%' : '25%'}>
                            <FormControl size='small' fullWidth>
                                <InputLabel sx={{ color: iconColor }}>Period</InputLabel>
                                <SelectComponent
                                    className='period'
                                    value={period}
                                    label='Period'
                                    // onChange={handleChange}
                                    onChange={e => setPeriod(e.target.value as string)}
                                    MenuProps={{
                                        sx: {
                                            "& .MuiMenuItem-root.Mui-selected": {
                                                backgroundColor: selectedMenuColor,
                                            },
                                            "& .MuiButtonBase-root.MuiMenuItem-root:hover": {
                                                backgroundColor: selectedMenuColorHover,
                                            }
                                        },
                                    }}
                                >
                                    <MenuItem value='today'>Today</MenuItem>
                                    <MenuItem value='thisWeek'>This Week</MenuItem>
                                    <MenuItem value='thisMonth'>This Month</MenuItem>
                                    <MenuItem value='thisYear'>This Year</MenuItem>
                                    <MenuItem value='custom'>Custom</MenuItem>
                                </SelectComponent>
                            </FormControl>
                        </Box> */}

          {/* <Box width={isMobile ? '45%' : '25%'}>
                            <FormControl size='small' fullWidth>
                                <InputLabel sx={{ color: iconColor }}>Columns</InputLabel>
                                <SelectComponent
                                    multiple
                                    className='columns'
                                    value={columns}
                                    label='Columns'
                                    onChange={(e) => setColumns(e.target.value as string[])}
                                    MenuProps={{
                                        sx: {
                                            "& .MuiMenuItem-root.Mui-selected": {
                                                backgroundColor: selectedMenuColor,
                                            },
                                            "& .MuiButtonBase-root.MuiMenuItem-root:hover": {
                                                backgroundColor: selectedMenuColorHover,
                                            },
                                            '& .MuiPaper-root.MuiPopover-paper.MuiMenu-paper::-webkit-scrollbar': {
                                                width: '4px',
                                            },
                                            '& .MuiPaper-root.MuiPopover-paper.MuiMenu-paper::-webkit-scrollbar-thumb': {
                                                backgroundColor: scrollbarColor,
                                                borderRadius: '4px',
                                            },
                                            '& .MuiPaper-root.MuiPopover-paper.MuiMenu-paper::-webkit-scrollbar-track': {
                                                backgroundColor: lineColor,
                                            },
                                            '& .MuiPaper-root.MuiPopover-paper.MuiMenu-paper': {
                                                scrollbarColor: `${scrollbarColor} ${lineColor}`,
                                                scrollbarWidth: 'thin',
                                            }
                                        },
                                    }}
                                >
                                    {
                                        table === 'trip'
                                            ?
                                            tripColumns.map(item => {
                                                return <MenuItem key={item.field} value={item.field}>{item.headerName}</MenuItem>
                                            })
                                            :
                                            stopColumns.map(item => {
                                                return <MenuItem key={item.field} value={item.field}>{item.headerName}</MenuItem>
                                            })
                                    }
                                </SelectComponent>
                            </FormControl>
                        </Box> */}
          {/* </Box> */}
          <Box flex={1} overflow="hidden">
            {report === "vehicle" ? (
              table === "trip" ? (
                <TripTable
                  columns={tripColumnsDisplay}
                  fromDate={fromDate}
                  toDate={toDate}
                  device={device}
                />
              ) : table === "stop" ? (
                <StopTable
                  columns={stopColumnsDisplay}
                  fromDate={fromDate}
                  toDate={toDate}
                  device={device}
                />
              ) : (
                <SummaryTable
                  columns={summaryColumnsDisplay}
                  fromDate={fromDate}
                  toDate={toDate}
                  device={device}
                />
              )
            ) : (
              <MobileTripTable
                columns={tripColumnsDisplay}
                fromDate={fromDate}
                toDate={toDate}
                device={device}
              />
            )}
          </Box>
        </Box>
      </Modal>
      <Modal
        open={openMap}
        sx={{
          backgroundColor: "white",
        }}
      >
        <Box>
          <Box
            sx={{
              zIndex: "0",
            }}
          >
            {mobileTripCoordinates.length ? (
              <MobileTripMap data={mobileTripCoordinates} />
            ) : tripCoordinates.length ? (
              <TripMap data={tripCoordinates} />
            ) : (
              <StopMap data={stopCoordinates} />
            )}
          </Box>
          <Box
            className="closeButton"
            alignItems="center"
            bgcolor={primaryColor}
            height="50px"
            width="50px"
            position="absolute"
            right="0"
            sx={{
              zIndex: "1",
            }}
            border={`2px solid ${lineColor}`}
          >
            <CloseIcon
              onClick={() => {
                setOpenMap(false);
                setTripCoordinates([]);
                setStopCoordinates([]);
                setMobileTripCoordinates([]);
              }}
              sx={{
                color: lineColor,
                fontSize: "50px",
                cursor: "pointer",
              }}
            />
          </Box>
          <Box
            position="absolute"
            bottom={isMobile ? "-20px" : "8px"}
            right="2px"
            sx={{
              zIndex: "1",
            }}
          >
            <ToggleMap />
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default Report;
