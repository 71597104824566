import React, { useContext, useMemo, useState } from "react";
import DeckGL from "@deck.gl/react/typed";
import { FlyToInterpolator } from "deck.gl/typed";
import { TileLayer } from "@deck.gl/geo-layers/typed";
import {
  BitmapBoundingBox,
  BitmapLayer,
  IconLayer,
  PathLayer,
} from "@deck.gl/layers/typed";
import mobile from "../assets/images/phone.png";
import { Box } from "@mui/material";
import { MapColorContext, MapLayerContext } from "../App";

const MobileTripMap = (props: { data: number[] }) => {
  const { layer } = useContext(MapLayerContext);
  const {mapColor} = useContext(MapColorContext);

  const [initialViewState] = useState({
    longitude: 85.308,
    latitude: 27.6972,
    zoom: 12.5,
    pitch: 0,
    bearing: 0,
    transitionDuration: 1000,
    transitionInterpolator: new FlyToInterpolator(),
  });

  const layers = useMemo(() => {
    const iconMapping = {
      mobile: { x: 0, y: 0, width: 256, height: 256, mask: true },
    };

    return [
      new TileLayer({
        data: layer,
        minZoom: 0,
        maxZoom: 19,
        tileSize: 256,
        renderSubLayers: (props) => {
          const { boundingBox } = props.tile;
          return new BitmapLayer(props, {
            data: undefined,
            image: props.data,
            bounds: boundingBox.flatMap((x) => x) as BitmapBoundingBox,
          });
        },
      }),
      new IconLayer({
        id: "mobile",
        data: [{ coordinates: props.data }],
        pickable: true,
        iconAtlas: mobile,
        iconMapping: iconMapping,
        sizeScale: 6,
        getIcon: () => "mobile",
        getPosition: (d) => JSON.parse(d.coordinates)[0],
        getSize: () => 6,
        // onClick: d => handleIconClick(d.object),
        getColor: () =>{
          const rgbArray = mapColor.match(/\d+/g).map(Number).slice(0, 3);
          return rgbArray
        }
      }),
      new PathLayer({
        id: "mobilePath",
        data: [{ coordinates: props.data }],
        pickable: true,
        capRounded: true,
        jointRounded: true,
        billboard: true,
        widthMinPixels: 3,
        autoHighlight: true,
        highlightColor: [6, 39, 103],
        getColor: () =>{
          const rgbArray = mapColor.match(/\d+/g).map(Number).slice(0, 3);
          return rgbArray
        },
        getWidth: 0,
        getPath: (d) => JSON.parse(d.coordinates),
        // getDashArray: [4,4],
        // dashJustified: true,
        // parameters:{
        //     depthMask: false,
        // },
      }),
    ];
  }, [layer, props.data, mapColor]);

  return (
    <>
      <Box display="flex">
        <DeckGL
          initialViewState={initialViewState}
          controller={true}
          layers={layers}
          getCursor={(isDragging) => {
            return isDragging.isDragging
              ? "grabbing"
              : isDragging.isHovering
              ? "pointer"
              : "grab";
          }}
          // style={{ zIndex: '2' }}
          // onClick={ d => {
          // d.object && setPopupData(d);
          //     !d.object && setPopupData(undefined);
          // }}
        ></DeckGL>
      </Box>
    </>
  );
};

export default MobileTripMap;
