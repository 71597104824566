import React, { useMemo, useState, useContext, useEffect } from "react";
import DeckGL from "@deck.gl/react/typed";
import { FlyToInterpolator, PickingInfo } from "deck.gl/typed";
import { TileLayer } from "@deck.gl/geo-layers/typed";
import {
  BitmapBoundingBox,
  BitmapLayer,
  IconLayer,
  PathLayer,
} from "@deck.gl/layers/typed";
import vehicle from "../assets/images/icons.png";
import mark from "../assets/images/icons.png";
import mobile from "../assets/images/phone.png";
import InfoBox from "./InfoBox";
import { Box, Typography, useMediaQuery } from "@mui/material";
import {
  // CalledReplayContext,
  DisplayCoordinatesContext,
  FilterContext,
  InfoDataContext,
  MapLayerContext,
  MobileCoordinatesContext,
  MobilesDataContext,
  PathFilterContext,
  VehicleCoordinatesContext,
  VehicleDataContext,
  VehicleDataPopupContext,
  VehiclesDataContext,
  ViewportDataContext,
} from "../App";
import {
  iconColor,
  primaryColor,
  textColor,
} from "../constants/color-constants";
import { IMobileType, IVehicleType } from "../api/Api";
import MoreDetails from "./MoreDetails";
import CloseIcon from "@mui/icons-material/Close";
import { format } from "date-fns";
import WebMercatorViewport from "viewport-mercator-project";

const Map = () => {
  const isMobile = useMediaQuery("(max-width:600px)");
  const { vehicleData } = useContext(VehicleDataContext);
  const { viewportData } = useContext(ViewportDataContext);
  const { vehiclesInfo } = useContext(VehiclesDataContext);
  const { mobilesInfo } = useContext(MobilesDataContext);
  const { vehiclecoordinates } = useContext(
    VehicleCoordinatesContext
  );
  const { mobilecoordinates } = useContext(MobileCoordinatesContext);
  // const [isHovered, setIsHovered] = useState(false);
  const [device, setDevice] = useState("");
  // const isMobile = useMediaQuery('(max-width:600px)')
  const { displayCoordinates } = useContext(DisplayCoordinatesContext);
  const { layer } = useContext(MapLayerContext);
  const [previousCoordinates, setPreviousCoordinates] = useState<any>([]);
  const { table } = useContext(FilterContext);
  const { pathFilter } = useContext(PathFilterContext);
  const { vehicleDataPopup, setVehicleDataPopup } = useContext(
    VehicleDataPopupContext
  );
  const { setInfoData } = useContext(InfoDataContext);

  const dateFormat = "yyy-MM-dd";
  const timeFormat = "HH:mm";

  const formatDateAndTime = (value: Date) => {
    const formattedDate = format(new Date(value), dateFormat);
    const formattedTime = format(new Date(value), timeFormat);
    return `${formattedDate} (${formattedTime})`;
  };

  // const { calledreplay } = useContext(CalledReplayContext);
  // function colorStringToArray(colorString: string): any {
  //   const rgbMatch = colorString.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);
  //   const rgbaMatch = colorString.match(/^rgba\((\d+),\s*(\d+),\s*(\d+),\s*([\d.]+)\)$/);

  //   if (rgbMatch) {
  //     const rgbArray = [parseInt(rgbMatch[1], 10), parseInt(rgbMatch[2], 10), parseInt(rgbMatch[3], 10)];
  //     return [...rgbArray, 1]; // Add opacity value as 1 for RGB
  //   } else if (rgbaMatch) {
  //     return [
  //       parseInt(rgbaMatch[1], 10),
  //       parseInt(rgbaMatch[2], 10),
  //       parseInt(rgbaMatch[3], 10),
  //       parseFloat(rgbaMatch[4]),
  //     ];
  //   }
  //   return null;
  // }
  // const [infoBoxOpen, setInfoBoxOpen] = useState(true)

  const [initialViewState, setInitialViewState] = useState({
    longitude: 85.308,
    latitude: 27.6972,
    zoom: 10.7,
    pitch: 0,
    bearing: 0,
    transitionDuration: 1000,
    transitionInterpolator: new FlyToInterpolator(),
  });

  useEffect(() => {
    if (viewportData !== undefined && viewportData.length > 0) {
      const allCoordinates = viewportData.map((data: any) => {
        try {
          if (data.coordinates) {
            return JSON.parse(data.coordinates)[0];
          } else if (data.road_snap) {
            return JSON.parse(data.road_snap)[0];
          } else {
            return [];
          }
        } catch (error) {
          console.error("Error while parsing coordinates:", error);
          return [];
        }
      });

      if (previousCoordinates.length === 0) {
        setPreviousCoordinates((prevCoords: any) => [
          ...prevCoords,
          ...allCoordinates,
        ]);
      }

      // Combine previous and new coordinates
      const combinedCoordinates = [...previousCoordinates, ...allCoordinates];
      // Extracting longitudes and latitudes separately

      const longitudes = combinedCoordinates.map(
        (coord) => coord[0] !== undefined && coord[0]
      );
      const latitudes = combinedCoordinates.map(
        (coord) => coord[1] !== undefined && coord[1]
      );

      // Finding minimum and maximum longitudes and latitudes
      const minLongitude = Math.min(...longitudes);
      const maxLongitude = Math.max(...longitudes);
      const minLatitude = Math.min(...latitudes);
      const maxLatitude = Math.max(...latitudes);

      // Calculating center and zoom level
      const centerLongitude = (minLongitude + maxLongitude) / 2;
      const centerLatitude = (minLatitude + maxLatitude) / 2;
      const deltaLongitude = maxLongitude - minLongitude;
      const deltaLatitude = maxLatitude - minLatitude;
      const zoom = Math.min(
        Math.log2(360 / deltaLongitude),
        Math.log2(180 / deltaLatitude),
        20 // Maximum zoom level
      );

      setInitialViewState({
        longitude: centerLongitude,
        latitude: centerLatitude,
        zoom: isMobile ? zoom - 1 : zoom + 0.5,
        pitch: 0,
        bearing: 0,
        transitionDuration: 1000,
        transitionInterpolator: new FlyToInterpolator(),
      });
      setPopupData(undefined);
    }
  }, [viewportData, previousCoordinates, isMobile]);

  // const handleIconClick = (data: VehicleType) => {
  //   setInfoBoxOpen(true);
  //   setVehicleData(data);
  // };

  const layers = useMemo(() => {
    const iconMapping = {
      vehicle: { x: 0, y: 0, width: 579, height: 579, mask: false },
      top_car: { x: 180, y: 150, width: 180, height: 160, mask: true },
      mobile: { x: 0, y: 0, width: 256, height: 256, mask: true },
      mark: { x: 0, y: 0, width: 180, height: 160, mask: true },
    };

    return [
      new TileLayer({
        // data: "https://mt0.google.com/vt/lyrs=m&hl=sl&x={x}&y={y}&z={z}&s=Ga",
        // data: "https://mt0.google.com/vt/lyrs=m,traffic&hl=sl&x={x}&y={y}&z={z}&s=Ga",
        // https://c.tile.openstreetmap.org/{z}/{x}/{y}.png
        data: layer,
        minZoom: 0,
        maxZoom: 19,
        tileSize: 256,
        renderSubLayers: (props) => {
          const { boundingBox } = props.tile;
          return new BitmapLayer(props, {
            data: undefined,
            image: props.data,
            bounds: boundingBox.flatMap((x) => x) as BitmapBoundingBox,
          });
        },
      }),
      new IconLayer({
        id: "vehicle",
        data: vehiclecoordinates,
        pickable: true,
        iconAtlas: vehicle,
        iconMapping: iconMapping,
        sizeScale: 6,
        getIcon: () => "top_car",
        getPosition: (d) => {
          try {
            if (d.coordinates) {
              // console.log(JSON.parse(d.coordinates));
              // console.log(JSON.parse(d.coordinates));
              // console.log("*********************************")
              // const coordinatesString = d.coordinates;
              // const coordinatesArray = JSON.parse(coordinatesString);

              // for (const coordinate of coordinatesArray) {
              //   console.log([coordinate[0], coordinate[1]])
              //   return [coordinate[0], coordinate[1]]
              // }
              // const coordinatesString = d.coordinates;
              // const coordinatesArray = JSON.parse(coordinatesString);

              // Loop through each coordinate pair and print in desired format
              // for (const coordinate of coordinatesArray) {
              //   const a = `[${coordinate[0]}, ${coordinate[1]}]`
              //   console.log(JSON.parse(a));
              //   return JSON.parse(a);
              // }
              // return JSON.parse(d.coordinates);
              // return d.coordinates
              // const coordinates = JSON.parse(d.coordinates);
              // console.log(coordinates.map((coord: any) => coord));
              // return coordinates.map((coord: any) => coord[0]);
              // console.log(d.coordinates);
              // console.log(JSON.parse(d.coordinates)[0]);
              // d.coordinates.forEach((coord: any) => console.log(`(${coord[0]}, ${coord[1]})`));
              return JSON.parse(d.coordinates)[0];
            } else if (d.longitude && d.latitde) {
              const coordinate = [d.longitude, d.latitde];
              return coordinate[0];
            } else {
              // if(d.road_snap!== undefined)
              if (d.road_snap.length > 2) {
                return JSON.parse(d.road_snap)[0];
              }
            }
          } catch (error) {
            console.error("Error parsing coordinates:", error);
            // return [0, 0];
          }
        },
        getAngle: (d) => {
          if (d.coordinates) {
            const angle = JSON.parse(d.heading)[0];
            return angle;
          }
        },
        getSize: (d) => {
          if (d.road_snap || d.longitude || d.latitde) {
            return 6;
          } else if (displayCoordinates.includes(d.device_id)) {
            return 6;
          }
          return 0;
        },
        // onClick: d => handleIconClick(d.object),
        onClick: (d) => {
          if (d.object && !pathFilter) {
            setInfoData([d.object]);
            setVehicleDataPopup("vehicle");
            // setIsHovered(true)
          }
          if (d.object && table === "trip" && pathFilter) {
            setDevice("device");
            setFilterPopupData(d);
          }
          if (d.object && table === "stop" && pathFilter) {
            setDevice("device");
            setStopFilterPopupData(d);
          }
          // d.object && setPopupData(d);
        },
        // onHover: !isMobile
        // ? (d) => {
        //     if(d.object){
        //       setDevice('device')
        //       setPopupData(d)
        //       setIsHovered(true)
        //     };
        //     // !d.object && setPopupData(undefined);
        // }
        // : undefined,
        getColor: (d) => {
          if (d.color) {
            const rgbArray = d.color.match(/\d+/g).map(Number).slice(0, 3);
            return rgbArray;
          }
        },
      }),
      new IconLayer({
        id: "vehiclemark",
        data: vehiclecoordinates,
        pickable: true,
        iconAtlas: mark,
        iconMapping: iconMapping,
        sizeScale: 6,
        getIcon: () => "mark",
        getPosition: (d) => {
          try {
            if (d.coordinates) {
              const coordinates = JSON.parse(d.coordinates);
              return coordinates[coordinates.length - 1];
            } else if (d.longitude && d.latitde) {
              return;
            } else {
              // console.log(d.road_snap);
              // if(d.road_snap!== undefined)
              if (d.road_snap.length > 2) {
                const coordinates = JSON.parse(d.road_snap);
                return coordinates[coordinates.length - 1];
              }
            }
          } catch (error) {
            console.error("Error parsing coordinates:", error);
            // return [0, 0];
          }
        },
        getSize: (d) => {
          if (d.road_snap) {
            return 3;
          } else if (displayCoordinates.includes(d.device_id)) {
            return 3;
          }
          return 0;
        },
        getColor: (d) => {
          if (d.color) {
            const rgbArray = d.color.match(/\d+/g).map(Number).slice(0, 3);
            return rgbArray;
          }
        },
      }),
      new PathLayer({
        id: "vehiclePath",
        data: vehiclecoordinates,
        pickable: true,
        capRounded: true,
        jointRounded: true,
        // billboard: true,
        widthMinPixels: 3,
        autoHighlight: true,
        highlightColor: [6, 39, 103],
        getColor: (d) => {
          if (d.color) {
            const rgbArray = d.color.match(/\d+/g).map(Number).slice(0, 3);
            return rgbArray;
          }
        },
        getWidth: 0,
        getPath: (d) => {
          if (d.road_snap) {
            return JSON.parse(d.road_snap);
          } else if (displayCoordinates.includes(d.device_id)) {
            return JSON.parse(d.coordinates);
          }
          return undefined;
        },
        // getDashArray: [4,4],
        // dashJustified: true,
        // parameters:{
        //     depthMask: false,
        // },
      }),
      new IconLayer({
        id: "mobile",
        data: mobilecoordinates,
        pickable: true,
        iconAtlas: mobile,
        iconMapping: iconMapping,
        sizeScale: 6,
        getIcon: () => "mobile",
        getPosition: (d) => {
          try {
            if (d.coordinates) {
              return JSON.parse(d.coordinates)[0];
            } else {
              // if(d.road_snap!== undefined)
              if (d.road_snap.length > 2) {
                return JSON.parse(d.road_snap)[0];
              }
            }
          } catch (error) {
            console.error("Error parsing coordinates:", error);
            return [0, 0];
          }
        },
        getAngle: (d) => {
          if (d.coordinates) {
            const angle = JSON.parse(d.heading)[0];
            return angle;
          }
        },
        getSize: (d) => {
          if (d.road_snap) {
            return 6;
          } else if (displayCoordinates.includes(d.device_id)) {
            return 6;
          }
          return 0;
        },
        // onClick: d => handleIconClick(d.object),
        onClick: (d) => {
          if (d.object && !pathFilter) {
            setInfoData([d.object]);
            setVehicleDataPopup("mobile");
            // setIsHovered(true)
          }
          if (d.object && table === "trip" && pathFilter) {
            setDevice("mobile");
            setFilterPopupData(d);
          }
          // if (d.object && d.object.coordinates) {
          //   setDevice("mobile");
          //   setPopupData(d);
          //   // setIsHovered(true)
          // }
          // if (d.object && d.object.road_snap) {
          //   console.log(d);
          //   setDevice("mobile");
          //   setFilterPopupData(d);
          // }
          // d.object && setPopupData(d);
        },
        // onHover: !isMobile
        // ? (d) => {
        //     if(d.object){
        //       setDevice('mobile')
        //       setPopupData(d)
        //       setIsHovered(true)
        //     };
        //     // !d.object && setPopupData(undefined);
        // }
        // : undefined,
        getColor: (d) => {
          if (d.color) {
            const rgbArray = d.color.match(/\d+/g).map(Number).slice(0, 3);
            return rgbArray;
          }
        },
      }),
      new IconLayer({
        id: "mobilemark",
        data: mobilecoordinates,
        pickable: true,
        iconAtlas: mark,
        iconMapping: iconMapping,
        sizeScale: 6,
        getIcon: () => "mark",
        getPosition: (d) => {
          try {
            if (d.coordinates) {
              const coordinates = JSON.parse(d.coordinates);
              return coordinates[coordinates.length - 1];
            } else {
              // console.log(d.road_snap);
              // if(d.road_snap!== undefined)
              if (d.road_snap.length > 2) {
                const coordinates = JSON.parse(d.road_snap);
                return coordinates[coordinates.length - 1];
              }
            }
          } catch (error) {
            console.error("Error parsing coordinates:", error);
            return [0, 0];
          }
        },
        getSize: (d) => {
          if (d.road_snap) {
            return 3;
          } else if (displayCoordinates.includes(d.device_id)) {
            return 3;
          }
          return 0;
        },
        getColor: (d) => {
          if (d.color) {
            const rgbArray = d.color.match(/\d+/g).map(Number).slice(0, 3);
            return rgbArray;
          }
        },
      }),
      new PathLayer({
        id: "mobilePath",
        data: mobilecoordinates,
        pickable: true,
        capRounded: true,
        jointRounded: true,
        // billboard: true,
        widthMinPixels: 3,
        autoHighlight: true,
        highlightColor: [6, 39, 103],
        getColor: (d) => {
          if (d.color) {
            const rgbArray = d.color.match(/\d+/g).map(Number).slice(0, 3);
            return rgbArray;
          }
        },
        getWidth: 0,
        getPath: (d) => {
          try {
            if (d.road_snap) {
              return JSON.parse(d.road_snap);
            } else if (displayCoordinates.includes(d.device_id)) {
              return JSON.parse(d.coordinates);
            }
            return undefined;
          } catch (error) {
            console.error("Error parsing coordinates:", error);
            return [0, 0];
          }
        },
        // getDashArray: [4,4],
        // dashJustified: true,
        // parameters:{
        //     depthMask: false,
        // },
      }),
    ];
  }, [layer, vehiclecoordinates, mobilecoordinates, displayCoordinates, pathFilter, table, setInfoData, setVehicleDataPopup]);

  useEffect(() => {
    if (
      vehicleData !== undefined &&
      vehicleData.length > 0 &&
      vehicleData[0].coordinates
    ) {
      // const singleCoordinate = JSON.parse(vehicleData[0].coordinates)[0];
      const matchingCoordinates =
        vehicleDataPopup === "vehicle"
          ? vehiclecoordinates.filter(
              (coord: any) => coord.device_id === vehicleData[0].device_id
            )
          : mobilecoordinates.filter(
              (coord: any) => coord.device_id === vehicleData[0].device_id
            );
      const singleCoordinate = JSON.parse(
        matchingCoordinates[0].coordinates
      )[0];
      // console.log(singleCoordinate);
      setInitialViewState({
        // longitude: vehicleData?.coordinates[0],
        // latitude: vehicleData?.coordinates[1],
        longitude: singleCoordinate[0],
        latitude: singleCoordinate[1],
        zoom: 17,
        pitch: 0,
        bearing: 0,
        transitionDuration: 1000,
        transitionInterpolator: new FlyToInterpolator(),
      });
      setPopupData(undefined);
    }
  }, [mobilecoordinates, vehicleData, vehicleDataPopup, vehiclecoordinates]);

  const [popupData, setPopupData] = useState<PickingInfo>();
  const [filterPopupData, setFilterPopupData] = useState<PickingInfo>();
  const [stopfilterPopupData, setStopFilterPopupData] = useState<PickingInfo>();
  const [updatedPopup, setUpdatedPopup] = useState<PickingInfo>();

  const fetchDeviceid = popupData ? popupData.object.device_id : null;
  //   const fetchDeviceidCo = popupData ? popupData.device_id : null;

  const filteredData =
    device === "device"
      ? vehiclesInfo.length &&
        vehiclesInfo.filter(
          (item: IVehicleType) => item.device_id === fetchDeviceid
        )
      : mobilesInfo.length &&
        mobilesInfo.filter(
          (item: IMobileType) => item.device_id === fetchDeviceid
        );

  const renderPopup = () => {
    if (popupData) {
      return (
        // isHovered &&
        <Box
          width="200px"
          height="55px"
          bgcolor={primaryColor}
          position="absolute"
          top="0"
          left="0"
          padding="4px"
          sx={{
            transform: `translate(${
              updatedPopup?.x !== undefined && updatedPopup?.x - 100
            }px, ${updatedPopup?.y !== undefined && updatedPopup?.y - 85}px)`,
            // transform: `translate(${popupData.x - 100}px, ${popupData.y - 85}px)`,
            border: `1px solid ${iconColor}`,
            borderRadius: "10px",
          }}
          // onMouseEnter={()=>setIsHovered(true)}
          // onMouseLeave={!isMobile ? ()=>setIsHovered(false) : undefined}
        >
          {device === "device"
            ? filteredData.map((vehicle: IVehicleType) => (
                <Box
                  className="details"
                  textAlign="center"
                  color={textColor}
                  key={vehicle.device_id}
                >
                  <CloseIcon
                    onClick={() => setPopupData(undefined)}
                    sx={{
                      cursor: "pointer",
                      fontSize: "20px",
                      position: "absolute",
                      right: "0",
                    }}
                  />
                  <Typography className="name" fontSize="12px" paddingTop="4px">
                    {vehicle.name}
                  </Typography>
                  <Typography className="uniqueId" fontSize="12px">
                    {vehicle.unique_id}
                  </Typography>
                  <Box
                    sx={{
                      cursor: "pointer",
                      position: "absolute",
                      right: "2px",
                      bottom: "1px",
                    }}
                  ></Box>
                  <MoreDetails data={vehicle} check={device} />
                </Box>
              ))
            : filteredData.map((mobile: IMobileType) => (
                <Box
                  className="details"
                  textAlign="center"
                  color={textColor}
                  key={mobile.device_id}
                >
                  <CloseIcon
                    onClick={() => setPopupData(undefined)}
                    sx={{
                      cursor: "pointer",
                      fontSize: "20px",
                      position: "absolute",
                      right: "0",
                    }}
                  />
                  <Typography className="name" fontSize="12px" paddingTop="4px">
                    {mobile.fullname}
                  </Typography>
                  <Typography className="uniqueId" fontSize="12px">
                    {mobile.phone}
                  </Typography>
                  <MoreDetails data={mobile} check={device} />
                </Box>
              ))}
        </Box>
      );
    }
  };

  const renderFilterPopup = () => {
    if (filterPopupData) {
      return (
        // isHovered &&
        <Box
          width="200px"
          height="55px"
          bgcolor={primaryColor}
          position="absolute"
          top="0"
          left="0"
          padding="4px"
          sx={{
            transform: `translate(${
              updatedPopup?.x !== undefined && updatedPopup?.x - 100
            }px, ${updatedPopup?.y !== undefined && updatedPopup?.y - 85}px)`,
            // transform: `translate(${popupData.x - 100}px, ${popupData.y - 85}px)`,
            border: `1px solid ${iconColor}`,
            borderRadius: "10px",
          }}
          // onMouseEnter={()=>setIsHovered(true)}
          // onMouseLeave={!isMobile ? ()=>setIsHovered(false) : undefined}
        >
          {device === "device" ? (
            <Box
              className="details"
              textAlign="center"
              color={textColor}
              key={filterPopupData.object.device_id}
            >
              <CloseIcon
                onClick={() => setFilterPopupData(undefined)}
                sx={{
                  cursor: "pointer",
                  fontSize: "20px",
                  position: "absolute",
                  right: "0",
                }}
              />
              <Typography className="name" fontSize="12px" paddingTop="4px">
                {filterPopupData.object.end_address === null
                  ? "No Data"
                  : filterPopupData.object.end_address}
              </Typography>
              <Typography className="uniqueId" fontSize="12px">
                {formatDateAndTime(filterPopupData.object.end_time)}
              </Typography>
            </Box>
          ) : (
            <Box
              className="details"
              textAlign="center"
              color={textColor}
              key={filterPopupData.object.device_id}
            >
              <CloseIcon
                onClick={() => setFilterPopupData(undefined)}
                sx={{
                  cursor: "pointer",
                  fontSize: "20px",
                  position: "absolute",
                  right: "0",
                }}
              />
              <Typography className="name" fontSize="12px" paddingTop="4px">
                {filterPopupData.object.end_location}
              </Typography>
              <Typography className="uniqueId" fontSize="12px">
                {formatDateAndTime(filterPopupData.object.end_time)}
              </Typography>
            </Box>
          )}
        </Box>
      );
    }
  };

  const renderStopFilterPopup = () => {
    if (stopfilterPopupData) {
      return (
        // isHovered &&
        <Box
          width="200px"
          height="55px"
          bgcolor={primaryColor}
          position="absolute"
          top="0"
          left="0"
          padding="4px"
          sx={{
            transform: `translate(${
              updatedPopup?.x !== undefined && updatedPopup?.x - 100
            }px, ${updatedPopup?.y !== undefined && updatedPopup?.y - 85}px)`,
            // transform: `translate(${popupData.x - 100}px, ${popupData.y - 85}px)`,
            border: `1px solid ${iconColor}`,
            borderRadius: "10px",
          }}
          // onMouseEnter={()=>setIsHovered(true)}
          // onMouseLeave={!isMobile ? ()=>setIsHovered(false) : undefined}
        >
          {device === "device" && (
            <Box
              className="details"
              textAlign="center"
              color={textColor}
              key={stopfilterPopupData.object.device_id}
            >
              <CloseIcon
                onClick={() => setStopFilterPopupData(undefined)}
                sx={{
                  cursor: "pointer",
                  fontSize: "20px",
                  position: "absolute",
                  right: "0",
                }}
              />
              <Typography className="name" fontSize="12px" paddingTop="4px">
                {stopfilterPopupData.object.address === null
                  ? "No Data"
                  : stopfilterPopupData.object.address}
              </Typography>
              <Typography className="uniqueId" fontSize="12px">
                {formatDateAndTime(stopfilterPopupData.object.end_time)}
              </Typography>
            </Box>
          )}
        </Box>
      );
    }
  };

  const [mapViewState, setMapViewState] = useState(initialViewState);

  const calculateNewXY = (
    viewState: any,
    longitude: number,
    latitude: number
  ) => {
    const viewport = new WebMercatorViewport(viewState);
    const [x, y] = viewport.project([longitude, latitude]);
    return [x, y];
  };

  const handleViewStateChange = ({ viewState }: any) => {
    setMapViewState(viewState);
  };

  useEffect(() => {
    const updatePopupPosition = () => {
      if (popupData) {
        const [newX, newY] = calculateNewXY(
          mapViewState,
          popupData?.coordinate !== undefined ? popupData?.coordinate[0] : 0,
          popupData?.coordinate !== undefined ? popupData?.coordinate[1] : 0
        );
        const updatedPopupInfo = { ...popupData, x: newX, y: newY };
        setUpdatedPopup(updatedPopupInfo);
      }
      if (filterPopupData) {
        const [newX, newY] = calculateNewXY(
          mapViewState,
          filterPopupData?.coordinate !== undefined
            ? filterPopupData?.coordinate[0]
            : 0,
          filterPopupData?.coordinate !== undefined
            ? filterPopupData?.coordinate[1]
            : 0
        );
        const updatedPopupInfo = { ...filterPopupData, x: newX, y: newY };
        setUpdatedPopup(updatedPopupInfo);
      }
      if (stopfilterPopupData) {
        const [newX, newY] = calculateNewXY(
          mapViewState,
          stopfilterPopupData?.coordinate !== undefined
            ? stopfilterPopupData?.coordinate[0]
            : 0,
          stopfilterPopupData?.coordinate !== undefined
            ? stopfilterPopupData?.coordinate[1]
            : 0
        );
        const updatedPopupInfo = { ...stopfilterPopupData, x: newX, y: newY };
        setUpdatedPopup(updatedPopupInfo);
      }
    };

    updatePopupPosition();
  }, [filterPopupData, mapViewState, popupData, stopfilterPopupData]);

  return (
    <>
      <Box display="flex">
        <DeckGL
          initialViewState={initialViewState}
          onViewStateChange={handleViewStateChange}
          controller={true}
          layers={layers}
          getCursor={(isDragging) => {
            return isDragging.isDragging
              ? "grabbing"
              : isDragging.isHovering
              ? "pointer"
              : "grab";
          }}
          style={{ zIndex: "-100" }}
          // onClick={ d => {
          // d.object && setPopupData(d);
          //     !d.object && setPopupData(undefined);
          // }}
        >
          {renderPopup()}
          {renderFilterPopup()}
          {renderStopFilterPopup()}
        </DeckGL>
        <InfoBox />
      </Box>
    </>
  );
};

export default Map;
