import React, { useContext } from "react";
import { iconColor } from "../constants/color-constants";
import SearchIcon from "@mui/icons-material/Search";
import { Box, Icon, Tooltip } from "@mui/material";
import { LoaderContext, PathFilterContext, ShowFilterContext, SidebarOpenContext, initialApiCallContext } from "../App";
// import FilterAltIcon from "@mui/icons-material/FilterAlt";
// import FilterListIcon from "@mui/icons-material/FilterList";

const Search = () => {
  // const [open, setOpen] = useState(false);
  const { showFilter, setShowFilter } = useContext(ShowFilterContext);
  const { pathFilter, setPathFilter } = useContext(PathFilterContext);
  const { setSidebarOpen } = useContext(SidebarOpenContext);
  // const { setInitialApiCall } = useContext(initialApiCallContext);
  const { setLoader } = useContext(LoaderContext);
  // const { period, setPeriod } = useContext(FilterContext);
  // const { setSidebarMarginTop } = useContext(SidebarMarginTop);

  return (
    <>
      <Tooltip title="Search & Filter">
        <Box
          sx={{
            position: "relative",
          }}
          onClick={() => {
            // !pathFilter && setInitialApiCall(false);
            showFilter ? setShowFilter(false) : setShowFilter(true);
            pathFilter ? setPathFilter(false) : setPathFilter(true);
            setSidebarOpen(false);
            setLoader(true);
            // {open ? setOpen(false) : setOpen(true)}
            // setSidebarMarginTop('122')
          }}
        >
          <Icon
            component={SearchIcon}
            sx={{
              color: iconColor,
              height: "25px",
              cursor: "pointer",
            }}
          />
          {/* <Icon
                    component={FilterAltIcon}
                    sx={{
                        backgroundColor: 'red',
                        color: iconColor,
                        height: '15px',
                        padding: '0px',
                        cursor: 'pointer',
                        position: 'absolute',
                        left: '0',
                        margin: '8px 0px 0px -6px'
                    }}
                /> */}
        </Box>
      </Tooltip>
    </>
  );
};

export default Search;
